
        @import "@control-front-end/common/styles/variables";
        @import "@control-front-end/common/styles/mixins";
        
.actorsNoAccess {
  box-sizing: border-box;

  &__list {
    margin: 12px 0;

    &__block {
      margin-top: 12px;
    }
  }

  &__line {
    display: flex;
    align-items: center;
    margin: 4px 0;

    &:first-child {
      margin-top: 4px;
    }
  }
}
