
        @import "@control-front-end/common/styles/variables";
        @import "@control-front-end/common/styles/mixins";
        
.panel {
  box-sizing: border-box;

  &__section {

    &__title {
      display: flex;
      justify-content: space-between;
      padding: 12px 20px;
      @include themify($themes) {
        border-bottom: 1px solid themed('disabled');
        background-color: themed('workSpaceBg');
      }

      &__close {
        cursor: pointer;
        box-sizing: border-box;
      }
    }

    &__content {
      height: 100%;
      box-sizing: border-box;
      padding: 16px 0;
    }
  }

  &__el {
    box-sizing: border-box;
    padding: 0 20px;

    &.calendar {
      :global .rightIcon i,
      :global .rightIcon i svg {
        width: 12px;
        height: 12px;
        cursor: pointer;
      }
    }

    &__warning {
      display: flex;
      flex-direction: row;
      margin-bottom: 10px;

      & > i {
        margin-top: -12px;
      }

      & > span {
        margin-top: -16px;
        margin-left: 4px;
      }
    }

  }

  &__multi {
    box-sizing: border-box;
    margin-bottom: 24px;

    &__content {
      padding: 0 16px;
      box-sizing: border-box;

      &__wrap {
        box-sizing: border-box;
      }

      &__item {
        display: flex;
        align-items: center;
        width: 100%;

        & > div {
          flex-grow: 1;
          margin-right: 12px;
        }

        &__remove:not(.disabled) {
          cursor: pointer;
          & > i {
            @include btn-icon-hover();
          }
        }

        &__color {
          position: relative;

          & > span {
            position: absolute;
            transform: translate(0px, -27px);
            @include themify($themes) {
              color: themed('gray2');
            }
          }
        }
      }

      &__add {
        display: inline-block;
        cursor: pointer;
        @include themify($themes) {
          color: themed('gray2');
        }
      }
    }

    &__extra {
      box-sizing: border-box;

      & .calendar {
        padding: 0;
      }

      & .edit {
        box-sizing: border-box;
      }

      & .check {
        margin-bottom: 16px !important;
      }
    }
  }
}
