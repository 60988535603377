
        @import "@control-front-end/common/styles/variables";
        @import "@control-front-end/common/styles/mixins";
        
.upload {
  margin-bottom: 24px;
  display: flex;
  flex-direction: column;

  &__title {
    @include themify($themes) {
      color: themed('gray2');
    }
  }

  &__area {
    box-sizing: border-box;
  }

  &__img {
    padding: 1px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    @include themify($themes) {
      border: 1px solid themed('descrBorder');
    }

    img {
      max-width: 100%;
      max-height: 180px;
    }

    &__box {
      display: flex;
      flex-direction: column;
    }

    &__info {
      display: flex;
      justify-content: space-between;
      align-items: center;
      box-sizing: border-box;
      height: 40px;
      padding: 0 16px;
      @include themify($themes) {
        background-color: themed('workSpaceBg');
        border-left: 1px solid themed('descrBorder');
        border-right: 1px solid themed('descrBorder');
        border-bottom: 1px solid themed('descrBorder');
      }

      &__icon {
        cursor: pointer;

        & :global .disabled {
          cursor: default !important;
        }
      }
    }
  }

  &__doc {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 5px;
    padding: 9px 16px;
    @include themify($themes) {
      background-color: themed('workSpaceBg');
      border: 1px solid themed('descrBorder');
    }

    &__info {
      display: flex;
      align-items: center;
      box-sizing: border-box;
      height: 40px;

      &:first-child {
        width: calc(100% - 16px);
      }

      &__desc {
        display: flex;
        flex-direction: column;
        margin-left: 12px;
        @include text-overflow();

        span {
          @include text-overflow();
        }

        span:nth-child(2) {
          @include themify($themes) {
            color: themed('gray2');
          }
        }
      }
    }
  }
}

.upload.form {
  box-sizing: border-box;
}

.upload.modal {
  .upload__title {
    font-size: 14px;
    margin-bottom: 8px;
    @include themify($themes) {
      color: themed('textColor') !important;
    }
  }

  .upload__area > div {
    @include themify($themes) {
      background-color: themed('mainBg') !important;
    }
  }

  .upload__img,
  .upload__img__info {
    @include themify($themes) {
      background-color: themed('mainBg') !important;
    }
  }

  .upload__img {
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
  }

  .upload__img__info {
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
  }

  .upload__doc {
    @include themify($themes) {
      background-color: themed('mainBg') !important;
    }
  }
}