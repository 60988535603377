
        @import "@control-front-end/common/styles/variables";
        @import "@control-front-end/common/styles/mixins";
        
.dateLabel {
  @include themify($themes) {
    color: themed('gray2');
  }
}

.formField {
  & > div {
    display: flex;
  }

  a {
    @include text-overflow();
  }
}

.fieldValueTotal {
  display: flex;
  max-width: 100%;
  overflow-y: hidden;
}

.chips > div {
  max-width: 120px;
}
