
        @import "@control-front-end/common/styles/variables";
        @import "@control-front-end/common/styles/mixins";
        
.bulk {
  display: flex;
  align-items: center;
  flex-grow: 1;

  &__selection {
    display: flex;
    align-items: center;
    position: relative;
    flex-grow: 0;
    padding: 4px;
    margin-left: -5px;
    border-radius: 4px;
    border: 1px solid transparent;

    &.active {
      @include themify($themes) {
        border-color: themed('primaryColor');
      }
    }

    &__check {
      margin-bottom: 0 !important;
    }

    &__some {
      position: absolute;
      left: 4px;
      width: 16px;
      height: 16px;
      border-radius: 2px;
      pointer-events: none;
      @include themify($themes) {
        background-color: themed('primaryColor');
      }
      &:before {
        content: '-';
        position: absolute;
        left: 4px;
        top: -3px;
        font-size: 24px;
        @include themify($themes) {
          color: themed('mainBg');
        }
      }
    }

    &__dropdown {
      cursor: pointer;
      padding: 2px;
      @include btn-icon-hover();
    }

    &__label {
      display: flex;

      &.selected {
        @include themify($themes) {
          color: themed('textColor') !important;
        }
      }
    }

    &__menu {
      position: absolute;
      left: 0;
      top: 28px;
      box-shadow: 0 4px 8px rgba(57, 63, 70, 0.1);
      border-radius: 4px;
      @include themify($themes) {
        border: 1px solid themed('gray50');
      }
    }
  }

  &__actions {
    display: flex;
    align-items: center;
    position: relative;
    margin-left: 8px;

    i {
      padding: 4px;
      margin-right: 4px;
      cursor: pointer;
      @include btn-icon-hover();
    }

    &__menu {
      position: absolute;
      right: 5px;
      top: 24px;
      box-shadow: 0 4px 8px rgba(57, 63, 70, 0.1);
      border-radius: 8px;
      max-width: 240px !important;
      @include themify($themes) {
        border: 1px solid themed('gray50');
      }
    }
  }
}
