
        @import "@control-front-end/common/styles/variables";
        @import "@control-front-end/common/styles/mixins";
        
.empty {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  align-items: center;
  padding: 20px;

  &.small {
    .empty__icon {
      margin: 16px 0 16px;
    }
  }

  &.medium {
    .empty__icon {
      margin: 160px 0 28px;
      i, svg {
        width: 48px !important;
        height: 48px !important;
      }
    }
  }

  &__icon {
    :global .fill {
      @include themify($themes) {
        fill: themed('workSpaceBorder');
      }
    }
  }

  &__label {
    display: flex;
    flex-direction: column;
    text-align: center;
    margin-bottom: 16px;
    white-space: break-spaces;
    @include themify($themes) {
      color: themed('gray1');
    }
  }
}


