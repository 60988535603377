
        @import "@control-front-end/common/styles/variables";
        @import "@control-front-end/common/styles/mixins";
        
.select {
  margin-bottom: 4px;
  padding: 20px 0 !important;

  & > div {
    padding: 0 !important;
  }

  & > div,
  & > div:hover {
    cursor: initial;
  }

  & > div > div {
    top: 0;
  }

  & > div > div:last-child {
    cursor: pointer;
  }
}
