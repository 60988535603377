
        @import "@control-front-end/common/styles/variables";
        @import "@control-front-end/common/styles/mixins";
        
.divider {
  margin: 0;
  height:1px;
  border:none;
  @include themify($themes) {
    background-color: themed('workSpaceBorder');
  }
}

.userName {
  word-break: break-word;
}