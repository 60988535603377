
        @import "@control-front-end/common/styles/variables";
        @import "@control-front-end/common/styles/mixins";
        
.preview {
  &__root {
    display: flex;
    min-width: 264px;
    padding: 12px;
    align-items: center;
    @include themify($themes) {
      background-color: themed('mainBg');
    }
    position: relative;

    & > div:first-child {
      margin-right: 8px;
      width: 60px !important;
      height: 60px !important;
      @include themify($themes) {
        border: 2px solid themed('descrBorder') !important;
      }
    }

    :global .status {
      right: 0 !important;
      bottom: 0 !important;
    }
  }

  &__info {
    width: 100%;
    max-width: 320px;
    display: flex;
    flex-direction: column;
    margin-right: 12px;

    & > span {
      line-height: 20px;

      &:first-child {
        max-width: calc(100% - 64px);
        @include text-overflow();
      }

      &:not(:first-child) {
        @include themify($themes) {
          color: themed('gray1') !important;
        }
      }
    }
  }

  &__actions {
    display: flex;
    align-items: center;
    gap: 8px;
    position: absolute;
    top: 12px;
    right: 12px;

    & > * {
      cursor: pointer;
      @include btn-icon-hover();
    }
  }
}
