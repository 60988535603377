
        @import "@control-front-end/common/styles/variables";
        @import "@control-front-end/common/styles/mixins";
        
.colorOption {
  position: relative;
  cursor: pointer;
}

.sample {
  width: 16px;
  height: 16px;
  @include themify($themes) {
    border: 1px solid themed('gray2');
  }

  &:before {
    content: '';
    position: absolute;
    width: 24px;
    height: 24px;
    top: -3px;
    left: -3px;
    border-radius: 2px;
    box-sizing: border-box;
    @include themify($themes) {
      border: 1px solid themed('gray2');
    }
  }
}

.picker {
  position: absolute;
  bottom: -40px;
  right: 0;
  z-index: 2;
}
