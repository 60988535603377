
        @import "@control-front-end/common/styles/variables";
        @import "@control-front-end/common/styles/mixins";
        
.form {
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  margin-bottom: 24px;
  height: 100%;
  overflow: auto;

  &__wrap {
    flex-basis: 600px;
    min-width: 320px;
    border-radius: 8px;
    max-width: 100%;
    @include themify($themes) {
      border: 1px solid themed('disabled');
      background-color: themed('mainBg');
    }
  }

  &__header {
    position: relative;
    box-sizing: border-box;
    padding: 24px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    @include themify($themes) {
      background-color: themed('workSpaceBg');
      border-bottom: 1px solid themed('disabled');
    }

    &__title {
      box-sizing: border-box;
      font-size: 18px;
      font-weight: 600;
      display: flex;
      align-items: center;
      word-break: break-word;
    }

    &__description {
      box-sizing: border-box;
      margin-top: 12px;
      word-break: break-word;
      @include themify($themes) {
        color: themed('gray2');
      }
    }
  }

  &__content {
    width: 100%;
    box-sizing: border-box;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;

    & > div {
      width: 100%;
    }
  }

  &__navigation {
    display: flex;
    width: fit-content;
    height: 100%;
    flex-shrink: 0;
    overflow-x: hidden;
    overflow-y: auto;
    scrollbar-gutter: stable;
    position: relative;
  }

  &__tabs {
    position: relative;
    top: 25px
  }

  &.single {
    box-sizing: border-box;
  }

  &.page {
    margin-bottom: 0;

    .form__wrap {
      border: 0;
      flex-basis: 100%;
      min-width: initial;
    }
  }

  &.panel {
    justify-content: flex-start;

    .form__wrap {
      border: 0;
      margin: 0 -24px -24px -24px;
      flex-basis: 200px;
      flex-grow: 1;
    }
  }

  &.modal {
    justify-content: flex-start;
    overflow-x: hidden;
    margin-bottom: 0;

    .form__wrap {
      border: 0;
      background-color: inherit;
      flex-grow: 1;
      display: flex;

      :global .field {
        @include themify($themes) {
          background-color: themed('mainBg');
        }
      }
    }

    .form__content {
      display: flex;
      flex-grow: 1;
    }
  }

  &.showNavigation {
    padding: 20px 20px 20px 0;
    flex-grow: 1;
  }
}

.formLoader {
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-content: center;
}

@include respond-to(mobile) {
  .form__navigation {
    display: none;
  }
}
