
        @import "@control-front-end/common/styles/variables";
        @import "@control-front-end/common/styles/mixins";
        
.event {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  @include themify($themes) {
    background-color: themed('cardBg');
    border: 1px solid themed('disabled');
  }

  &__wrap {
    transition: .3s;
    box-sizing: border-box;
    height: 100%;
    width: 100%;
    padding: 0 !important;
    z-index: 2;
    user-select: initial;
    position: initial;
    left: initial;
    top: initial;

    & :global .event__header {
      @include themify($themes) {
        background-color: themed('primaryColor');
        border-color: themed('primaryColor');
      }
    }

    & :global .event__header__title {
      @include themify($themes) {
        color: themed('editedBadgeTextColor');
        -webkit-text-fill-color: themed('editedBadgeTextColor');
      }

      &::placeholder {
        @include themify($themes) {
          color: themed('editedBadgeTextColor') !important;
        }
      }

      & [disabled] {
        @include themify($themes) {
          -webkit-text-fill-color: themed('editedBadgeTextColor');
        }
      }
    }

    & :local .event__row {
      height: 40px;
      box-sizing: border-box;
      margin-bottom: 0;
      @include themify($themes) {
        border-bottom: 1px solid themed('editedBadgeBg');
        background-color: themed('workSpaceBg');
      }
      padding-top: 0;
    }

    & :local .event__attr__date {
      @include themify($themes) {
        background-color: themed('workSpaceBg');
      }
    }

    & :local .event__attr__title {
      @include themify($themes) {
        background-color: themed('workSpaceBg');
      }
    }

    & .event {
      box-shadow: 0 4px 7px 0 rgba(0, 0, 0, 0.1);
      @include themify($themes) {
        border-color: themed('primaryColor');
      }
    }
  }

  &__content {
    position: relative;
    display: flex;
    flex-direction: column;
    flex: 1 0 auto;
    padding-bottom: 12px;
    height: calc(100% - 120px);
    box-sizing: border-box;
  }

  &__main {
    display: flex;
    height: calc(100% - 172px);

    &__loader {
      position: absolute;
      background-color: rgba(255, 255, 255, 0.5);
    }
  }

  &__rows {
    flex: 0 0 auto;
  }

  &__row {
    height: 28px;
    display: flex;
    padding-top: 6px;
  }

  &__people {
    width: 100%;
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    justify-content: flex-start;
    box-sizing: border-box;
    padding: 0 16px;
    @include themify($themes) {
      color: themed('gray1');
    }
  }

  &.error {
    @include themify($themes) {
      border: 1px solid themed('red1') !important;
    }

    & > span {
      @include themify($themes) {
        color: themed('red1') !important;
      }
    }
  }

  &.active {
    @include themify($themes) {
      border-bottom-color: themed('primaryColor') !important;
    }
  }

  &__title {
    width: 100%;
    padding-top: 10px !important;
    padding-bottom: 0 !important;

    :global .leftIcon {
      margin-right: 8px !important;
    }
  }

  &__title :global .field {
    padding: 0 16px 10px 16px;
    @include themify($themes) {
      border-bottom-color: themed('workSpacebg') !important;
    }
  }

  &__title:global.focus .field {
    @include themify($themes) {
      border-bottom-color: themed('primaryColor') !important;
    }
  }

  &__title:global.selected .field {
    @include themify($themes) {
      border-bottom-color: themed('editedBadgeBg');
    }
  }

  &__title:global.error .field {
    @include themify($themes) {
      border-bottom-color: themed('red1') !important;
    }
  }

  &__title::placeholder {
    font-weight: normal;
    @include themify($themes) {
      color: themed('gray1') !important;
    }
  }

  &__title:global.error {
    & input::placeholder {
      @include themify($themes) {
        color: themed('red1') !important;
      }
    }
  }

  &__descr {
    height: 38px;
    max-height: 38px;
    overflow: auto;
    padding: 12px 16px 0 16px;
    @include word-break();
  }

  &__iframeBtn {
    position: absolute;
    right: 20px;
    bottom: 16px;
  }

  :global .event__descr__chip {
    &:hover {
      @include themify($themes) {
        border-color: themed('primaryColor');
        color: themed('primaryColor');
      }
    }

    margin-left: 8px;
    margin-right: 8px;
    display: inline-flex;
    padding: 0 7px;
    position: relative;
    box-sizing: border-box;
    align-items: center;
    @include themify($themes) {
      border-color: themed('descrBorder');
    }
    border-width: 1px;
    border-style: solid;
    margin-bottom: 2px;
    height: 24px;
    border-radius: 4px;
    cursor: pointer;

    &__icon {
      width: 12px;
      display: flex;
      margin-right: 8px;

      svg {
        width: 12px;
        height: 12px;
      }

      path {
        @include themify($themes) {
          fill: themed('primaryColor');
        }
      }
    }

    &__label {
      max-width: 120px;
      margin-top: -1px;
      font-weight: 400;
      font-size: 14px;
      @include text-overflow();
    }
  }
}

@include respond-to(mobile) {
  .event {
    border: none !important;
  }

  .event__wrap {
    min-height: 400px !important;

    .event__people {
      width: 100% !important;
    }

    .event__row {
      padding: 0 16px;

      & > div {
        padding-left: 0 !important;
        padding-right: 0 !important;
        width: calc(100vw - 32px);
      }
    }

    .event__title {
      padding-top: 10px !important;

      & :global .field {
        padding: 0 0 10px 0 !important;
        @include themify($themes) {
          border-bottom-color: themed('gray50') !important;
        }
      }
    }
  }
}

.loading {
  position: relative;
}
