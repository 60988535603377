
        @import "@control-front-end/common/styles/variables";
        @import "@control-front-end/common/styles/mixins";
        
.fs {
  box-sizing: border-box;
  position: relative;
  z-index: 10;

  &.visible {
    cursor: pointer;
    box-sizing: border-box;
  }

  &.disabled {
    cursor: default;
  }

  &__type {
    display: flex;
    align-items: center;
    border-radius: 4px;
    padding: 8px;
    @include themify($themes) {
      background-color: themed('mainBg');
      color: themed('gray1');
    }

    &.default {
      .fs__type__title {
        display: flex;
      }
    }

    &.compact {
      .fs__type__title {
        display: none;
      }
    }

    &__title {
      margin-right: 4px;
    }

    &__value {
      font-weight: 600;
      margin-right: 8px;
      @include text-overflow();
    }
  }

  &__wrap {
    position: absolute;
    right: 0;
    top: 40px;
    display: flex;
    flex-direction: column;
    border-radius: 4px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.17);
    width: 240px;
    @include themify($themes) {
      background-color: themed('mainBg');
    }
  }

  &__menu {
    min-height: 40px;
    max-height: 224px !important;
    box-shadow: none;

    &__title {
      margin: 2px 12px;
      cursor: default !important;
    }

    &__search {

      &__field {
        padding: 8px 0 8px 0 !important;
        margin: 0 12px;

        :global .rightIcon {
          margin-bottom: 0;
          margin-top: 4px;
          cursor: pointer;

          i, svg {
            width: 12px;
            height: 12px;
          }
        }
      }
    }
  }
}