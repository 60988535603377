
        @import "@control-front-end/common/styles/variables";
        @import "@control-front-end/common/styles/mixins";
        
.nl {
  display: flex;
  overflow: auto;
  box-shadow: 0 3px 8px rgba(0, 0, 0, 0.06);
  border-radius: 4px;
  width: 100%;
  height: 100%;
  scrollbar-width: none;

  @include themify($themes) {
    background-color: themed('gray60');
    border: 1px solid themed('gray50');
  }

  position: relative;

  &.selected {
    @include themify($themes) {
      border: 1px solid themed('primaryColor');
    }
  }

  &.actor {
    box-sizing: border-box;
    overflow: hidden;
    @include themify($themes) {
      background-color: themed('mainBg') !important;
    }

    .nl__content {
      padding-top: 36px;
      box-sizing: border-box;
    }
  }

  &.script {
    box-sizing: border-box;

    @include themify($themes) {
      background-color: themed('gray60') !important;
    }

    .nl__header>div {
      @include themify($themes) {
        background-color: themed('gray50') !important;
      }
    }

    .nl__content {
      padding-top: 48px;
      box-sizing: border-box;

      &__script {
        display: flex;
        height: 100%;
      }
    }
  }

  &.layer {
    box-sizing: border-box;
  }

  &.flaskView:not(:hover) {
    .nl__header {
      opacity: 0;
    }
  }

  &__header {
    display: flex;
    height: 36px;
    transition: opacity 450ms cubic-bezier(.23, 1, .32, 1) 0ms;
    opacity: 1;
    position: absolute;
    right: 0;
    left: 0;
    z-index: 1;
    @include themify($themes) {
      background-color: themed('mainBg');
    }

    &__title {
      @include text-overflow();
    }

    &__actions {
      flex-grow: 1;
    }

    &__menu {
      position: absolute;
      top: 4px;
      right: 12px;
      z-index: 2;
      border-radius: 8px;

      &.wrap {
        padding: 8px 0;
      }

      [class^='nested'] {
        position: absolute !important;
        left: 144px !important;
        margin-top: 4px;
      }

      &__divider {
        margin: 2px 0 2px 0 !important;
      }
    }
  }

  &__content {
    width: 100%;
    height: calc(100% - 4px);
    box-sizing: border-box;
    position: relative;
  }

  &__iframe {
    border: 0;
    width: 100%;
    height: 100%;
  }

  &__dis {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }

  &__loader,
  &__error {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }
}
