
        @import "@control-front-end/common/styles/variables";
        @import "@control-front-end/common/styles/mixins";
        
.formula {
  display: flex;
  position: relative;
  min-height: 36px;
  border-radius: 4px;
  padding: 0 8px;
  margin-top: 8px;
  @include themify($themes) {
    border: 1px solid themed('gray50');
    background-color: themed('mainBg');
  }

  &.active {
    @include themify($themes) {
      border: 1px solid themed('primaryColor');
    }
  }

  &.error {
    @include themify($themes) {
      border: 1px solid themed('red1') !important;
    }
  }

  &.disabled {
    opacity: 0.5;
    cursor: default;
    pointer-events: none;
  }

  &__wrap {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
  }

  &__left {
    display: flex;
    justify-content: center;
    width: 32px;
    position: relative;
    top: 1px;
    left: -5px;
    @include themify($themes) {
      border-right: 1px solid themed('gray50');
    }

    & > i {
      margin-top: 8px;
    }
  }

  &__field {
    display: flex;
    flex-wrap: wrap;
    flex: 1 1 auto;
    align-items: center;
    padding: 0 8px;

    :global .chip {
      border-radius: 4px;
      padding: 4px;
      margin: 2px;

      &:hover {
        cursor: pointer;
      }
    }

    &:before {
      content: '=';
      display: block;
      margin: 4px 4px 4px -4px;
      @include themify($themes) {
        color: themed('gray35');
      }
    }
  }

  &__close {
    margin-top: 8px;
    width: 20px !important;
    height: 20px !important;

    & i,
    & svg {
      width: 8px !important;
      height: 8px !important;
    }
  }
}