
        @import "@control-front-end/common/styles/variables";
        @import "@control-front-end/common/styles/mixins";
        
.filesDiff {
  display: inline-flex;
  cursor: pointer;

  &__content {
    flex-grow: 1;
    flex-basis: 50%;
    max-width: 50%;
  }

  &__item {

    &.removed {
      span {
        text-decoration: line-through;
        @include themify($themes) {
          color: themed('red1');
        }
      }

      img {
        @include themify($themes) {
          border: 1px solid themed('red1');
        }
      }

      :global .fill {
        @include themify($themes) {
          fill: themed('red1') !important;
        }
      }
    }

    &.new {
      span {
        @include themify($themes) {
          color: themed('success');
        }
      }

      img {
        @include themify($themes) {
          border: 1px solid themed('success');
        }
      }

      :global .fill {
        @include themify($themes) {
          fill: themed('success') !important;
        }
      }

      .filesDiff__item__file {
        background-color: #E4F9E6;
      }
    }

    & img {
      max-width: 100%;
      max-height: 100%;
      border-radius: 4px;
    }

    &__wrap {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 32px;
      height: 32px;
    }

    &__file {
      display: flex;
      width: 100%;
      height: 100%;
      border-radius: 4px;
      align-items: center;
      justify-content: center;
    }
  }
}
