
        @import "@control-front-end/common/styles/variables";
        @import "@control-front-end/common/styles/mixins";
        
.wb {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  z-index: 1000;
  overflow: auto;
  position: fixed;
  align-items: center;
  align-content: center;
  background-color: rgba(57, 63, 70, 0.8);

  &__wrap {
    width: 500px;
    height: 500px;
    margin: auto;
    box-sizing: border-box;
    display: grid;
    grid-template-rows: 1fr;
    flex-direction: column;
    border-radius: 4px;
    position: relative;
    resize: both;
    overflow: auto;
    min-width: 300px;
    min-height: 300px;
    max-width: 95vh;
    max-height: 95vh;
    @include themify($themes) {
      background-color: themed('mainBg');
      border: 1px solid themed('workSpaceBorder') !important;
    }
  }

  &__title {
    position: absolute;
    left: 12px;
    top: 4px;
  }

  &__close {
    position: absolute;
    right: 4px;
    top: 4px;
    padding: 8px;
    cursor: pointer;
  }

  &__discl {
    position: absolute;
    left: 50%;
    bottom: 8px;

    span {
      position: relative;
      left: -50%;
    }
  }

  &__content {
    box-sizing: content-box;
    border: 0;
    padding: 0;
    margin: 0;
    line-height: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
  }
}
