
        @import "@control-front-end/common/styles/variables";
        @import "@control-front-end/common/styles/mixins";
        
.modal {
  height: 90% !important;
  max-height: 90% !important;
}

.content {
  padding: 0 !important;
}

.history {
  padding: 0 !important;
  height: 100%;
  overflow: hidden;

  &__filters {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    padding: 20px;
    box-sizing: border-box;
    gap: 8px;

    &__field {
      width: fit-content;
      min-width: 76px;
      border-radius: 4px;
      @include themify($themes) {
        border: 1px solid themed('blueBg');
      }

      &__item {
        @include themify($themes) {
          border-top: 1px solid themed('gray50');
        }

        &.high > div {
          padding-top: 2px;
          padding-bottom: 2px;
        }
      }

      & > div {
        padding: 0 !important;
        border: none;
        margin-top: 0 !important;
      }

      :global .field {
        @include themify($themes) {
          background-color: themed('blueBg');
          border-color: themed('blueBg') !important;
        }

        input {
          font-weight: 600;
          @include themify($themes) {
            color: themed('primaryColor') !important;
          }
        }
      }

      :global .rightIcon {
        margin-left: 8px !important;
      }
    }
  }

  &__log {
    display: flex;
    flex-direction: column;
    gap: 12px;
    height: calc(100% - 76px);
    overflow: auto;
  }
}

.user {
  @include themify($themes) {
    border-top: 1px solid themed('gray50');
  }

  & > div {
    & > span {
      display: none;
    }
  }
}
