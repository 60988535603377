
        @import "@control-front-end/common/styles/variables";
        @import "@control-front-end/common/styles/mixins";
        
.agf {
  display: flex;
  width: 100%;
  flex-wrap: wrap;

  &__avatar {
    display: flex;
    flex-direction: column;
    margin-right: 32px;

    span {
      align-self: center;
      margin-bottom: 8px;
    }
  }

  &__data {
    flex-grow: 1;
    align-self: center;

    &__ref {
      position: relative;

      &__icon {
        position: absolute;
        left: 76px;
        top: 1px;
        z-index: 1;
      }
    }

    &__field {
      flex-grow: 1;

      :global .field {
        @include themify($themes) {
          background-color: themed('mainBg');
        }
      }
    }
  }
}
