
        @import "@control-front-end/common/styles/variables";
        @import "@control-front-end/common/styles/mixins";
        
.formula__input {
  position: relative;
  margin: 4px 0;

  &:hover {
    opacity: 1;

    i {
      opacity: 1;
    }
  }

  &__input {
    //font-family: monospace;
    font-family: 'Noto Sans Mono', monospace;
    border: none;
    padding: 0;
    outline: none;
    box-sizing: border-box;
    background-color: transparent;
    min-width: 1ch;
    width: 1ch;
    line-height: 20px;
    &.error {
      color: red;
    }
  }

  &__btns {
    display: flex;
    position: absolute;
    top: 20px;
    right: -80px;
    border-radius: 0 4px 4px 4px;
    z-index: 9999;
    @include themify($themes) {
      border: 1px solid themed('primary70Color');
      background-color: themed('secondaryColor');
    }

    & i {
      @include icon-primary;
      cursor: pointer;
      padding: 8px 12px;

      &:first-child {
        @include themify($themes) {
          border-right: 1px solid themed('primary70Color');
        }
      }

      &.active :global .fill {
        @include themify($themes) {
          fill: themed('primaryColor') !important;
        }
      }
    }
  }
}
