
        @import "@control-front-end/common/styles/variables";
        @import "@control-front-end/common/styles/mixins";
        
.card {
  display: flex;
  box-sizing: border-box;
  justify-content: center;

  &.panel {
    flex-basis: 200px;
    flex-grow: 1;

    .card__section:first-child {
      margin-bottom: 16px;
    }
  }

  &.popup {
    flex-basis: 360px;
    flex-grow: 1;
    justify-content: flex-start;

    div,
    span {
      font-size: 12px !important;
    }

    .card__wrap {
      flex-basis: 320px !important;
    }

    .card__section {
      margin-bottom: 0;
      padding: 12px 0;
    }
  }

  &.modal {
    flex-basis: 200px;
    flex-grow: 1;
  }

  &.iframe {
    width: 100%;

    .card__section {
      padding: 16px;
    }

    .card__wrap {
      flex-basis: auto;
      width: 100%;
    }
  }

  &.default {
    .card__content {
      padding: 24px;

      &.wrap {
        padding: 4px 0 0 !important;

        .card__section {
          padding: 16px;
        }
      }
    }
  }

  &__wrap {
    flex-basis: 600px;
    min-width: 310px;
    border-radius: 8px;
    flex-direction: column;
    overflow: hidden;
  }

  &__content {
    box-sizing: border-box;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    margin: 4px;

    &__title {
      max-width: calc(100% - 24px);
      margin: 8px 16px;
      @include text-overflow();
    }

    &__forms {
      display: flex;
      flex-direction: column;
    }

    &__divider {
      margin: 16px 0 20px;
      &:last-child {
        display: none;
      }
    }

    &__chip {
      flex-direction: row-reverse;
      cursor: pointer;
      margin-right: 0 !important;
      margin-bottom: 12px !important;

      &:hover {
        @include themify($themes) {
          background-color: themed('blueBg');
          border-color: themed('blueBg');
        }

        :global .fill {
          @include themify($themes) {
            fill: themed('primaryColor');
          }
        }
      }

      i {
        margin-left: 8px;
        margin-right: 0 !important;
      }
    }

    &__accessDenied {
      display: flex;
      height: 44px;
      align-items: center;
      justify-content: center;
      padding: 12px 16px 0;
    }
  }

  &__section {
    display: flex;
    flex-direction: column;
    padding-top: 12px;

    &:empty {
      display: none;
    }

    &__row {
      display: flex;
      justify-content: space-between;
    }

    &__item {
      display: flex;
      margin-bottom: 12px;

      &:not(:last-child) {
        margin-right: 12px;
      }
    }

    &__openall {
      cursor: pointer;
      transition: transform 0.3s;
      transform: rotate(180deg);

      &.open {
        transform: rotate(270deg);
      }
    }
  }

  &__field {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    border-radius: 4px;
    overflow-wrap: break-word;

    & > div:not(:only-child) {
      margin: 0 4px 4px 0;
    }

    &.multiCol {
      padding: 8px;
      @include themify($themes) {
        background-color: themed('workSpaceBg');
      }

      & > * {
        width: 100%;
        @include word-break();
      }
    }

    &.wrap > span + div {
      margin-top: 12px;
    }

    &__actions {
      opacity: 0;
    }

    &:hover {
      .card__field__actions {
        opacity: 1;
      }
    }

    &__label {
      display: flex;
      align-items: center;
      gap: 2px;
      @include themify($themes) {
        color: themed('gray2');
      }
    }

    &__values {
      display: flex;
      width: 100%;
      flex-wrap: wrap;

      & > * {
        margin-bottom: 4px;

        &:not(:last-child) {
          margin-right: 8px;
        }

        &:not(:only-of-type) {
          position: relative;

          &:not(:last-child):after {
            content: ',';
            display: block;
            position: absolute;
            right: -3px;
            bottom: 0;
            @include themify($themes) {
              color: themed('textColor');
            }
          }
        }
      }
    }

    &__img {
      height: auto;
      width: auto !important;
      max-width: 100%;
      cursor: pointer;
    }

    &__file {
      display: flex;
      align-items: center;
      cursor: pointer;
      margin-bottom: 10px;

      span {
        margin-left: 8px;
        width: calc(100% - 24px);
        @include text-overflow;
      }

      &__img {
        &__wrap {
          @include img_wrap_icon(16px, 'editedBadgeBg');
        }

        max-width: 16px;
        max-height: 16px;
      }
    }
  }

  &__tooltip {
    display: none;
  }
}
