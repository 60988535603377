
        @import "@control-front-end/common/styles/variables";
        @import "@control-front-end/common/styles/mixins";
        
.chart {
  &__header {
    &.draggable {
      cursor: move;
    }
  }
  &__actions {
    & > * {
      position: relative;
      cursor: pointer;
      padding: 4px;

      &:not(.disabled):not(:global [class*='pinned']) > i {
        @include btn-icon-hover();
      }
    }
    &__edit {
      &.disabled {
        cursor: default;
      }
    }
  }
}

.errorImg {
  width: 40px;
  height: 40px;
  background-image: url('./img/bad_chart.svg');
}

div.settingsPopoverContent {
  z-index: 9;
}

.settings {
  @include themify($themes) {
    border: 1px solid themed('gray50');
    background-color: themed('mainBg');
  }
}

.loader {
  @include pagination-loader();
  bottom: 0;

  &.initial {
    opacity: 1;
  }
}

.container {
  position: relative;
  display: flex;
  overflow: auto;
  justify-content: center;
  align-items: center;
}

.counterType {
  & > div {
    display: flex;
    gap: 8px;
    justify-content: center;
  }
}

.rotate {
  transition: 0.3s;
}
