
        @import "@control-front-end/common/styles/variables";
        @import "@control-front-end/common/styles/mixins";
        
.notify {
  display: flex;
  position: relative;

  &__popup {
    box-sizing: border-box;
    position: fixed;
    width: 480px;
    border-radius: 10px;
    z-index: 999;
    left: 240px;
    top: 28px;
    @include popup-shadow();
    @include themify($themes) {
      background-color: themed('mainBg');
      border: 1px solid themed('gray50');
    }
  }

  &.collapsed {
    position: absolute;
    top: 32px;
    left: 20px;

    .notify__popup {
      left: 56px;
    }
  }

  &__icon {
    @include btn-icon();
  }

  &__badge {
    position: absolute;
    cursor: pointer;
    top: -6px;
    right: -6px;
  }

  &__headers {
    position: relative;
    display: flex;
    justify-content: space-between;
    padding: 19px 20px 18px 20px;
    @include themify($themes) {
      border-bottom: 1px solid themed('gray50');
    }

    & > div:last-child {
      text-align: right;
    }
  }

  &__title {
    position: absolute;
    width: 100%;
    right: 0;
    z-index: 0;
    text-align: center;
  }

  &__btn {
    width: 96px;
    cursor: pointer;
    transition: .3s;
    z-index: 1;
    @include themify($themes) {
      color: themed('primaryColor');
    }

    &.hidden {
      display: none;
    }

    &:hover {
      @include themify($themes) {
        color: themed('primaryColorHover');
      }
    }
  }

  &__list {
    box-sizing: border-box;
    overflow: auto;
    max-height: 500px;
    border-radius: 0 0 10px 10px;
    @include themify($themes) {
      background-color: themed('workSpaceBg');
    }
  }

  &__item {
    display: flex;
    flex-direction: row;
    box-sizing: border-box;
    padding: 0 20px;
    cursor: pointer;
    transition: .3s;

    &:hover {
      @include themify($themes) {
        background-color: themed('blueBg');
      }
    }

    &:last-child > div {
      border: none;
    }

    &__container {
      display: flex;
      flex-direction: row;
      width: 100%;
      padding: 20px 0;
      cursor: pointer;
      @include themify($themes) {
        border-bottom: 1px solid themed('editedBadgeBg');
      }
    }

    &__avatar {
      margin-right: 12px;
    }

    &__content {
      overflow-x: hidden;
      overflow-y: auto;

      &__row {
        hyphens: auto;
        margin-bottom: 8px;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 5;
        -webkit-box-orient: vertical;
        white-space: normal;
        line-height: 24px;

        & :global a {
          @include word-break();
        }

        &.time {
          font-size: 12px;
          @include themify($themes) {
            color: themed('gray2');
          }
        }

        span {
          font-weight: 600;
        }

        &:last-child {
          margin-bottom: 0;
        }

        &:empty {
          display: none;
        }
      }

      a {
        @include themify($themes) {
          color: themed('primaryColor');

          &:hover {
            color: themed('linkColorHover');
          }
        }
      }
    }
  }

  &__empty {
    text-align: center;
    padding: 54px 60px 64px 60px;

    &__head {
      font-weight: 600;
      margin-bottom: 8px;
    }
  }

  :global .event__descr__chip {
    &:hover {
      @include themify($themes) {
        border-color: themed('primaryColor');
        color: themed('primaryColor');
      }
    }

    margin-left: 8px;
    margin-right: 8px;
    display: inline-flex;
    padding: 0 7px;
    position: relative;
    box-sizing: border-box;
    align-items: center;
    @include themify($themes) {
      border-color: themed('descrBorder');
    }
    border-width: 1px;
    border-style: solid;
    margin-bottom: 2px;
    height: 24px;
    border-radius: 4px;
    cursor: pointer;

    &__icon {
      width: 12px;
      display: flex;
      margin-right: 8px;

      svg {
        width: 12px;
        height: 12px;
      }

      path {
        @include themify($themes) {
          fill: themed('primaryColor');
        }
      }
    }

    &__label {
      max-width: 120px;
      margin-top: -1px;
      font-weight: 400;
      font-size: 14px;
      @include text-overflow();
    }
  }
}

@include respond-to(mobile) {
  .notify {
    width: calc(100vw - 16px);
    left: 8px;
  }

  .notify__empty {
    padding: 60px 24px;
  }

  .notify__title > span {
    font-size: 14px !important;
  }
}
