
        @import "@control-front-end/common/styles/variables";
        @import "@control-front-end/common/styles/mixins";
        
.snippets {
  width: 100%;
  position: absolute;
  bottom: 100%;
  @include themify($themes) {
    background-color: themed('mainBg');
  }

  &.manual {
    .snippets__field :global .field {
      display: flex;
    }
  }

  &.auto {
    .snippets__field :global .field {
      visibility: hidden !important;
      height: 1px;
    }
  }

  &__field {
    display: flex;
    flex-direction: column-reverse;
    justify-content: flex-end;
    padding: 0 !important;
    margin-bottom: 4px;

    :global .label {
      display: none;
    }
  }

  &__content {
    & :global .popoverContent {
      position: relative;
      margin-top: 0;
    }

    :global .searchList {
      max-height: 200px !important;
      overflow-y: auto;
      overflow-x: hidden !important;
      padding-bottom: 12px;

      &:empty {
        display: none;
      }
    }
  }

  &__item {
    position: relative;
    padding: 0 16px !important;

    &.empty {
      @include themify($themes) {
        color: themed('gray2');
      }
    }

    &.titled {
      padding-top: 32px !important;
      .snippets__item__category {
        display: flex;
      }

      &:not(:first-child):before {
        content: '';
        height: 1px;
        width: 100%;
        position: absolute;
        top: 0;
        @include themify($themes) {
          background-color: themed('gray50');
        }
      }
    }

    &__text {
      font-style: italic;
      width: 100%;
      margin-left: 1px;
      @include text-overflow();
      @include themify($themes) {
        color: themed('gray2');
      }
    }

    &__category {
      display: none;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      padding: 8px 16px;
      box-sizing: border-box;
      @include text-overflow();
      @include themify($themes) {
        background-color: themed('mainBg');
      }
    }
  }

  &__loader {
    position: absolute !important;
    pointer-events: none;
    display: flex;
    align-items: center;
    bottom: 10px;
    right: 12px;
    width: fit-content !important;
  }
}
