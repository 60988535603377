
        @import "@control-front-end/common/styles/variables";
        @import "@control-front-end/common/styles/mixins";
        
.fcEventParentSelected {
  z-index: 100 !important;
}

.eventWrapper {
  overflow: hidden;
}

.bluredEvent {
  opacity: 0.5;
}

.eventPopover {
  width: 100%;
  height: 100%;
  overflow: hidden;
  border-radius: 4px;
}

.popoverMoreMenu {
  position: static !important;
}

.description {
  display: -webkit-box;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 6;
  overflow: hidden;
  word-break: break-all;
  white-space: pre-wrap;
  overflow-wrap: anywhere;
  font-size: 12px;
  font-weight: 400;
  max-height: 200px;
  min-height: 30px;
}

.eventPopoverContent {
  z-index: 999 !important;
}

.eventMenu {
  min-width: 275px;
  max-width: 400px;
  min-height: 160px;
  max-height: 400px;
  overflow: hidden !important;
  z-index: 2;
  position: relative;
}

.fullscreenOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 1;
  cursor: pointer;
}

.eventLabel {
  line-height: 14px;
}

.eventLabel.cutLabel {
  height: 1.2em;
}

.eventPopover .eventCard {
  cursor: pointer;
  background-color: transparent !important;
  overflow: hidden;
}

.eventMonthGrid .eventDote {
  @include themify($themes) {
    background-color: themed('primaryColor');
  }
  border-radius: 50%;
  width: 8px;
  height: 8px;
  margin-top: 3px;
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: auto;
}

.eventSelected.eventMonthGrid .eventDote {
  background-color: white;
}
.eventTooltip {
  z-index: 1001 !important;
}

.loader {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 100;
}
