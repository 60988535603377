
        @import "@control-front-end/common/styles/variables";
        @import "@control-front-end/common/styles/mixins";
        
.jse {
  position: relative;
  width: 100%;
  height: 100%;

  & > div:nth-child(2) {
    width: 100%;
    height: calc(100% - 72px);
  }

  :global .ace_gutter {
    z-index: 2;
  }

  :global .jsoneditor {
    width: 100%;
    height: 100%;
    position: relative;
  }

  :global .jsoneditor-outer {
    width: 100%;
    height: 100%;
  }

  :global .jsoneditor-text {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    @include themify($themes) {
      border-color: themed('gray50') !important;
    }
  }

  :global .jsoneditor-menu {
    position: absolute;
    right: 16px;
    top: 12px;
    z-index: 9;

    a,
    & > button {
      display: none;
    }

    button {
      width: 16px;
      height: 16px;
      padding: 1px 0;
      margin-left: 8px;
      border-radius: 2px;
      border: none;
      cursor: pointer;
    }
  }

  :global .jsoneditor-statusbar {
    font-size: 12px;
    @include themify($themes) {
      color: themed('textColor') !important;
    }
  }

  :global .jsoneditor-mode-text {
    .jsoneditor-validation-errors-container {
      display: none;
    }
  }

  &.withMenu {
    :global .jsoneditor-menu {
      button.jsoneditor-format {
        display: inline-flex;
        background: transparent url('img/format_json.svg') no-repeat;
        &:hover {
          background: transparent url('img/format_json_hover.svg') no-repeat;
        }
      }

      button.jsoneditor-repair {
        display: inline-flex;
        background: transparent url('img/repair.svg') no-repeat;
        &:hover {
          background: transparent url('img/repair_hover.svg') no-repeat;
        }
      }

      button.jsoneditor-undo {
        display: inline-flex;
        background: transparent url('img/undo.svg') no-repeat;
        &:hover {
          background: transparent url('img/undo_hover.svg') no-repeat;
        }
      }

      button.jsoneditor-redo {
        display: inline-flex;
        background: transparent url('img/redo.svg') no-repeat;
        &:hover {
          background: transparent url('img/redo_hover.svg') no-repeat;
        }
      }
    }
  }
}
