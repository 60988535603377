
        @import "@control-front-end/common/styles/variables";
        @import "@control-front-end/common/styles/mixins";
        
.at {
  display: block;
  position: absolute;
  top: 0;
  z-index: 10000;
  transform-origin: left top;

  &__item {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    width: 24px;
    height: 24px;
    border-radius: 24px 24px 24px 0;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    border: 1px solid rgba(255, 255, 255, 0.1);
    @include themify($themes) {
      background-color: themed('primaryColor');
    }
    position: absolute;

    & > span {
      @include themify($themes) {
        color: themed('mainBg');
      }
    }

    &.selected {
      z-index: 1;
    }
  }
}
