
        @import "@control-front-end/common/styles/variables";
        @import "@control-front-end/common/styles/mixins";
        
.call {
  width: 100%;
  border-radius: 8px;
  padding: 16px;
  box-sizing: border-box;
  @include themify($themes) {
    background-color: themed('mainBg');
  }

  &__arrow {
    & > i {
      padding: 0 8px;
      cursor: pointer;
      transition: transform 0.3s;
      transform: rotate(270deg);
      @include btn-icon-hover();
    }

    &.open > i {
      transform: rotate(90deg);
    }
  }

  &__label {
    @include themify($themes) {
      color: themed('gray2');
    }
  }

  &__value {
    width: 100%;
    @include text-overflow();

    &__actor {
      display: flex;
      align-items: center;
      gap: 8px;
    }
  }

  &__status {
    &.success {
      @include themify($themes) {
        background-color: themed('green5');
        color: themed('green1');
      }
    }

    &.error {
      @include themify($themes) {
        background-color: themed('redBg');
        color: themed('red1');
      }
    }

    &__resend {
      cursor: pointer;
      @include btn-icon-hover();
    }
  }

  &__main {

    & > div:not(:last-child) {
      width: 20%;
    }

    &__block {
      display: flex;
      flex-direction: column;
      gap: 8px;
      width: 100%;
    }
  }

  &__details {
    &__url {
      width: 100%;
      box-sizing: border-box;
      border-radius: 4px;
      padding: 12px;
      margin-bottom: 16px;
      @include themify($themes) {
        border: 1px solid themed('gray50');
      }

      i {
        cursor: pointer;
        @include btn-icon-hover();
      }
    }

    &__json {
      height: 228px;
      box-sizing: border-box;
      border-radius: 4px;
      @include themify($themes) {
        border: 1px solid themed('gray50');
      }

      &__view {
        display: flex;
        flex-direction: column;
        gap: 8px;
        padding: 12px;
        box-sizing: border-box;
        width: 50%;
        height: calc(100% - 16px);

        :global .ace_editor {
          font-size: 12px !important;
        }

        &__expand {
          cursor: pointer;
          @include btn-icon-hover();
        }
      }
    }
  }
}
