
        @import "@control-front-end/common/styles/variables";
        @import "@control-front-end/common/styles/mixins";
        
.content {
  padding-bottom: 0;

  &__banner {
    word-break: break-word;
  }
}

.table {
  display: table;
  width: 100%;
  table-layout: fixed;
  border-collapse: collapse;
  margin: 20px 0;

  &__row {
    display: table-row;

    &.head {
      @include themify($themes) {
        color: themed('mainMenuColor');
        background-color: themed('gray50');
      }
    }
  }

  &__cell {
    display: table-cell;
    vertical-align: middle;
    width: 15%;
    height: 32px;

    &:first-child {
      //width: 1%;
      padding-right: 4px;
    }

    &:not(:last-child) {
      @include text-overflow;
    }

    & .red {
      @include themify($themes) {
        color: themed('red1');
      }
    }

    & .green {
      @include themify($themes) {
        color: themed('green1');
      }
    }
  }

  &__arrow {
    transform: rotate(-90deg);
  }
}


