
        @import "@control-front-end/common/styles/variables";
        @import "@control-front-end/common/styles/mixins";
        
.loader {
  position: absolute;
  top: 0;
  right: 6px;
  display: flex;
  height: 100%;
  align-items: center;
}

.field {
  &.default {
    padding: 20px 0;
  }

  &__content {
    position: relative;

    & > div {
      padding: 0 !important;
    }

    & > div > div {
      top: 0 !important;
    }

    &__end {
      cursor: pointer;
      margin-right: 6px;
    }
  }
}

.default {
  box-sizing: border-box;
}

.small {
  width: 100%;
  min-width: 200px;
  align-items: center;
  padding: 0 !important;

  & input {
    padding: 0 !important;
  }

  & :global .searchList {
    border-width: 0;
    border-radius: 4px;
    top: 32px;

    & > div > div {
      border-width: 0;
    }
  }
}

.autocompleteContent {
  :global .searchList {
    max-height: 186px !important;
  }
}
