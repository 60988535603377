
        @import "@control-front-end/common/styles/variables";
        @import "@control-front-end/common/styles/mixins";
        
.modal {
  max-height: 90% !important;

  &__content {
    overflow-x: hidden;

    & > div {
      overflow-x: hidden;
      @include hide-scroll-bar();
    }

    &__row > div {
      width: 50%;
      :global .label {
        font-weight: 600;
        font-size: 14px !important;
        transform: translate(0px, -22px) !important;
        @include themify($themes) {
          color: themed('textColor') !important;
        }
      }
    }

    &__divider {
      margin-bottom: 12px !important;
    }

    &__header {
      display: flex;
      width: calc(100% - 36px);
      padding-left: 32px;
      box-sizing: border-box;
      gap: 8px;

      & > div:nth-child(1) {
        min-width: 130px;
        max-width: 130px;
      }

      & > span {
        &:nth-child(2) {
          width: 29%;
          min-width: 236px;
          flex-shrink: 0;
        }
        &:nth-child(3) {
          width: 50%;
          min-width: 236px;
        }
        &:nth-child(4) {
          width: 20%;
        }
      }
    }

    &__list {
      display: flex;
      flex-direction: column;
      height: fit-content;
    }

    &__item {
      display: flex;
      width: 924px !important;
      align-items: center;
      justify-content: space-between;
      gap: 12px;
      margin-left: -16px;

      :global .drag {
        align-self: center;
        cursor: grab;
      }
    }

    &__axis {
      width: 100%;
    }

    &__loader {
      margin: auto;
    }

    &__btn {
      width: fit-content !important;
      padding: 0 !important;

      i,
      svg {
        width: 16px;
        height: 16px;
      }
    }

    &__checkbox {
      margin-top: 12px;

      &__info {
        @include btn-icon-hover();
      }
    }

    .regenerateBtn {
      background: none;
      padding: 0;
      border: none;
      @include btn-icon-hover();
      cursor: pointer;
    }

    .regenerateBtn svg {
      @include themify($themes) {
        color: themed('gray35') !important;
      }
    }

    .animateRegenarateBtn {
      animation: rotateAnimation 0.5s linear infinite;
    }

    @keyframes rotateAnimation {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(180deg);
      }
    }

    :global .layout {
      position: relative;
      display: flex;
      width: 100% !important;
      overflow-y: clip;
      overflow-x: hidden;
    }

    &.dragging {
      cursor: grabbing;
    }
  }
}

div.selectFullWidth {
  width: 100%;
}

div.third {
  width: 32%;
}
