
        @import "@control-front-end/common/styles/variables";
        @import "@control-front-end/common/styles/mixins";
        
.check {
  box-sizing: border-box;
  display: flex;
  align-items: center;

  &__box {
    box-sizing: border-box;
    margin-right: 4px;
  }

  & > div:last-child {
    width: 50%;
  }
}

