
        @import "@control-front-end/common/styles/variables";
        @import "@control-front-end/common/styles/mixins";
        
.resultList {
  overflow: auto;

  &::-webkit-scrollbar {
    width: 4px;
  }
}

.resultListItem {
  cursor: pointer;

  &:hover {
    @include themify($themes) {
      background-color: themed('primary50Color');
    }
  }

  &.active {
    @include themify($themes) {
      background-color: themed('primary60Color') !important;
    }
  }

  &.disabled {
    cursor: default;
    @include themify($themes) {
      background-color: themed('gray20') !important;
    }
  }

  span {
    height: fit-content;
  }

  &__avatar {
    flex: 0 0 auto;
    box-sizing: border-box;
    height: 22px !important;
    width: 22px !important;

    &__box {
      border-radius: 50%;
      padding: 1px;
      @include themify($themes) {
        border: 1px solid themed('descrBorder');
      }
    }
  }

  &__rightEl {
    flex: 0 0 40px;
    cursor: pointer;
    position: relative;

    :global .stroke {
      stroke-width: 2;
      transform: scale(1.3, 1.3) translate(-2px, -3px);
      @include themify($themes) {
        stroke: themed('primaryColor') !important;
      }
    }
  }

  &__counter {
    position: absolute;
    right: 24px;
  }
}

@include respond-to(mobile) {
  .resultListItem__rightEl {
    flex-basis: auto;
  }
}
