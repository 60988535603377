
        @import "@control-front-end/common/styles/variables";
        @import "@control-front-end/common/styles/mixins";
        
.schip {
  position: relative;
  padding: 8px;
  display: flex;
  border-radius: 3px;
  max-width: 300px;
  width: fit-content;
  gap: 12px;
  @include themify($themes) {
    border: 1px solid themed('descrBorder');
  }

  &__icon {
    padding: 8px;
    border-radius: 3px;
    @include themify($themes) {
      border: 1px solid themed('descrBorder');
    }
  }

  &__title {
    box-sizing: border-box;
    flex-grow: 1;

    &__toggle {
      display: flex;
      align-items: center;
      gap: 8px;
      margin-top: 4px;
    }
  }

  &__close {
    cursor: pointer;
  }
}
