
        @import "@control-front-end/common/styles/variables";
        @import "@control-front-end/common/styles/mixins";
        
.source {
  box-sizing: border-box;

  &__type {
    width: 300px;
  }

  &__edit {
    padding-top: 0 !important;
  }

  &__select {
    margin-right: 16px;
  }

  &__select > div:first-child > div {
    display: inline-block;
    width: 300px;
    padding-bottom: 12px !important;
  }

  &__select > div:first-child,
  &__select > div:nth-child(2) {
    width: fit-content;
    display: inline-flex;
  }
}
