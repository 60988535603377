
        @import "@control-front-end/common/styles/variables";
        @import "@control-front-end/common/styles/mixins";
        
.modal {
  height: 90% !important;
  max-height: 90% !important;
  position: relative;
  width: 800px !important;
  @include themify($themes) {
    background-color: themed('mainBg');
  }

  :global .idChip {
    margin: 0 8px !important;
    @include themify($themes) {
      background-color: themed('gray50');
    }
  }
}

.content {
  margin-bottom: 0 !important;
  padding: 0 !important;
  @include hide-scroll-bar();
}

.buttons {
  justify-content: flex-end !important;
  padding: 20px !important;
  @include themify($themes) {
    border-top: 1px solid themed('gray50');
  }

  & > div {
    flex-grow: initial !important;
  }
}

.label {
  font-size: 12px;
  @include themify($themes) {
    color: themed('gray2');
  }
}

.general {
  padding: 24px 20%;
  @include themify($themes) {
    background-color: themed('workSpaceBg');
  }
}

.filter {
  min-height: calc(100% - 196px);
  padding: 20px;

  &__form.disabled {
    .filter__section {
      cursor: default;
      opacity: 0.5;
      pointer-events: none;
    }
  }

  &__owner{
    width: 240px;
  }

  &__section {
    display: flex;
    flex-direction: column;
    padding: 20px 0 0 0;

    &.dynamic {
      padding: 0;
    }
  }

  &__col {
    display: flex;
    flex-direction: column;
  }

  &__row {
    display: flex;
    position: relative;
    gap: 20px;
  }

  &__item {
    display: flex;
    flex-direction: column;
    width: calc(50% - 8px);

    & > span {
      margin-bottom: 12px;
    }

    & :global .error {
      & :global .field {
        @include themify($themes) {
          border-color: themed('red1') !important;
        }
      }

      & :global .error {
        position: relative;
      }
    }

    &.action {
      flex-direction: row;
      align-items: center;

      & :global .field {
        width: 240px;
        box-sizing: border-box;
      }

      & > div:last-child {
        padding: 12px;

        i {
          cursor: pointer;
          @include btn-icon-hover();
        }
      }
    }
  }

  &__textfield {

    & :global .field {
      box-sizing: border-box;
    }
  }

  &__select {
    align-items: center;

    &.withCreateBtn {
      & input {
        padding-right: 24px !important;
      }
    }

    &__container {
      position: relative;
    }

    & input {
      padding-right: 20px;
    }

    & > div:nth-child(2) {
      margin-top: 0 !important;
    }

    &.openUp {
      & > div:nth-child(2) {
        bottom: 32px;
      }
    }

    &.isSingleSelect {
      & > div {
        padding: 0;
      }

      div :global .field {
        @include themify($themes) {
          background-color: themed('mainBg');
        }
      }
    }

    &__item {
      padding: 4px 16px;
      position: relative;

      &__form {
        display: flex;
        align-items: center;

        & > *:first-child {
          margin-right: 4px;
        }
      }

      &__divider {
        position: absolute;
        width: calc(100% - 32px);
        top: 0;
      }
    }
  }

  &__calendar {
    &.dateTo {
      left: -24px;
    }
  }

  &__btn {
    display: flex;
    width: fit-content;
    margin-bottom: 20px;
  }

  &__checkboxes {
    display: flex;
    gap: 32px;
    margin-top: 12px;
  }
}

.table {
  display: table;
  width: 100%;
  margin-bottom: 20px;

  &__row {
    display: table-row;
  }

  &__cell {
    display: table-cell;
    padding: 12px 0;
    @include themify($themes) {
      border-bottom: 1px solid themed('gray50') !important;
    }

    &.title {
      width: 440px;
      span {
        @include table_cell_overflow_ellipsis(400px);
      }
    }

    &__form {
      display: flex;
      align-items: center;
      gap: 8px;
      span {
        @include table_cell_overflow_ellipsis(250px);
      }
    }

    &__close {
      display: flex;
      justify-content: flex-end;
      padding: 4px;
      cursor: pointer;
    }
  }
}

.column {
  width: calc(50% - 8px);
}

.flexWrap {
  flex-wrap: wrap;
}
