
        @import "@control-front-end/common/styles/variables";
        @import "@control-front-end/common/styles/mixins";
        
.fe {
  max-width: 600px;
  min-width: 99%;
  height: 100%;

  &__ref {
    display: flex;
    align-items: center;
    position: relative;
    margin-top: 20px;
    padding: 0 16px;

    & > span {
      width: 120px;
      margin-right: 8px;
    }

    & > div {
      width: 100%;
      padding: 0 !important;

      & :global .field {
        @include themify($themes) {
         background-color: themed('mainBg') !important;
        }
      }
    }
  }

  &__parent {
    display: flex;
    align-items: center;
    position: relative;
    padding: 0 16px;

    & > span {
      width: 120px;
      margin-right: 8px;
    }

    &__clear {
      position: absolute;
      right: 24px;
      cursor: pointer;
      @include btn-icon-hover();
    }
  }

  &__sections {
    display: flex;
    height: calc(100% - 116px);
    width: 100%;
    overflow: hidden;
    @include themify($themes) {
      border-top: 1px solid themed('disabled');
    }

    &__navigation {
      display: flex;
      width: fit-content;
      height: 100%;
      flex-shrink: 0;
      overflow-x: hidden;
      overflow-y: auto;
      scrollbar-gutter: stable;
      position: relative;
    }

    &__list {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      height: 100%;
      overflow-x: hidden;
      overflow-y: auto;
      padding: 16px 16px 0 0;
      box-sizing: border-box;
      scrollbar-gutter: stable;
      position: relative;
    }
  }

  &__section {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 20px;

    &:last-child {
      margin-bottom: 0;
    }

    &__header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 8px;
      line-height: 24px;

      &__title {
        box-sizing: border-box;
        margin-right: 24px;
        padding: 0 !important;

        input {
          font-size: 18px;
          font-weight: 600 !important;
        }
      }

      &__btns {
        display: flex;
        align-items: center;
        box-sizing: border-box;
        gap: 16px;
        padding-right: 15px;

        & i {
          cursor: pointer;
          @include btn-icon-hover();

          :global .fill {
            transition: .3s;
          }
        }

        &__nav {
          display: flex;
          gap: 16px;

          & > i:first-child {
            transform: rotate(90deg);
          }

          & > i:last-child {
            transform: rotate(-90deg);
          }
        }
      }
    }

    &__content {
      display: flex;
      flex-direction: column;
      width: 100%;
      gap: 8px;
      @include themify($themes) {
        background-color: themed('workSpaceBg');
      }
    }
  }

  &__add {
    padding: 20px 0;

    & > div {
      box-sizing: border-box;
      cursor: pointer;
      border-radius: 8px;
      padding: 24px;
      text-align: center;
      @include themify($themes) {
        color: themed('primaryColor');
        background-color: themed('mainBg');
      }

      &:hover {
        @include themify($themes) {
          color: themed('primaryColorHover');
        }
      }
    }
  }

  &__row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 8px;
    width: 100%;

    &:last-child {
      margin-bottom: 32px;
    }
  }
}

@include respond-to(mobile) {
  .fe__sections__navigation {
    display: none;
  }

  .fe__row {
    gap: 4px;
  }

  .fe__section {
    margin-bottom: 16px;
  }

  .fe__section__header__title input {
    font-size: 14px !important;
  }

  .item__active__tabs__item {
    font-size: 14px;
    padding: 4px 12px;
  }
}
