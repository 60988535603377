
        @import "@control-front-end/common/styles/variables";
        @import "@control-front-end/common/styles/mixins";
        
.content {
  margin-bottom: 0 !important;
}

.row {
  &__field {
    padding-top: 12px !important;
    padding-bottom: 0 !important;
  }
  &__label {
    margin-bottom: 16px;
    position: relative;

    &__descr {
      @include themify($themes) {
        color: themed('gray1');
      }
    }
  }
}
