
        @import "@control-front-end/common/styles/variables";
        @import "@control-front-end/common/styles/mixins";
        
.modal {
  min-width: 960px;
  max-width: 1200px;
  flex-grow: 1;
  height: 90% !important;
  max-height: 90% !important;
}

.table {
  @include themify($themes) {
    background-color: themed('workSpaceBg') !important;
  }

  & :global .scTableRow {
    padding: 0 !important;
    @include themify($themes) {
      background-color: themed('workSpaceBg');
    }
  }

  & :global .scTableRowDivider {
    margin: 0 !important;
  }

  &__head {
    :global .scTableRow {
      @include themify($themes) {
        background-color: themed('workSpaceBg') !important;
      }
    }
  }

  &__row {
    cursor: pointer;
    padding: 0 !important;

    :global .scTableRowList {
      padding-left: 8px !important;
      justify-content: space-between;
    }
  }

  &__cell {
    margin-right: 8px;

    &.long {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 8px;

      & > span {
        @include text-overflow();
      }
    }

    &.medium {
      width: 196px !important;
      min-width: 196px !important;
    }

    &.compact {
      width: 92px !important;
      min-width: 92px !important;
    }
  }
}

.loader {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
}
