
        @import "@control-front-end/common/styles/variables";
        @import "@control-front-end/common/styles/mixins";
        
.param {
  display: flex;

  &__field {
    width: 45%;
    margin-right: 12px;

    div:not(:first-child) {
      background-color: #FFFFFF;
    }
  }

  &__delete {
    margin-top: 28px;

    &:hover {
      cursor: pointer;

      path {
        @include themify($themes) {
          fill: themed('primaryColor');
        }
      }
    }
  }
}
