
        @import "@control-front-end/common/styles/variables";
        @import "@control-front-end/common/styles/mixins";
        
.title {
  display: inline-block;

  &.singleLine {
    width: 100%;
    display: flex;
    align-items: center;
    min-width: 0;

    .title__preview {
      @include text-overflow;
    }

    .title__preview__content {
      max-width: 500px;
      height: 24px;
      @include text-overflow;
    }
  }

  &.edit {
    width: 100%;
  }

  &__preview {
    display: flex;
    position: relative;
    border-radius: 3px;
    border: 1px solid transparent;
    font-weight: 600;
    font-size: 18px;
    min-width: 0;
    @include word-break();
    line-height: 24px;
    padding: 4px 24px 4px 4px;

    &__html {
      @include text-overflow();
    }

    &.primary {
      box-sizing: border-box;
    }

    &.secondary {
      font-size: 14px;
      font-weight: normal;
      @include themify($themes) {
        color: themed('gray2');
      }

      &:hover {
        @include themify($themes) {
          border-color: themed('disabled');
          background-color: themed('primary50Color');
        }
      }
    }

    &.large {
      font-size: 24px;
      @include themify($themes) {
        color: themed('gray2');
      }

      .title__preview__content {
        height: 28px;
      }
    }

    &.multiLine {
      & :local .title__preview__html {
        overflow: initial;
        text-overflow: initial;
        white-space: initial;
      }
    }

    &.owner {
      cursor: pointer;

      &:hover {
        @include themify($themes) {
          border-color: themed('disabled');
          background-color: themed('primary50Color');
        }

        i {
          opacity: 1;
        }
      }
    }

    i {
      position: absolute;
      top: 10px;
      right: 8px;
      opacity: 0;
    }

    &__readOnly {
      margin-right: 16px !important;
      margin-bottom: 0 !important;
      @include themify($themes) {
        border-color: themed('cardHeader');
        background-color: themed('cardHeader');
        color: themed('mainBg');
      }
    }

    &__content {
      box-sizing: border-box;
      min-width: 0;

      &.isNew {
        font-weight: 400 !important;
        @include themify($themes) {
          color: themed('gray1') !important;
        }
      }
    }
  }

  &__editor {
    padding: 0 !important;

    & :global .field {
      transition: none;
    }
  }
}

@include respond-to(mobile) {
  .title {
    width: 100% !important;
  }

  .title__preview {
    max-width: calc(100vw - 32px);
    &__content {
      @include text-overflow();
    }
  }
}
