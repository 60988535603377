
        @import "@control-front-end/common/styles/variables";
        @import "@control-front-end/common/styles/mixins";
        
.button {
  box-sizing: border-box;
  width: 100%;
  margin-bottom: 20px;
  height: fit-content !important;
  padding: 10px 15px !important;

  & span {
    text-align: center;
  }
}
