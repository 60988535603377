
        @import "@control-front-end/common/styles/variables";
        @import "@control-front-end/common/styles/mixins";
        
.label{
  font-size: 14px;
  margin-bottom: 20px;

  &.left{
    text-align: left;
  }

  &.center{
    text-align: center;
  }

  &.right{
    text-align: right;
  }

  &.hidden{
    display: none;
  }
}
