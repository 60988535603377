
        @import "@control-front-end/common/styles/variables";
        @import "@control-front-end/common/styles/mixins";
        
.modal {
  overflow: visible !important;
  @include themify($themes) {
    color: themed('textColor') !important;
  }

  & > div:first-child {
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
  }

  &__content {

    & > div:first-child {
      @include hover-scroll-bar();
    }

    &.visible {
      overflow: visible !important;

      & > div:first-child {
        overflow: visible !important;
      }
    }

    :global .label {
      display: block;

      > * {
        display: block;
      }
    }

    &__row {
      margin-bottom: 20px;

      :global .searchList .label {
        margin-bottom: 0;
      }

      :global .modal__content__chip {
        @include themify($themes) {
          color: themed('docTitleColor');
        }
      }

      :global .error {
        margin-bottom: 0;

        :global .modal__content__chip {
          @include themify($themes) {
            background-color: themed('errorChipColor');
          }
        }

        svg {
          path.fill {
            @include themify($themes) {
              fill: themed('removeColor') !important;
            }
          }
        }
      }
    }
  }

  &__loader {
    position: absolute;
    bottom: 32px;
    left: calc(50% - 25px);
  }

  &__info {
    margin-bottom: 12px;
    display: inline-block;
  }

  &__form {
    & > div {
      margin-bottom: 8px;
    }
  }

  &__buttons {
    & :global span {
      font-weight: 400 !important;
    }

    &:empty {
      padding: 0 !important;
    }
  }

  &__btn {
    &:not(:last-child) {
      margin-right: 20px;
    }

    &.cancel {
      @include themify($themes) {
        color: themed('primaryColor');
      }
    }

    &.red {
      @include themify($themes) {
        border: 1px solid themed('red1');
        background-color: themed('red1');
        color: themed('mainBg');
      }

      &:hover {
        @include themify($themes) {
          border: 1px solid themed('removeColorHover');
          background-color: themed('removeColorHover');
        }
      }
    }

    span {
      text-align: center;
    }
  }
}

.modal__removeBtn .modal__btn,
.modal__btn.confirm {
  @include themify($themes) {
    background-color: themed('removeColor');
    border-color: themed('removeColor');

    &:hover,
    &:active,
    &:focus {
      background-color: themed('removeColorHover') !important;
      border-color: themed('removeColorHover') !important;
    }
  }
}

// TODO remove
.textfield {
  @include modal-textfield;
}

// TODO remove
.select {
  @include modal-select;
}

// TODO remove
.label {
  @include modal-label;
}


@include respond-to(mobile) {
  .modal {
    max-width: calc(100% - 16px);

    & > div > span {
      font-size: 14px;
    }

    & > div:first-child > div {
      left: 12px !important;
      top: 14px !important;
      @include no-touch-select();
    }
  }
  .modal__content {
    padding: 12px !important;
  }

  .modal__buttons {
    padding: 0 12px 12px !important;

    & > div {
      @include no-touch-select();
    }
  }
}
