
        @import "@control-front-end/common/styles/variables";
        @import "@control-front-end/common/styles/mixins";
        
.listWrap {
  max-height: 300px;
}

.preview {
  display: flex;
  margin-left: 10px;
}

.previewItem {
  flex: 1;
  position: relative;
  margin-left: -10px; /* Negative margin to create overlap */
}

.list {
  width: 264px;
}