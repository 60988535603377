
        @import "@control-front-end/common/styles/variables";
        @import "@control-front-end/common/styles/mixins";
        
.banner {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border: 1px solid;
  border-left-width: 4px;
  border-radius: 4px;
  @include themify($themes) {
    border-color: themed('gray50');
    border-left-color: themed('redBorder');
  }
  padding: 18px;

  &__icon {
    @include themify($themes) {
      background-color: themed('redBg');
    }
    border-radius: 8px;

    :global .fill {
      @include themify($themes) {
        fill: themed('redBorder');
      }
    }
  }
}
