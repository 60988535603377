
        @import "@control-front-end/common/styles/variables";
        @import "@control-front-end/common/styles/mixins";
        
.modal {
  width: 1000px !important;
  height: 100% !important;
  max-height: 90% !important;
  padding: 0 !important;
}
