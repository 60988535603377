
        @import "@control-front-end/common/styles/variables";
        @import "@control-front-end/common/styles/mixins";
        
.modal {
  max-height: 90% !important;
}

.transaction {
  & > div {
    overflow-x: hidden;
  }

  :global .label {
    font-weight: 600;
    @include themify($themes) {
      color: themed('textColor') !important;
    }
  }

  :global .toggle {
    & > div:first-child {
      border-radius: 4px;
    }
  }

  &__actor {
    width: 60% !important;
  }

  &__type {
    display: flex;
    gap: 20px;
    margin: 12px 0;
  }

  &__comment {
    flex-grow: 10;
  }

  &__amount {
    display: flex;
    align-items: flex-start;

    &__field {
      width: 210px;
      min-width: 50%;
    }

    &__currency {
      margin-top: 32px;
      margin-left: 8px;
      max-width: 50%;
      @include text-overflow();
      @include themify($themes) {
        color: themed('gray2');
      }
    }
  }

  &__date {
    display: flex;
    align-items: center;
  }

  &__noAccess {
    margin-bottom: 16px;
  }
}
