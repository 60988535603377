
        @import "@control-front-end/common/styles/variables";
        @import "@control-front-end/common/styles/mixins";
        
.scriptCard {
  overflow: hidden !important;
}

.header {
  @include themify($themes) {
    background-color: themed('primaryColor');
  }
  cursor: move;
}

.close {
  cursor: pointer;
}

.widgetContent {
  overflow-y: auto;
  height: calc(100% - 56px);
  &::-webkit-scrollbar {
    width: var(--scrollbar-width) !important;
    height: var(--scrollbar-height) !important;
  }

  &::-webkit-scrollbar-thumb {
    background: var(--scrollbar-thumb-bg) !important;
    border-radius: var(--scrollbar-thumb-border-radius) !important;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: var(--scrollbar-thumb-bg) !important;
  }

  &::-webkit-scrollbar-track {
    background: var(--scrollbar-track-bg) !important;
    border-radius: var(--scrollbar-thumb-border-radius) !important;
    box-shadow: var(--scrollbar-track-box-shadow) !important;
  }
}
