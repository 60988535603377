
        @import "@control-front-end/common/styles/variables";
        @import "@control-front-end/common/styles/mixins";
        
.profile {
  display: flex;
  position: relative;

  &__avatar {
    cursor: pointer;
    box-sizing: border-box;
    height: 32px !important;
    width: 32px !important;

    &__box {
      @include themify($themes) {
        border: 1px solid themed('descrBorder');
      }
      border-radius: 50%;
      padding: 1px;
    }
  }

  &__menu {
    position: fixed;
    bottom: 64px;
    left: 220px;
    z-index: 9999;

    &.collapsed {
      left: 44px;
    }
  }
}
