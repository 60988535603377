
        @import "@control-front-end/common/styles/variables";
        @import "@control-front-end/common/styles/mixins";
        
.wrap {
  display: flex;
  overflow: auto;
  border-radius: 4px;
  width: 100%;
  height: 100%;
  scrollbar-width: none;
  position: relative;
  pointer-events: all;
}
