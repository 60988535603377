
        @import "@control-front-end/common/styles/variables";
        @import "@control-front-end/common/styles/mixins";
        
.block {
  display: flex;
  flex-direction: column;

  &__label {
    @include themify($themes) {
      color: themed('gray2') !important;
    }
  }
}
