
        @import "@control-front-end/common/styles/variables";
        @import "@control-front-end/common/styles/mixins";
        
.workspace {
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  &__label {
    width: 100%;
    line-height: 17px;
    cursor: initial;
    @include themify($themes) {
      color: themed('mainMenuColor');
    }
  }

  &__select {
    display: flex;
    width: 100%;
    position: relative;
    @include text-overflow();

    &__active {
      width: 100%;
      @include text-overflow();
    }

    &:hover {
      .workspace__select__icon :global .fill {
        @include themify($themes) {
          fill: themed('primaryColor') !important;
        }
      }
    }

    &__icon {
      margin-top: 4px;

      & > svg {
        transition: transform 0.3s;
      }

      &.active {
        :global .fill {
          @include themify($themes) {
            fill: themed('primaryColor') !important;
          }
        }
      }

      &.active > svg {
        transform: rotate(180deg);
      }
    }
  }

  &__list {
    flex-grow: 1;
    overflow: auto;
  }
}

.loader {
  height: 100%;
  align-content: center;
}

.collapsed {
  bottom: 32px;
}

.total {
  background-color: #f9fafb;
}

.relativeToButton {
  position: fixed;
  bottom: 64px;
  left: 12px;
  z-index: 1;
}
