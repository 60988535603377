
        @import "@control-front-end/common/styles/variables";
        @import "@control-front-end/common/styles/mixins";
        
.scriptSelect {
  width: 320px;
}

.timeoutField {
  width: 250px;
}

.exparationSettings {
  display: flex;
  justify-content: space-between;
}