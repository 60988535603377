
        @import "@control-front-end/common/styles/variables";
        @import "@control-front-end/common/styles/mixins";
        
.modal {
  height: 80% !important;
  max-height: 80% !important;
  position: relative;
  border: none !important;
}

.content {
  margin-bottom: 0 !important;
  box-sizing: border-box;
  height: 100%;
  width: 100%;
  overflow: hidden;

  & > div {
    display: flex;
    height: 100%;
    overflow: hidden;
  }

  &__wrap {
    display: grid;
    grid-template-rows: 62px 120px 32px 1fr;
    grid-template-columns: 1fr;
    min-height: 1px;
    max-height: 100%;
    width: 100%;
  }

  &__checkbox label {
    @include themify($themes) {
      color: themed('textColor');
    }
  }

  &__tabs {
    :global .tabItem {
      padding-bottom: 8px !important;
    }
  }
}
