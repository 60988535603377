
        @import "@control-front-end/common/styles/variables";
        @import "@control-front-end/common/styles/mixins";
        
.content {
  margin-bottom: 0 !important;
  padding: 0 !important;

  &__iframe {
    border: 0;
    width: 100%;
    height: 100%;

    &__loader {
      position: fixed;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      width: 100%;
    }
  }
}

.titleHead {
  height: 100% !important;
  max-height: 90% !important;
  padding: 0 !important;
}
