
        @import "@control-front-end/common/styles/variables";
        @import "@control-front-end/common/styles/mixins";
        
.modal {
  overflow: visible !important;
  width: 1024px !important;

  & > div:first-child {
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
  }

  &__content {
    overflow: hidden !important;
    max-height: 600px;
    & > div {
      overflow: hidden !important;
      height: 60vh;
    }
  }
}

@include respond-to(tablet) {
  .modal {
    max-width: calc(100vw - 16px) !important;
  }
}
