
        @import "@control-front-end/common/styles/variables";
        @import "@control-front-end/common/styles/mixins";
        
.removeBtn:hover:not(.disabled) {
  cursor: pointer;
  @include btn-icon-hover();
}

.removeBtn.disabled {
  opacity: 0.5;
}