
        @import "@control-front-end/common/styles/variables";
        @import "@control-front-end/common/styles/mixins";
        
.eventModal {
  overflow: visible !important;
  max-height: 90% !important;
  height: calc(100% - 96px) !important;
  width: 810px !important;
  background: none !important;
  border: none !important;
  box-shadow: none !important;
  padding: 0 !important;
  position: relative;
  & > div > div {
    overflow: visible !important;
  }

  &__content {
    margin-bottom: 0 !important;
    padding: 0 !important;
    overflow-y: visible !important;
  }

  &__btn {
    margin-right: 16px !important;
  }
}

@include respond-to(tablet) {
  .eventModal {
    max-width: 640px !important;
    @include no-touch-select();
  }
}

@include respond-to(mobile) {
  .eventModal {
    max-width: 100vw;
    @include no-touch-select();

    &__content {
      border-radius: 10px 10px 0 0 !important;
    }

    &__bottom {
      bottom: 12px;
    }
  }
}
