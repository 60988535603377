
        @import "@control-front-end/common/styles/variables";
        @import "@control-front-end/common/styles/mixins";
        
.content {
  & > div {
    height: 386px;
    overflow: hidden;

    & > div:nth-child(2) {
      box-sizing: border-box;
      height: calc(100% - 64px);
      overflow: auto;
    }

    div[class*='-label-'] {
      font-size: 14px !important;
      font-weight: 600 !important;
    }

    :global .label {
      font-size: 14px !important;
      font-weight: 600 !important;
    }
  }

  &__wrap {
    margin-top: 20px;
  }

  &__row {
    position: relative;
    & > div {
      width: 50%;
    }
  }

  &__access {
    height: 100%;

    & > div {
      padding: 0 !important;
    }
  }

  &__noAccess {
    margin-bottom: 12px;
  }

  &__actor {
    width: calc(50% - 12px);
  }

  &__options {
    margin-top: 20px;
  }

  &__multivalue {
    cursor: pointer;
    margin-left: 4px;
    position: absolute;
    top: 2px;
    left: calc(50% + 108px);
  }
}

.flexItem {
  flex-grow: 1;
}
