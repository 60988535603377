
        @import "@control-front-end/common/styles/variables";
        @import "@control-front-end/common/styles/mixins";
        
.table {
  display: grid;
  grid-template-rows: 64px 1fr;
  width: 100%;
  height: 100%;
  min-height: 200px;
  background-color: inherit !important;
  overflow: auto;

  & :global .scTableRowList {
    justify-content: space-between;
  }

  & :global .scTableRow {
    @include themify($themes) {
      background-color: themed('workSpaceBg') !important;
    }
  }

  & :global .scTableRowDivider {
    margin: 0 !important;
  }

  &__empty {
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    & > i, svg {
      width: 48px !important;
      height: 48px !important;
    }

    & > span {
      margin-top: 16px;
      @include themify($themes) {
        color: themed('gray35');
      }
    }
  }

  &__row {
    padding: 0 !important;
  }

  &__cell {
    display: flex;
    align-items: center;
    margin-right: 8px;
    min-height: 56px;

    &.title {
      span {
        @include text-overflow();
        margin-left: 12px;
      }
    }

    &.tpl,
    &.date {
      .chip {
        margin: 0 !important;
        border: none !important;
        @include themify($themes) {
          background-color: themed('gray50') !important;
        }
      }
    }

    &.close {
      i {
        padding: 4px;
        cursor: pointer;
        @include btn-icon-hover();
      }
    }
  }
}

@include respond-to(mobile) {
  .table {
    margin: 0;
    padding-bottom: 24px;

    &__row {
      padding: 0 16px;
      @include no-touch-select();
    }

    & :global hr {
      margin: 0 16px !important;
    }
  }
}
