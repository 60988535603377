
        @import "@control-front-end/common/styles/variables";
        @import "@control-front-end/common/styles/mixins";
        
.idChip {
  cursor: pointer;
  flex-direction: row-reverse;
  margin: 4px 12px 4px 0 !important;
  padding: 0 8px !important;
  width: fit-content;

  &.unspaced {
    margin: 0 !important;
  }

  @include themify($themes) {
    background-color: themed('gray20');
    border-color: themed('gray20');
  }

  i {
    margin-left: 8px;
    margin-right: 0 !important;
  }

  span {
    max-width: 100px !important;
  }

  &:hover {
    @include themify($themes) {
      background-color: themed('blueBg');
      border-color: themed('blueBg');
    }

    :global .fill {
      @include themify($themes) {
        fill: themed('primaryColor');
      }
    }
  }
}


