
        @import "@control-front-end/common/styles/variables";
        @import "@control-front-end/common/styles/mixins";
        
.le {
  margin-bottom: 1px;

  &.hidden {
    display: none;
  }

  &.grey {
    .le__title {
      @include themify($themes) {
        background-color: themed('workSpaceBg');
      }
    }

    .le__wrap {
      @include themify($themes) {
        background-color: themed('mainBg');
      }
    }
  }

  &.white {
    .le__title {
      border-radius: 4px;
      @include themify($themes) {
        background-color: themed('mainBg');
      }
    }

    .le__wrap {
      background-color: transparent;
    }
  }

  &.dark {
    .le__title {
      cursor: pointer;
      @include themify($themes) {
        background-color: themed('gray50');
      }

      &:hover {
        @include themify($themes) {
          background-color: themed('workSpaceBorder');
        }
      }
    }

    .le__wrap {
      background-color: transparent;
    }
  }

  &.active {
    .le__title {
      @include themify($themes) {
        color: themed('primaryColor') !important;
        background-color: themed('blueBg');
      }
    }
  }

  &__title {
    display: inline-flex;
    align-items: center;
    width: 100%;
    user-select: none;
    justify-content: space-between;
    cursor: pointer;

    &.hidden {
      display: none;
    }

    &__label {
      display: inline-flex;
      align-items: center;
      flex-shrink: 1;
      @include text-overflow();
    }

    &__rc {
      display: flex;
      margin-right: 16px;
    }

    &__icon {
      margin-left: 16px;
      transition: transform 0.3s;

      &.close {
        transform: rotate(-90deg);
      }
    }

    &__value {
      cursor: pointer;
      margin-top: 6px;
      margin-bottom: 7px;
      margin-left: 12px;
      line-height: 19px;
      @include text-overflow();
    }
  }

  &__wrap {
    box-sizing: border-box;

    &.hidden {
      display: none;
    }
  }
}

@include respond-to(mobile) {
  .le__title {
    @include no-touch-select();
  }
}

.leSimulator {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;

  &.hidden {
    display: none;
  }

  &.compact {
    .leSimulator__header {
      padding: 0 12px 0 0;
    }

    .leSimulator__header__title {
      & > span {
        width: 100% !important;
      }

      & > div {
        &.active,
        &:hover {
          background-color: transparent !important;
        }
      }

      @include themify($themes) {
        color: themed('textColor');
      }
    }

    .leSimulator__content > div {
      padding-left: 48px;
    }
  }

  &.secondary {
    .leSimulator__header {
      flex-direction: row-reverse;
    }
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    padding: 8px 12px;
    box-sizing: border-box;
    width: 100%;

    &.grey {
      @include themify($themes) {
        background-color: themed('workSpaceBg');
      }
    }

    &.white {
      @include themify($themes) {
        background-color: themed('mainBg');
      }
    }

    &__title {
      display: flex;
      align-items: center;
      width: calc(100% - 16px);

      span {
        width: calc(100% - 24px);
        @include text-overflow;
      }

      :global i,
      :global .badge {
        margin-right: 8px;
      }
    }

    &__icon {
      transition: transform 0.3s;

      &.close {
        transform: rotate(-90deg);
      }
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
  }
}
