
        @import "@control-front-end/common/styles/variables";
        @import "@control-front-end/common/styles/mixins";
        
.li {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: auto;
  z-index: 1000;
  background-color: rgba(57, 63, 70, 0.8);

  &Upload {
    position: relative;
    margin: auto;
    height: 320px;
    width: 480px;
    z-index: 101;

    @include themify($themes) {
      background-color: themed('mainBg');
    }

    &Preview {
      position: absolute;
      top: 20px;
      left: 20px;
      height: calc(100% - 40px);
      width: calc(100% - 40px);
      z-index: 103;

      &Item {
        height: 100%;
        margin: 0 !important;
        padding: 0 !important;
        align-items: start !important;
        gap: 8px;
        overflow: hidden;
        border-bottom: none !important;

        > * {
          &:first-child {
            height: 100%;
            flex: 0 1 100%;

            img {
              width: 100%;
              max-height: 100%;
              opacity: 0.3;
            }
          }

          &:last-child {
            flex: 0 1 20px;
            margin: 0;
          }
        }
      }
    }

    &Icon {
      padding: 10px;
      border-radius: 50%;

      @include themify($themes) {
        background-color: themed('gray50');
      }
    }

    &Loader {
      position: absolute;
      top: 25%;
      left: calc(50% - 33px);
    }

    &Btn {
      position: absolute !important;
      bottom: 25%;
      left: calc(50% - 48px);
      width: 96px;
    }

    &Box {
      & > div {
        align-items: flex-end;
        text-align: center;
        border: none;
        background: none;
        font-size: 0;

        i {
          display: none;
        }

        & > span {
          display: none;
        }

        & > div {
          display: flex;
          padding: 11px;
          border-radius: 4px;

          @include themify($themes) {
            background-color: themed('primaryColor');
            color: themed('mainBg');
          }

          span {
            text-decoration: none !important;

            @include themify($themes) {
              color: themed('mainBg');
            }
          }
        }
      }
    }

    &Browse {
      border-style: dashed !important;

      @include themify($themes) {
        border-color: themed('workSpaceBorder');
      }
    }
  }
}
