
        @import "@control-front-end/common/styles/variables";
        @import "@control-front-end/common/styles/mixins";
        
.button {
  display: flex;
  box-sizing: border-box;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 80px;
  padding: 0 16px;
  border-radius: 4px;

  @include themify($themes) {
    background-color: themed('workSpaceBg');
  }

  & > div:first-child {
    width: 90%;
  }
}
