
        @import "@control-front-end/common/styles/variables";
        @import "@control-front-end/common/styles/mixins";
        
.sections__panel {
  display: flex;
  flex-direction: column;
  width: 268px;
  flex-shrink: 0;
  transition: width 400ms cubic-bezier(.23, 1, .32, 1) 0ms;
  overflow: auto;
  box-sizing: border-box;
  position: relative;
  @include themify($themes) {
    background-color: themed('mainBg');
    border-right: 1px solid themed('gray50');
  }

  &__header {
    display: flex;
    align-items: center;
    position: relative;
    padding: 30px 30px 12px;

    &__select > div > div {
      padding-top: 0 !important;
      margin: 0 20px;
    }

    &__add {
      margin-left: 12px;
      cursor: pointer;
      @include btn-icon-hover();
    }

    &__collapse {
      position: absolute;
      top: 24px;
      right: 30px;
      cursor: pointer;
      transition: all 0.1s;
      transform: rotate(0deg);
      width: 24px;
      height: 32px;
      display: flex;
      align-items: center;
      @include btn-icon-hover();
    }
  }

  &.collapsed {
    width: 0;
    padding: 0;
    overflow: visible;

    .sections__panel__header {
      padding: 20px 0 0 8px;
    }

    .sections__panel__header__collapse {
      transform: rotate(180deg);
      position: relative;
      top: 8px;
      left: -4px;
      width: 24px;
      height: 32px;
      display: flex;
      align-items: center;
      border: 1px solid transparent;
      border-radius: 4px 0 0 4px;
      padding: 0 6px 0 6px;
      @include themify($themes) {
        background-color: themed('mainBg');
        border-color: themed('gray50');
      }
    }
  }

  &.open {
    width: 240px;
    flex-shrink: 0;
    overflow: auto;

    .sections__panel__header__collapse {
      transform: rotate(0deg);
    }
  }

  &.hidden {
    display: none;
  }

  & > span {
    padding: 8px;
  }

  &__list {
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow: auto;
    margin-left: 28px;
  }

  &__menu {
    position: absolute;
    right: 16px;
    top: 0;
    z-index: 10;

    &__wrap {
      position: relative;
      display: flex;
      align-items: center;

      & > i {
        cursor: pointer;
        transition: 0.3s;
        opacity: 0;
        @include btn-icon-hover();
      }
    }
  }

  &__accessDenied {
    display: flex;
    flex-direction: column;
    cursor: default;
    width: 100%;
    padding: 4px 20px 4px 56px;
    box-sizing: border-box;
    overflow: visible !important;
    @include text-overflow();
    position: relative;

    & > span:first-child {
      @include themify($themes) {
        color: themed('gray2');
      }
    }

    & > span:nth-child(2) {
      @include themify($themes) {
        color: themed('gray35');
      }
    }

    .sections__panel__menu__wrap {
      position: absolute;
      right: 20px;
      top: calc(50% - 8px);
    }

    &:hover {
      i {
        opacity: 1;
      }
    }
  }

  &__item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    flex-shrink: 0;
    padding: 8px;
    box-sizing: border-box;
    border-radius: 4px;
    cursor: pointer;
    @include text-overflow();
    @include themify($themes) {
      color: themed('gray1');
    }

    span {
      @include text-overflow();
    }

    &:hover:not(.empty),
    &.active {
      @include themify($themes) {
        color: themed('primaryColor') !important;
      }
    }

    &.empty {
      cursor: default;
    }

    &__error {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      flex-shrink: 0;
      @include themify($themes) {
        color: themed('red1');
      }

      &.total {
        display: flex;
        justify-content: center;
        width: 100%;
        cursor: pointer;
      }

      span {
        margin-right: 4px;
      }

      :global .fill {
        @include themify($themes) {
          fill: themed('red1');
        }
      }
    }

    &__empty {
      padding: 4px 8px;
      cursor: default;
      @include themify($themes) {
        color: themed('gray35');
      }
    }
  }

  &__delList {
    display: flex;
    flex-direction: column;
     & > span:first-child {
       margin-bottom: 12px;
     }

    &__item {
      display: flex;
      align-items: center;

      & > span {
        margin-left: 8px;
      }
    }
  }

  :global .toggle {
    margin: 0 20px;

    & > div:first-child:hover {
      @include themify($themes) {
        background-color: themed('blueBg');
        color: themed('primaryColor') !important;
      }

      i {
        opacity: 1;
      }
    }
  }

  &.noForms {
    &::-webkit-scrollbar-track{
      background: none !important;
      box-shadow: none !important;
    }

    &::-webkit-scrollbar-thumb{
      background: none !important;
    }

    :global .searchList {
      overflow-x: hidden;
    }
  }
}

@include respond-to(mobile) {
  .sections__panel {
    display: none;
  }
}
