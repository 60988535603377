
        @import "@control-front-end/common/styles/variables";
        @import "@control-front-end/common/styles/mixins";
        
.filterSelect {
  width: 34%;
}

.filterSelect :global #actorFilter {
  padding-top: 23px;
}

.filterSelect :global .label {
  font-weight: 600;
  font-size: 14px;
  transform: translate(0px, -22px);
}

.buttonTop {
  width: 60px;
}

.buttonCustom {
  width: 160px;
}

.button {
  @include themify($themes) {
    border-color: themed('primaryColor');
  }
}

.topInput {
  & :global .field {
    padding-top: 5px !important;
    padding-bottom: 6px !important;
  }
}
