
        @import "@control-front-end/common/styles/variables";
        @import "@control-front-end/common/styles/mixins";
        
.diff {
  box-sizing: border-box;
  padding: 12px 16px;
  border-radius: 8px;
  word-break: break-word;
  @include themify($themes) {
    background-color: themed('mainBg');
    border: 1px solid themed('gray50');
  }

  &__item {
    display: flex;

    :global .badge {
      border-width: 1px;
      @include themify($themes) {
        outline: 1px solid themed('gray35') !important;
      }
    }

    &__img > img {
      max-width: 100%;
      max-height: 64px;
      border-radius: 4px;
    }

    &__content {
      display: flex;
      flex-grow: 1;
      flex-basis: 50%;
      max-width: 50%;
      flex-wrap: wrap;
    }
  }

  .removed {
    text-decoration: line-through;

    mark[data-chip],
    a[data-chip] {
      text-decoration: none;
      @include themify($themes) {
        border-color: themed('red1') !important;
      }
    }
  }

  .added {
    mark[data-chip],
    a[data-chip] {
      @include themify($themes) {
        border-color: themed('success') !important;
      }
    }
  }
}
