
        @import "@control-front-end/common/styles/variables";
        @import "@control-front-end/common/styles/mixins";
        
.customBar {
  flex-wrap: wrap;
  overflow-y: hidden;
  height: 32px;
}

.scriptButton {
  width: 40px;
  background-color: transparent;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 4px;
  cursor: pointer;
}

.scriptsListWrapper {
  width: 167px;
  min-height: 100px;
}

.scriptsList {
  max-height: 184px;
  min-height: 50px;
  overflow-y: auto;
}

.scriptItem {
  cursor: pointer;
}
.scriptItem:hover {
  @include themify($themes) {
    background-color: themed('workSpaceBg');
  }
}
