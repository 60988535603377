
        @import "@control-front-end/common/styles/variables";
        @import "@control-front-end/common/styles/mixins";
        
.workspaceItem {
  cursor: pointer;
  position: relative;

  &:hover,
  &.active {
    @include themify($themes) {
      background-color: themed('primaryBgHover');
    }
  }

  .avatar {
    flex-shrink: 0;
  }

  &__label {
    padding: 4px 12px 4px 0;
    span {
      height: fit-content;
    }
    & > div {
      flex-wrap: wrap;
    }
  }

  &__check {
    position: absolute;
    right: 4px;
    :global .stroke {
      @include themify($themes) {
        stroke: themed('primaryColor') !important;
      }
    }
  }
}
