
        @import "@control-front-end/common/styles/variables";
        @import "@control-front-end/common/styles/mixins";
        
.connector {
  &__type {
    margin-bottom: 8px;
  }

  &__comment {
    flex-grow: 10;
    margin-bottom: 20px;
  }

  &__empty {
    cursor: default !important;
    &:hover {
      background-color: transparent;
    }
  }
}