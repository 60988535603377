
        @import "@control-front-end/common/styles/variables";
        @import "@control-front-end/common/styles/mixins";
        
.people {
  &__placeholder {
    @include themify($themes) {
      color: themed('gray2');
    }
  }

  &__avatar {
    margin-right: 12px;

    :global .fill {
      @include themify($themes) {
        fill: themed('primaryColor') !important;
      }
    }

    &.invite {
      @include themify($themes) {
        background-color: themed('primary30Color') !important;
      }
    }
  }

  &__icon {
    margin-right: 8px;
  }

  &__count {
    @include themify($themes) {
      color: themed('primaryColor');
    }
  }
}
