
        @import "@control-front-end/common/styles/variables";
        @import "@control-front-end/common/styles/mixins";
        
.slideout {
  &__bg {
    position: fixed;
    bottom: 0;
    left: 0;

    &.open {
      top: 0;
      right: 0;
      background-color: rgba(0, 2, 6, 0.3);
      z-index: 999;
    }
  }

  &__menu {
    position: absolute;
    left: -240px;
    top: 0;
    bottom: 44px;
    transform: translate3d(0px, 0px, 0px);
    transition: transform .3s;
    width: 240px;
    opacity: 1;
    z-index: 1000;
    @include themify($themes) {
      background-color: themed('workSpaceBg');
    }

    &.open {
      opacity: 1;
      transform: translate3d(240px, 0px, 0px);
    }
  }

  &__bottom {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 44px;
    cursor: pointer;
    box-shadow: -4px 2px 8px 0 rgba(0, 0, 0, 0.14);
    @include no-touch-select();
    @include themify($themes) {
      background-color: themed('mainBg');
    }
  }

  &__btn {
    position: relative;
    margin-right: 12px;

    & :global .fill {
      @include themify($themes) {
        fill: themed('textColor') !important;
      }
    }

    &__badge {
      position: absolute;
      top: -8px;
      right: -8px;
      z-index: 10002;
      border-width: 2px !important;
    }
  }
}

@include respond-to(desktop) {
  .slideout__bg,
  .slideout__bottom {
    display: none;
  }
}

@include respond-to(tablet) {
  .slideout__bottom {
    height: 48px !important;
  }

  .slideout__menu {
    height: calc(100% - 48px) !important;
  }
}

.hidden {
  display: none;
}

.onTop {
  z-index: 999;
}