
        @import "@control-front-end/common/styles/variables";
        @import "@control-front-end/common/styles/mixins";
        
.table {
  display: grid;
  grid-template-rows: 56px 1fr;
  height: 100%;
  width: 100%;
  overflow: auto;

  :global .scTableRowList {
    padding: 0 !important;
  }

  & :global .scTableRowDivider {
    margin: 0 !important;
  }

  &__head {
    :global .scTableRow {
      padding: 0 !important;
      @include themify($themes) {
        background-color: themed('workSpaceBg');
      }
    }

    .table__cell.value > span {
      white-space: pre-wrap !important;
      text-align: end;
    }
  }

  &__row {
    padding: 0 !important;
    @include themify($themes) {
      background-color: themed('mainBg');
    }
  }

  &__cell {
    display: flex;
    padding: 0 8px !important;

    &.percentage {
      min-width: 72px !important;
    }

    &.value {
      justify-content: flex-end;
    }
  }
}
