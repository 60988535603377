
        @import "@control-front-end/common/styles/variables";
        @import "@control-front-end/common/styles/mixins";
        
.options {
  display: flex;
  flex-direction: column;

  &__label {
    width: 100%;
  }

  &__limits > div {
    width: 180px;
  }

  &__field {
    @include modal-textfield();
  }

  &__switch {
    align-items: center;
  }

  &__divider {
    margin: 8px 0 !important;
  }
}
