
        @import "@control-front-end/common/styles/variables";
        @import "@control-front-end/common/styles/mixins";
        

.wrap {
  box-sizing: border-box;
  overflow: hidden;
  height: 100%;
}

.panel {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding-left: 12px;
  box-sizing: border-box;
  z-index: 10;
  position: relative;
  @include themify($themes) {
    background-color: themed('mainBg');
  }

  &.resizing {
    cursor: ew-resize;
    user-select: none;
  }

  &__resizer {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 10px;
    z-index: 2;
    cursor: ew-resize;
    display: flex;
    align-items: center;
    justify-content: center;
    @include themify($themes) {
      background-color: themed('mainBg');
      border-right: 1px solid themed('gray50');
      border-left: 1px solid themed('gray50');
    }
  }

  &__content {
    overflow-x: hidden;
    overflow-y: auto;
    height: 100%;
    @include hide-scroll-bar();
  }
}

@include respond-to(mobile) {
  .panel {
    padding-left: 0;
  }

  .panel__resizer {
    display: none;
  }
}

@include respond-to(tablet) {
  .wrap {
    width: 100vw !important;
  }

  .panel__resizer {
    display: none;
  }
}
