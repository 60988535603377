
        @import "@control-front-end/common/styles/variables";
        @import "@control-front-end/common/styles/mixins";
        
.transactions {
  overflow: hidden;
}

.search {
  display: flex;
  width: 480px;
  @include modal-textfield;

  :global .field {
    width: 100%;
  }
}

.content {
  overflow: auto;
  @include themify($themes) {
    background-color: themed('mainBg');
  }
}
