
        @import "@control-front-end/common/styles/variables";
        @import "@control-front-end/common/styles/mixins";
        
.actorTab__menu {
  position: absolute;
  top: 0;
  right: 0;

  &__wrap {
    position: relative;
  }

  &__icon {
    cursor: pointer;

    & > i {
      padding: 4px;
      transition: 0.3s;
      opacity: 0;
      @include btn-icon-hover();
    }

    &.active > i {
      opacity: 1;
    }
  }
}

@include respond-to(mobile) {
  .actorTab__menu__icon > i {
    opacity: 1 !important;
  }
}
