
        @import "@control-front-end/common/styles/variables";
        @import "@control-front-end/common/styles/mixins";
        
.collapsibleList {
  max-height: 200px;
  overflow: auto;
  padding-bottom: 2px;
  width: 100%;
  word-break: break-all;
}
