
        @import "@control-front-end/common/styles/variables";
        @import "@control-front-end/common/styles/mixins";
        
.tabsPopup {
  width: 300px;
  padding: 12px;

  &__icon {
    transform: rotate(-90deg);
    transition: .3s;
    cursor: pointer;
    @include btn-icon-hover();
  }

  &__list {
    box-shadow: none !important;
    padding: 0 !important;

    :global .stroke {
      @include themify($themes) {
        stroke: themed('primaryColor');
      }
    }
  }
}
