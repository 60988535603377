
        @import "@control-front-end/common/styles/variables";
        @import "@control-front-end/common/styles/mixins";
        
.fl {
  box-sizing: border-box;
  display: flex;
  width: 100%;
  height: 100%;
  overflow: hidden;

  &__title {
    display: flex;
    margin: 24px 0 16px;

    &:first-child {
      margin-top: 0;
    }

    & > span:first-child {
      display: block;
      @include text-overflow();
    }

    & > span:nth-child(2) {
      margin-left: 4px;
      @include themify($themes) {
        color: themed('gray2');
      }
    }
  }

  &__container {
    flex-direction: column;
    flex-grow: 1;
    height: 100%;
    overflow: auto;
    scrollbar-gutter: stable;

    &__tabs {
      padding: 24px 24px 0;
      :global .tabItem {
        padding-bottom: 8px !important;
      }
    }

    &__content {
      height: calc(100% - 200px);
      padding: 24px;
      box-sizing: border-box;
      position: relative;

      &__forms {
        display: flex;
        flex-direction: column;

        &.hidden {
          display: none;
        }
      }

      &__map {
        height: 100%;
        border: 3px solid #EAECEF;
        overflow: hidden;

        & > div {
          height: 100%;
        }
      }
    }
  }

  &__openall {
    padding: 0 8px;
    cursor: pointer;
    transition: transform 0.3s;
    transform: rotate(180deg);
    @include btn-icon-hover();

    &.open {
      transform: rotate(270deg);
    }
  }
}
