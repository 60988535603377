
        @import "@control-front-end/common/styles/variables";
        @import "@control-front-end/common/styles/mixins";
        
.cf {
  display: grid;
  grid-template-rows: 64px 52px 1fr;
  height: 100%;

  &.readOnly {
    grid-template-rows: 64px 1fr !important;
  }

  &__wrap {
    @include open-sans;
    height: 100%;
    @include themify($themes) {
      color: themed('textColor');
    }
  }

  // HEADER

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 14px 20px;
    position: relative;
    min-width: 0;
    gap: 24px;
    @include themify($themes) {
      background-color: themed('mainBg');
      border-bottom: 1px solid themed('disabled');
    }

    &__btn:hover {
      border-color: transparent !important;
      background-color: transparent !important;
    }

    &__title {
      display: flex;
      box-sizing: border-box;
      flex-grow: 1;
      justify-content: center;
      max-width: 80%;
      gap: 8px;
      @include text-overflow;
    }

    &__actions {
      display: flex;

      & > div {
        margin-left: 16px;
      }

      &__chip {
        cursor: pointer;
        flex-direction: row-reverse;
        height: 36px !important;
        margin-right: 0 !important;
        margin-bottom: 0 !important;
        @include themify($themes) {
          background-color: themed('gray20');
          border-color: themed('gray20');
        }

        &:hover {
          @include themify($themes) {
            background-color: themed('blueBg');
            border-color: themed('blueBg');
          }

          :global .fill {
            @include themify($themes) {
              fill: themed('primaryColor');
            }
          }
        }

        i {
          margin-left: 8px;
          margin-right: 0 !important;
        }
      }

      &__divider {
        width: 1px;
        height: 100%;
        @include themify($themes) {
          background-color: themed('gray50');
        }
      }
    }
  }

  &__tabs {
    display: flex;
    align-items: center;
    flex-shrink: 1;
    width: 100%;
    overflow-y: auto;
    @include hide-scroll-bar();

    &__wrap {
      display: flex;
      width: 100%;
      padding: 0 20px;
      box-sizing: border-box;
      position: relative;
    }

    &__item {
      min-width: auto !important;

      & span {
        @include text-overflow();
      }
    }

    :global .tabItem {
      padding: 20px 0 12px;
    }

    ::-webkit-scrollbar {
      height: 4px;
    }
  }

  // FOOTER

  &__footer {
    padding: 0 24px;
    display: flex;
    align-items: center;
    @include themify($themes) {
      background-color: themed('mainBg');
      border-top: 1px solid themed('disabled');
    }

    &__left {
      flex-grow: 1;
    }

    &__right {
      display: flex;
      align-items: center;
      box-sizing: border-box;

      &__tmpl {
        margin-right: 60px;
      }

      &__btns {
        & > div {
          margin-left: 8px;
        }

        &__loader {
          position: relative;
          top: 4px;
          box-sizing: border-box;
        }
      }
    }
  }

  &__loader {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background-color: rgba(255, 255, 255, 0.7);
    z-index: 100;
  }
}

@include respond-to(mobile) {
  .cf {
    grid-template-rows: 96px 52px 1fr;
    grid-template-columns: 100%;

    &.readOnly {
      grid-template-rows: 96px 1fr !important;
    }
  }

  .cf__header {
    align-items: flex-start;
    padding: 14px 16px !important;
  }

  .cf__header__info {
    flex-direction: column;
    align-items: flex-start;
  }

  .cf__header__title {
    position: absolute;
    width: 100%;
    max-width: 100%;
    bottom: 4px;
    margin: 0 !important;
  }

  .cf__tabs__wrap {
    width: 100%;
    align-items: center;
    padding: 0 16px;
    box-sizing: border-box;
  }
}
