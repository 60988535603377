
        @import "@control-front-end/common/styles/variables";
        @import "@control-front-end/common/styles/mixins";
        
.modal {
  height: 90% !important;
  max-height: 90% !important;
  position: relative;
  width: 800px !important;
  @include themify($themes) {
    background-color: themed('mainBg');
  }

  :global .idChip {
    margin: 0 8px !important;
    @include themify($themes) {
      background-color: themed('gray50');
    }
  }

  :global .label {
    font-weight: 600;
    font-size: 14px !important;
    transform: translate(0px, -22px) !important;
    @include themify($themes) {
      color: themed('textColor') !important;
    }
  }
}

.content {
  margin-bottom: 0 !important;
  padding: 0 !important;
  @include hide-scroll-bar();
}

.buttons {
  justify-content: flex-end !important;
  padding: 20px !important;
  @include themify($themes) {
    border-top: 1px solid themed('gray50');
  }

  & > div {
    flex-grow: initial !important;
  }
}

.general {
  padding: 24px 20%;
  @include themify($themes) {
    background-color: themed('workSpaceBg');
  }
}

.filter {
  min-height: calc(100% - 196px);
  padding: 20px;

  &__section {
    display: flex;
    flex-direction: column;
    padding: 20px 0 0 0;

    &.dynamic {
      padding: 0;
    }
  }

  &__row {
    display: flex;
    position: relative;
    gap: 12px;
  }

  &__item {
    display: flex;
    flex-direction: column;
    max-width: 200px;

    & > span {
      margin-bottom: 12px;
    }

    & :global .error {
      & :global .field {
        @include themify($themes) {
          border-color: themed('red1') !important;
        }
      }

      & :global .error {
        position: relative;
      }
    }

    &.action {
      flex-direction: row;
      align-items: center;

      & :global .selected {
        width: 200px;
      }

      & > div:last-child {
        padding: 12px;

        i {
          cursor: pointer;
        }
      }
    }
  }

  &__textfield {

    & :global .field {
      width: 200px !important;
      box-sizing: border-box;
    }
  }

  &__select {
    width: 152px;
  }

  &__calendar {
    position: absolute;
    left: 0;
    top: 20px;
    z-index: 30;

    &.dateTo {
      left: -24px;
    }

    &__error {
      position: absolute;
      top: 56px;
      left: 216px;
    }
  }

  &__oper {
    width: 80px;
  }

  &__status,
  &__owner,
  &__amount {
    width: 240px;
  }

  &__btn {
    display: flex;
    width: fit-content;
    margin-bottom: 20px;
  }

  &__divider {
    width: 100%;
    margin: 12px 0 !important;
    @include themify($themes) {
      background-color: themed('gray50') !important;
    }
  }

  &__dash {
    margin: 0 -8px;
    transform: translate(0, 28px);
    @include themify($themes) {
      color: themed('gray35') !important;
    }
  }
}

@include respond-to(mobile) {
  .filter {
    padding: 16px;
  }

  .filter__row {
    flex-wrap: wrap;
    width: 100%;
    gap: 4px;
  }

  .filter__dash {
    display: none;
  }
}
