
        @import "@control-front-end/common/styles/variables";
        @import "@control-front-end/common/styles/mixins";
        
.actorSelectField,
.accountSelectField,
.typeSelectField {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
}

.colorPickerField {
  min-width: 130px;
  max-width: 130px;
  box-sizing: border-box;
  @include themify($themes) {
    color: themed('gray35');
  }
}
.colorPickerField input {
  @include themify($themes) {
    color: themed('gray35');
  }
}

.colorPickerField :global .field {
  @include themify($themes) {
    background-color: themed('mainBg');
  }
}

.actorSelectField,
.accountSelectField > div {
  min-width: 236px;
}

.actorSelectField {
  width: 30%;
}
.actorSelectField > div:only-child {
  width: 100%;
}

.accountSelectField {
  width: 40%;
  box-sizing: border-box;
  max-width: 330px;
}

.accountSelectField > div:only-child {
  width: 100%;
}
.typeSelectField {
  width: 20%;
  max-width: 120px;
}

.chart__source {
  &__type {
    @include modal-select();
  }

  &__remove {
    padding: 4px;
    cursor: pointer;
    @include btn-icon-hover();
  }
}
