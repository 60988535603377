
        @import "@control-front-end/common/styles/variables";
        @import "@control-front-end/common/styles/mixins";
        
.users {
  position: relative;
  display: flex;
  justify-content: flex-start;
  height: fit-content;

  &.pointer {
    cursor: pointer;
  }

  &__avabox {
    position: relative;
    border-radius: 50%;
    padding: 1px;
    @include themify($themes) {
      border: 1px solid themed('descrBorder');
      background-color: themed('mainBg');
    }
    margin-right: 8px;

    &:last-child {
      margin-right: 0;
    }
  }

  &__avatar {
    &.small {
      width: 28px;
      height: 28px;
    }
  }

  &__counter {
    display: flex;
    position: relative;
    align-items: center;
    user-select: none;
    border-radius: 50%;
    background-size: cover;
    justify-content: center;
    font-size: 12px;
    font-weight: 600;
    @include themify($themes) {
      color: themed('primaryColor');
      background-color: themed('primary60Color');
    }

    &.medium {
      width: 32px;
      height: 32px;
    }

    &.small {
      width: 28px;
      height: 28px;
    }
  }
}
