
        @import "@control-front-end/common/styles/variables";
        @import "@control-front-end/common/styles/mixins";
        
.searchField {
  width: 100%;
  max-height: 58px;
  overflow-y: auto;
}

.usersResults {
  display: flex;
  padding: 16px 0 60px !important;
  box-sizing: content-box !important;
  height: 30vh;
  max-height: 340px;
  overflow: hidden;
  position: relative;

  &.iframe {
    height: 22vh;
  }

  &__wrap > div {
    min-width: 50%;
  }

  &__loader {
    position: absolute;
    cursor: default;
    pointer-events: none;
    background-color: rgba(255, 255, 255, 0.5);
  }

  &__invite {
    display: flex;
    @include themify($themes) {
      background-color: themed('workSpaceBg') !important;
    }

    & > div {
      padding-left: 0;
      border-color: transparent !important;

      &:hover {
        background-color: inherit !important;
      }
    }
  }

  &__actions {
    position: absolute;
    bottom: 0;
    width: 100%;
    padding: 12px;
    box-sizing: border-box;
    @include themify($themes) {
      border-top: 1px solid themed('gray50');
      background-color: themed('mainBg');
    }

    &__counter {
      display: flex;
      flex-grow: 1;
    }

    &__submitBtn {
      width: 128px;
    }
  }
}

