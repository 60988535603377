
        @import "@control-front-end/common/styles/variables";
        @import "@control-front-end/common/styles/mixins";
        
.le {
  &__item {
    display: flex;
    align-items: center;
    padding: 0 24px;
    position: relative;
    cursor: pointer;
    min-height: 32px;
    transition: background-color 0.2s;
    border-radius: 4px;
    margin-bottom: 2px;
    overflow: hidden;

    &:first-child {
      margin-bottom: 0;
    }

    &:nth-child(2) {
      margin-top: 2px;
    }

    &:last-child {
      .le__item__info {
        margin-bottom: 0;
        border-bottom: 0 !important;
      }
    }

    &:hover {
      @include themify($themes) {
        background-color: themed('primary50Color');
      }
    }

    &.compact {
      border-radius: 0px;

      &:hover {
        box-shadow: 0 0 8px 2px rgb(100, 157, 231, 0.8);
        transition: .3s;
        @include themify($themes) {
          color: themed('primaryColor');
        }
      }
    }

    &.active {
      @include themify($themes) {
        background-color: themed('primary60Color') !important;
      }

      &.compact {
        @include themify($themes) {
          background-color: themed('primaryColor') !important;
        }
      }
    }

    &__icon {
      margin-right: 20px;
      @include btn-icon();

      &:after {
        left: -8px;
        top: -8px;
        width: 32px;
        height: 32px;
      }
    }

    &__new {
      width: 4px;
      height: 16px;
      border-radius: 1px;
      margin-right: 20px;
      @include themify($themes) {
        background-color: themed('primaryColor');
      }

      &.compact {
        margin-right: 12px;
      }
    }

    &__info {
      flex: 1 0 184px;
      padding: 12px 0;
      width: 100%;
      @include themify($themes) {
        border-bottom: 1px solid themed('descrBorder');
      }

      &.compact {
        padding: 6px 0;

        &.active {
          border-bottom: 0px;
        }
      }
    }

    &__header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 6px;
      width: 100%;

      &.compact {
        margin-bottom: 0;
        :global .badge {
          width: 20px;
          height: 20px;
          min-width: 20px;
          position: absolute;
          right: 4px;
        }
        &.active {
          a,
          span {
            @include themify($themes) {
              color: themed('mainBg');
            }
          }
          :global .badge {
            width: 20px;
            height: 20px;
            min-width: 20px;
            @include themify($themes) {
              background-color: themed('mainBg');
              a,
              span {
                color: themed('primaryColor');
              }
            }
          }
        }
      }
    }

    &__owner {
      margin-right: 8px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-shrink: 0;

      & > span {
        max-width: 260px;
        @include text-overflow;
      }

      &__avatar {
        box-sizing: border-box;
        height: 28px !important;
        width: 28px !important;

        &__box {
          @include themify($themes) {
            border: 1px solid themed('descrBorder');
          }
          border-radius: 50%;
          padding: 1px;
        }
      }

      &__name {
        margin-left: 8px;
      }
    }

    &__title {
      display: flex;
      flex: 1 0 184px;
      align-items: center;
      width: 100%;
      overflow: hidden;

      a:first-child {
        @include themify($themes) {
          color: themed('textColor');
        }
      }

      &__text {
        font-weight: 600;
        @include text-overflow;

        &.compact.active {
          @include themify($themes) {
            color: themed('mainBg');
          }
        }
      }

      &__time {
        margin-left: auto;
        flex-shrink: 0;
        @include themify($themes) {
          color: themed('gray1');
        }
      }
    }

    &__descr {
      align-self: flex-start;
      display: block;
      padding: 4px 0;
      line-height: 24px;
      @include text-overflow;

      & > div:first-child {
        margin-left: 0 !important;
      }

      &__wrap {
        display: flex;
        align-items: center;
        height: 28px;
        min-height: 24px;
        width: 100%;
        overflow: hidden;
      }

      &.inline {
        margin-bottom: 0;
        white-space: normal !important;
        align-items: center;
        line-height: 24px;
        height: 24px;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
      }

      a {
        @include word-break();
      }

      a:not([data-chip]):hover {
        text-decoration: underline;
      }

      img {
        cursor: pointer;
        max-width: 100%;
        max-height: 100%;
      }

      :global .event__descr__chip {
        margin: 0 !important;

        &:hover {
          @include themify($themes) {
            border-color: themed('primaryColor');
            color: themed('primaryColor');
          }
        }

        margin-left: 8px;
        margin-right: 8px;
        display: inline-flex;
        padding: 0 7px;
        position: relative;
        box-sizing: border-box;
        align-items: center;
        @include themify($themes) {
          border-color: themed('descrBorder');
        }
        border-width: 1px;
        border-style: solid;
        margin-bottom: 2px;
        height: 24px;
        border-radius: 4px;
        cursor: pointer;

        &__icon {
          width: 12px;
          display: flex;
          margin-right: 8px;

          svg {
            width: 12px;
            height: 12px;
          }

          path {
            @include themify($themes) {
              fill: themed('primaryColor');
            }
          }
        }

        &__label {
          max-width: 120px;
          margin-top: -1px;
          font-weight: 400;
          font-size: 14px;
          @include text-overflow;
        }
      }
    }

    &__update {
      display: flex;
      flex-direction: row;
      margin-top: 12px;
      margin-bottom: 3px;

      &__info {
        margin-top: 8px;
        width: 100%;
        @include themify($themes) {
          color: themed('gray1');
        }
        @include text-overflow;

        &__nick {
          display: inline-flex;
          max-width: 360px;

          & > span {
            @include text-overflow;
          }

          &.compact {
            max-width: 240px;
          }
        }

        &.compact {
          margin-top: 0;

          &.active {
            @include themify($themes) {
              color: themed('mainBg');

              a {
                color: themed('mainBg');
              }

              a[data-chip] {
                color: themed('primaryColor');
              }
            }
          }

          span {
            font-size: 12px;
          }
        }
      }

      &__reaction {
        margin-left: 4px;
        font-size: 14px;
        @include text-overflow;
      }
    }

    &__name {
      display: inline-flex;
      align-items: center;
      margin-left: 12px;
      margin-right: 5px;

      span {
        font-weight: inherit !important;
      }
    }

    &__blank {
      min-height: 19px;
    }
  }
}

@include respond-to(mobile) {
  .le__item {
    padding: 0 16px;
  }

  .le__item__header {
    margin-bottom: 12px;
  }

  .le__item__info {
    padding: 16px 0;
  }

  .le__item__owner__name,
  .le__item__update__cnt__wrap,
  .le__item__name {
    display: none;
  }

  .le__item__owner__avatar {
    height: 20px !important;
    width: 20px !important;
  }

  .le__item__title > i {
    display: none;
  }

  .le__item__descr__wrap {
    height: auto;
  }

  .le__item__location {
    min-width: 68px;
  }

  .le__item__descr {
    height: auto;
    max-height: 40px !important;
    line-height: 22px;
    white-space: normal;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .le__item__update {
    margin-bottom: 0;
  }

  .le__item__update__info {
    margin-top: 8px;
  }

  .le__item__update__info__nick {
    max-width: 220px;
  }
}
