
        @import "@control-front-end/common/styles/variables";
        @import "@control-front-end/common/styles/mixins";
        
.corrective {

  :global .label {
    font-weight: 600;
    @include themify($themes) {
      color: themed('textColor') !important;
    }
  }

  :global .idChip {
    @include themify($themes) {
      background-color: themed('gray50') !important;
    }
  }

  &__transaction {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 12px;

    &__type {
      width: fit-content;
      min-width: 60px;
      padding: 0 8px !important;
      margin-bottom: 0 !important;

      @include themify($themes) {
        &.credit {
          color: themed('primaryColor');
          background-color: themed('blueBg');
          border-color: themed('blueBg');
        }
        &.debit {
          color: themed('redBorder');
          background-color: themed('redBg');
          border-color: themed('redBg');
        }
      }
    }
  }

  &__block {
    display: flex;
    flex-direction: column;
    width: 40%;
    gap: 4px;

    &__label {
      margin-bottom: 4px;
      @include themify($themes) {
        color: themed('gray2');
      }
    }

    &__row {
      display: flex;
      align-items: center;
      gap: 8px;
    }

    &__col {
      display: flex;
      flex-direction: column;
      width: fit-content;
      overflow: hidden;

      span {
        @include text-overflow();
      }
    }

    &__divider {
      margin: 12px 0 !important;
      width: 100%;
      @include themify($themes) {
        background-color: themed('gray50') !important;
      }
    }
  }

  &__date {
    margin-top: 16px;
    width: 250px;
  }

  &__comment {
    flex-grow: 10;
  }

  &__sign {
    width: 64px;

    :global .rightIcon {
      margin-left: 4px !important;
    }
  }

  &__textfield {
    max-width: 142px;
  }

  &__amount {
    display: flex;
    align-items: flex-start;
    width: 100%;
    gap: 8px;

    & > span {
      @include themify($themes) {
        color: themed('gray35');
      }
    }
  }
}

.readOnly {
  pointer-events: none;
  cursor: default;
}
