
        @import "@control-front-end/common/styles/variables";
        @import "@control-front-end/common/styles/mixins";
        
.wrap {
  width: 320px;
  height: 440px;
}

.block {
  min-height: 48px;
}

.description {
  min-height: 56px;
}

.descriptionText {
  word-break: break-word;
}

.growItem {
  flex-grow: 1;
}

.fieldIcon {
  height: 20px;
  display: flex;
  align-items: center;
}

.proportionalItem {
  flex: 1;
}

.title {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  white-space: normal;
  overflow: hidden;
  line-height: 24px;
}

.avatar {
  flex-shrink: 0;
}

.textBlock {
  max-height: 44px;
}

.layer {
  overflow: hidden !important;
}

.layerImage {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.valuesList {
  display: flex;
  white-space: nowrap;
  gap: 4px;
  overflow-x: auto;
  overflow-y: hidden;
}

.widgetButton {
  border: none !important;
  @include themify($themes) {
    background-color: themed('mainBg') !important;
    color: themed('textColor') !important;
  }
}
