
        @import "@control-front-end/common/styles/variables";
        @import "@control-front-end/common/styles/mixins";
        
.collabsibleText {
  p {
    line-height: 22px;
    margin-block-start: 4px;
    margin-block-end: 4px;
    word-break: break-word;
  }

  :global .clamp-lines__button {
    font-weight: 600;
    border: none;
    background-color: transparent;
    cursor: pointer;
    padding: 0;
    margin-bottom: 4px;
    @include themify($themes) {
      color: themed('primaryColor');
    }
  }
}
