
        @import "@control-front-end/common/styles/variables";
        @import "@control-front-end/common/styles/mixins";
        
.navigation {
  display: flex;
  flex-direction: column;
  width: 220px;
  height: 100%;
  padding: 0 16px;
  margin-right: 12px;
  overflow: hidden;
  position: relative;
  transition: width 400ms cubic-bezier(.23, 1, .32, 1) 0ms;
  z-index: 9;
  @include themify($themes) {
    border-right: 1px solid themed('disabled');
  }

  &.collapsed {
    width: 0;
    padding: 0 13px;
    margin-right: 0px;
    border: none;

    & > * {
      display: none;
    }
  }

  &__title {
    margin: 16px 0 8px 0;
  }

  &__arrows {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    width: 24px;
    height: 32px;
    border-radius: 0 4px 4px 0;
    background-color: inherit;
    border: 1px solid transparent;
    @include btn-icon-hover();
    position: absolute;
    right: 12px;
    top: 8px;

    &.collapsed {
      display: flex;
      left: 0;
      @include themify($themes) {
        background-color: themed('mainBg');
        border-color: themed('gray50');
      }

      i {
        transform: rotate(180deg);
      }
    }
  }

  &__list {
    width: 100%;
    height: calc(100% - 42px);
    overflow-x: hidden;
    overflow-y: auto;
    @include hide-scroll-bar();
  }

  &__item {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    padding: 0 12px;
    border-radius: 4px;
    box-sizing: border-box;
    z-index: 3;
    left: -10px;
    cursor: pointer;

    &.system {
      padding: 8px 12px 8px 40px;
    }

    &__drag {
      cursor: grab;
      margin-right: 4px;
      @include btn-icon-hover();
    }

    &__label {
      width: 100%;
      @include text-overflow();
    }

    &__more {
      display: block;
      right: 0;
      opacity: 0;
      position: relative;
      @include btn-icon-hover();
    }

    &:hover,
    &.active {
      @include themify($themes) {
        color: themed('primaryColor');
        background-color: themed('blueBg');
      }
    }

    &:hover {
      .navigation__item__more {
        opacity: 1;
      }
    }
  }

  &.readOnly {
    .navigation__item.system {
      padding-left: 12px !important;
    }
  }

  :global .layout {
    position: relative;
  }
}

@include respond-to(mobile) {
  .navigation,
  .navigation__arrows {
    display: none;
  }
}
