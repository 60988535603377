
        @import "@control-front-end/common/styles/variables";
        @import "@control-front-end/common/styles/mixins";
        
.fa {
  overflow: auto;
  height: 100%;
  padding: 20px;
  box-sizing: border-box;

  &__section {
    margin-bottom: 20px;
    padding-bottom: 20px;
    @include themify($themes) {
      border-bottom: 1px solid themed('gray50');
    }

    :global .toggle > div:first-child {
      background-color: transparent !important;

      i {
        margin: 0 !important;
      }
    }

    div[class*="-label-"] {
      font-size: 14px !important;
      font-weight: 600 !important;
    }

    :global .label {
      font-size: 14px !important;
      font-weight: 600 !important;
    }

    &__options > div {
      display: flex;
      flex-direction: row;
      gap: 16px;
      width: calc(50% - 24px);
      margin-bottom: 16px;
    }

    &__close {
      cursor: pointer;
      @include btn-icon-hover();
    }

    &__title {
      margin-bottom: 12px;
    }

    &__desc {
      margin-top: 2px;
      @include themify($themes) {
        color: themed('gray1');
      }
    }
  }

  &__disclaimer {
    margin-bottom: 20px;
  }

  &__wrap {
    display: flex;
    height: calc(100% - 54px);
    overflow: hidden;
  }

  &__list {
    display: flex;
    flex-direction: column;
    gap: 16px;
    flex: 1 1 auto;
    padding-right: 8px;
    margin-right: 8px;
    height: 100%;
    overflow: auto;

    &__btn {
      width: 140px;
    }

    &__numeric {
      @include themify($themes) {
        border-bottom: 1px solid themed('gray50');
      }
    }
  }

  .field {
    :global .field {
      padding-top: 9px !important;
      padding-bottom: 10px !important;
      @include themify($themes) {
        background-color: themed('mainBg') !important;
      }
    }
  }
}
