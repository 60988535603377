
        @import "@control-front-end/common/styles/variables";
        @import "@control-front-end/common/styles/mixins";
        
.modal {
  height: 90% !important;
  max-height: 90% !important;
  position: relative;
  border: none !important;
}

.content {
  margin-bottom: 0 !important;
  padding: 0 !important;
  width: 100%;
  height: 100%;
  border-radius: 9px;
  @include themify($themes) {
    background-color: themed('mainBg');
  }

  & > div {
    overflow: hidden;
  }

  &__iframe {
    border: 0;
    width: 100%;
    height: 100%;
  }

  &__loader {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
  }
}
