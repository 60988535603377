
        @import "@control-front-end/common/styles/variables";
        @import "@control-front-end/common/styles/mixins";
        
.table {
  overflow-y: visible;
  overflow-x: hidden;

  & :global .scTableRow {
    padding: 0 !important;
    @include themify($themes) {
      background-color: themed('workSpaceBg');
    }

    .scTableCell:first-child {
      font-weight: 600;
    }
  }

  & :global .scTableRowDivider {
    margin: 0 !important;
  }

  &__cell {
    width: 25% !important;
    @include text-overflow();

    &.actor {
      display: flex;
      align-items: center;

      & > div {
        display: flex;
        align-items: center;
        margin-right: 8px;

        & > i {
          margin-right: 4px;
        }
      }

      &__title {
        width: 100% !important;
        display: block !important;
      }
    }
  }
}

.loader {
  display: flex;
  width: 100%;
  height: 100%;
  margin-top: -100px;
  justify-content: center;
  align-items: center;
}

@include respond-to(mobile) {
  .table {
    width: 100vw !important;
    display: grid;
    overflow-x: auto;
  }
}
