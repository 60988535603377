
        @import "@control-front-end/common/styles/variables";
        @import "@control-front-end/common/styles/mixins";
        
.section {
  padding: 24px 0 4px 0;
  @include themify($themes) {
    border-bottom: 12px solid themed('workSpaceBg');
  }

  &:last-child {
    border-bottom: 0;
    margin-bottom: 0;
  }

  &__content {
    box-sizing: border-box;
  }

  &__error {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-shrink: 0;
    @include themify($themes) {
      color: themed('red1');
    }

    :global .fill {
      @include themify($themes) {
        fill: themed('red1');
      }
    }
  }

  &.hidden {
    display: none;
  }
}
