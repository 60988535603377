
        @import "@control-front-end/common/styles/variables";
        @import "@control-front-end/common/styles/mixins";
        
.modal {
  height: 70%;
  max-height: 90% !important;
}

.content {
  padding: 20px !important;
  box-sizing: border-box;

  & > div {
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }

  &__tabs {
    margin-bottom: 4px;
  }

  &__stat {
    display: flex;
    align-items: start;
    justify-content: space-between;
    gap: 8px;
    margin-bottom: 16px;

    &:empty {
      display: none;
    }

    & > div {
      display: flex;
      flex-direction: column;
      gap: 4px;
      min-width: 96px;
    }

    &__chip {
      width: fit-content;
      @include themify($themes) {
        border-color: themed('gray50') !important;
        background-color: themed('gray50') !important;
      }
    }

    &__privs {
      display: flex;
      flex-wrap: wrap;
      gap: 4px;
    }
  }

  &__list {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    overflow: auto;

    &__row {
      display: flex;
      align-items: center;
      justify-content: space-between;
      position: relative;
      width: 100%;
      box-sizing: border-box;
      padding: 8px 0;
      gap: 8px;
      cursor: default;

      &:not(:last-child) {
        @include themify($themes) {
          border-bottom: 1px solid themed('gray50');
        }
      }

      &__info {
        display: flex;
        align-items: center;
        flex-shrink: 1;
        flex-grow: 1;
        width: calc(100% - 140px);
        gap: 8px;
        margin-right: 4px;
        @include text-overflow();

        &__name {
          max-width: calc(100% - 48px);
          @include text-overflow();
        }

        &__avatar {
          box-sizing: border-box;
          height: 22px !important;
          width: 22px !important;

          &__box {
            border-radius: 50%;
            padding: 1px;
            @include themify($themes) {
              border: 1px solid themed('descrBorder');
            }
          }
        }
      }

      &__chip {
        padding: 0 5px !important;
        margin: 0 !important;

        i {
          margin: 0 !important;
        }

        @include themify($themes) {
          background-color: themed('mainBg');
          border: 1px solid themed('disabled');
        }

        &.rating {
          @include themify($themes) {
            background-color: themed('gray30');
            border-color: themed('gray30');
          }

          span {
            width: fit-content;
            text-align: center;
            margin-left: 4px;
          }

          i {
            :global .fill {
              @include themify($themes) {
                fill: themed('textColor') !important;
              }
            }
          }

        }

        &.sign.completed {
          @include themify($themes) {
            border-color: themed('primaryColor') !important;
            background-color: themed('primary60Color') !important;
            :global .fill {
              fill: themed('primaryColor') !important;
            }
          }
        }

        &.done.completed {
          @include themify($themes) {
            border-color: themed('reactionDoneColor') !important;
            background-color: themed('reactionDoneBackground') !important;
            :global .fill {
              fill: themed('reactionDoneColor') !important;
            }
          }
        }

        &.rejected {
          @include themify($themes) {
            border-color: themed('reactionRejectColor') !important;
            background-color: themed('reactionRejectBackground') !important;
            :global .fill {
              fill: themed('reactionRejectColor') !important;
            }
          }
        }
      }
    }
  }

  &__accessDenied {
    display: flex;
    flex-direction: column;
    gap: 16px;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 50%;
  }

  &__info:hover {
    cursor: pointer;
    @include btn-icon-hover();
  }
}

.loader {
  display: flex;
  height: 112px;
  width: 100%;
  justify-content: center;
  align-items: center;
}

@include respond-to(mobile) {
  .content {
    padding: 16px !important;
  }

  .content__list__row {
    padding: 4px 0;
  }
}
