
        @import "@control-front-end/common/styles/variables";
        @import "@control-front-end/common/styles/mixins";
        
.dragZone {
  pointer-events: none !important;
  position: absolute;
  z-index: 1001;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;

  &__box {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    border-radius: 4px;
    @include themify($themes) {
      background-color: themed('primary30Color');
      border: 2px dashed themed('primaryColor');
    }
  }

  i {
    margin-bottom: 8px;
    :global .fill {
      @include themify($themes) {
        fill: themed('primaryColor');
      }
    }
  }
}
