
        @import "@control-front-end/common/styles/variables";
        @import "@control-front-end/common/styles/mixins";
        
@import url('https://fonts.googleapis.com/css?family=Open+Sans:400,400i,600,700,800&subset=cyrillic&display=swap');
@import '~normalize.css/normalize';

html {
  height: 100%;
}

// Core styles
body {
  height: 100%;
  @include open-sans;
  line-height: 20px;
  font-size: 14px;
  min-height: 100%;
  @include themify($themes) {
    color: themed('textColor');
  }
}

textarea,
input {
  @include open-sans;
  @include themify($themes) {
    color: themed('textColor');
  }
}

input[disabled] {
  @include themify($themes) {
    -webkit-text-fill-color: themed('textColor');
    color: themed('textColor');
  }
}

a {
  text-decoration: none !important;
  transition: color 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
  @include themify($themes) {
    color: themed('primaryColor');

    &:hover {
      color: themed('linkColorHover');
    }
  }

  &[rel="nofollow"] {
    &:hover {
      @include themify($themes) {
        color: themed('primaryColor');
      }
      text-decoration: underline;
    }
  }
}

mark {
  @include themify($themes) {
    color: themed('textColor');
    background-color: themed('secondaryColor');
    padding: 2px 0;
  }
}

*:focus {
  outline: 0 none;
}

//Core styles end
