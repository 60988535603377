
        @import "@control-front-end/common/styles/variables";
        @import "@control-front-end/common/styles/mixins";
        
.table {
  display: grid;
  grid-template-rows: 48px 1fr;
  grid-template-columns: 100%;
  height: 100%;

  :global .scTableRowList {
    justify-content: space-between;
    align-items: stretch;
  }
}

.row.active {
    @include themify($themes) {
      background-color: themed('primary60Color');
    }

    :global .customized > div > div:first-child {
      @include themify($themes) {
        background-color: themed('primary60Color');
      }
    }
}

.row.disabled {
  opacity: 0.5;
  pointer-events: none;
}

.row.customized {
  :global .scTableRowList {
    position: relative;
    padding: 0 !important;

    & > div:first-child {
      position: sticky;
      left: 0;
      top: 0;
      z-index: 2;
      box-shadow: 6px 4px 10px rgba(57, 62, 68, 0.2);
      @include themify($themes) {
        background-color: themed('mainBg');
      }
    }
  }

  :global .scTableCell {
    padding: 4px 0 !important;
  }
}

.cell {
  display: flex;
  align-items: center;
  margin-right: 12px;
  box-sizing: border-box;

  &.empty {
    display: none;
  }

  &.alignEnd {
    justify-content: flex-end;
  }
}

.custom {
  padding: 8px 0 !important;
  @include text-overflow();

  &.edit > div {
    white-space: normal;
  }

  &.calendar > div {
    width: 100%;
    @include text-overflow();
  }

  &.check,
  &.select,
  &.multiSelect,
  &.radio {
    display: flex;
    flex-wrap: wrap;
    align-content: center;
    width: 100%;
    overflow-wrap: break-word;

    & > div:not(:only-child):not(:last-child) {
      margin: 0 4px 4px 0;
    }

    a[data-chip] {
      width: auto;
      max-width: 100%;
      box-sizing: border-box;
      display: block;
      @include text-overflow();
    }
  }
}

.shared {
  @include btn-icon-hover();
}

.clickable {
  cursor: pointer;
}

.menu {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: flex-end;

  i {
    padding: 8px;
    cursor: pointer;
    @include btn-icon-hover();
  }
}

.itemMenu {
  position: absolute;
  z-index: 10;
  right: 0;
}

.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.pagination {
  position: absolute;
  bottom: 0;
  left: 24px;
  right: 24px;
  padding: 12px;
  width: calc(100% - 48px);
}

.paginationLoader {
  display: flex;
  justify-content: center;
  align-items: center;
  bottom: 48px;
  @include pagination-loader();
}

.verticalScroll {
  overflow-y: auto;
}

@include respond-to(mobile) {
  .table {
    width: 100vw !important;
    display: grid;
    overflow-x: auto;
    margin: 0;
    padding-bottom: 24px;
    box-sizing: border-box;
  }

  .row {
    padding: 0 16px;
    @include no-touch-select();
  }
}
