
        @import "@control-front-end/common/styles/variables";
        @import "@control-front-end/common/styles/mixins";
        
.search {
  display: flex;
  position: relative;
  flex: 1 1 100px;
  margin-right: 24px;

  &__field {
    width: 100%;
    padding: 0 !important;

    > div {
      padding-top: 8px !important;
      padding-bottom: 9px !important;
      border: none !important;
      @include themify($themes) {
        background-color: themed('workSpaceBg');
      }
    }

    & :global .rightIcon {
      cursor: pointer;
      i,
      svg {
        width: 12px;
        height: 12px;
      }
    }

    &.withSubmit {
      & :global .endAdornment {
        &:before {
          content: 'Press ⏎ to search';
          width: fit-content;
          white-space: nowrap;
          padding-right: 8px;
          position: absolute;
          right: 32px;
          top: 8px;
          @include themify($themes) {
            color: themed('gray1');
            border-right: 1px solid themed('gray50');
          }
        }
      }
    }
  }

  &__results {
    position: absolute;
    top: 38px;
    box-sizing: border-box;
    overflow: auto;
    max-height: 300px;
    width: 100%;
    border-radius: 3px;
    padding: 8px 0;
    z-index: 1;
    box-shadow: 0 6px 16px 0 rgba(57, 63, 70, 0.1);
    @include themify($themes) {
      background-color: themed('mainBg');
      border: 1px solid themed('editedBadgeBg');
    }

    &__item {
      box-sizing: border-box;
      padding: 8px 28px;
      display: flex;
      align-items: center;
      cursor: pointer;
      transition: 0.3s;

      &.active,
      &:hover {
        @include themify($themes) {
          background-color: themed('primary10Color');
        }
      }

      &__icon {
        width: 40px;
        flex: 0 0 40px;

        & :global .fill {
          @include themify($themes) {
            fill: themed('primaryColor') !important;
          }
        }
      }

      &__info {
        overflow: hidden;
      }

      &__title {
        font-weight: 600;
        @include text-overflow();
      }

      &__extra {
        font-size: 12px;
        @include text-overflow();
        @include themify($themes) {
          color: themed('gray1');
        }
      }
    }
  }
}

.onTop {
  z-index: 10;
}

@include respond-to(mobile) {
  .search {
    margin-right: 12px !important;
  }
}
