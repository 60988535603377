
        @import "@control-front-end/common/styles/variables";
        @import "@control-front-end/common/styles/mixins";
        
.chart {
  display: flex;
  flex-direction: column;
  overflow: hidden !important;
  width: 100%;
  height: 100%;

  &__header {
    &__menu {
      position: absolute;
      top: 4px;
      right: 12px;
      z-index: 2;
      border-radius: 8px;

      &.wrap {
        padding: 8px 0;
      }

      [class^='nested'] {
        position: absolute !important;
        left: 144px !important;
        margin-top: 4px;
      }
    }
  }
}

.pinButton {
  width: 24px;
  height: 24px !important;

  &:not(.pinned) {
    border: none;
    @include themify($themes) {
      background-color: themed('mainBg') !important;
    }

    :global .fill {
      @include themify($themes) {
        fill: themed('gray2') !important;
      }
    }
  }
}

.pinned {
  @include themify($themes) {
    background-color: themed('primaryColor');
  }
}
