
        @import "@control-front-end/common/styles/variables";
        @import "@control-front-end/common/styles/mixins";
        
.accounts {
  display: flex;
  flex-direction: column;

  &__list {
    display: flex;
    flex-direction: column;

    &__item {
      display: flex;
      align-items: center;
      gap: 8px;
      width: 100%;

      & > *:nth-child(1),
      & > *:nth-child(2) {
        display: flex;
        width: 240px;
      }

      :global .bordered {
        width: 100%;
      }

      &__del {
        cursor: pointer;
        @include btn-icon-hover();
      }
    }

    &__btn {
      width: fit-content !important;
      padding: 0 !important;
      transform: translate(0, -12px);
      &:hover {
        border-color: transparent !important;
        background-color: transparent !important;
      }
    }
  }
}
