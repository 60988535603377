
        @import "@control-front-end/common/styles/variables";
        @import "@control-front-end/common/styles/mixins";
        
.ac {
  display: flex;
  position: relative;

  &__tooltip {
    display: flex;
    flex-direction: column;

    &__line {
      display: flex;
      flex-direction: row;
      align-items: center;
    }
  }
}

:global .colorChart {
  border-radius: 50%;
  box-sizing: border-box;
  border: 1px solid #fff;

  circle, path {
    stroke: white;
    stroke-width: 3px;
  }
}
