
        @import "@control-front-end/common/styles/variables";
        @import "@control-front-end/common/styles/mixins";
        
.copy {
  height: 24px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: #FFFFFF;
  background-color: #3F91FB;
  border-radius: 4px;
  border: none;

  &.error {
    border: 1px solid #D34437;
  }

  & i {
    margin-left: 10px;
    margin-right: 6px;
  }

  &__label {
    min-width: 30px;
    max-width: 220px;
    @include text-overflow();

    & span {
      margin-left: 6px;
    }
  }
}

.edit {
  height: 24px;
  display: flex;
  background-color: #FFFFFF;
  border-radius: 4px;
  flex-direction: row;
  justify-content: flex-end;

  &__field {
    height: 24px;
    max-width: 220px;
    color: #393F48;
    margin-top: -19px;
    padding-left: 8px;
    padding-right: 4px;

    & > div {
      border-bottom-style: none;
      padding-bottom: 4px;
    }
  }
}



