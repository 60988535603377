
        @import "@control-front-end/common/styles/variables";
        @import "@control-front-end/common/styles/mixins";
        
.pair {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 480px;
  gap: 12px;
  padding: 12px 0 64px;
  box-sizing: border-box;
  position: relative;
  box-shadow: 0 4px 8px rgba(35, 44, 56, 0.12);
  border-radius: 4px;
  @include themify($themes) {
    border: 1px solid themed('gray50');
    background-color: themed('mainBg');
  }

  &__block {
    display: flex;
    flex-direction: column;
    width: 50%;

    &:nth-child(1) {
      margin-left: 12px;
    }

    &:nth-child(2) {
      margin-right: 12px;
    }

    :global .label {
      font-weight: 600;
      font-size: 14px !important;
      transform: translate(0px, -24px) !important;
      @include themify($themes) {
        color: themed('textColor') !important;
      }
    }
  }

  &__actions {
    position: absolute;
    width: 100%;
    bottom: 0;
    display: flex;
    justify-content: flex-end;
    gap: 12px;
    padding: 12px;
    box-sizing: border-box;
    @include themify($themes) {
      border-top: 1px solid themed('workSpaceBorder');
    }
  }
}
