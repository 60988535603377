
        @import "@control-front-end/common/styles/variables";
        @import "@control-front-end/common/styles/mixins";
        
:global .react-resizable {
  position: relative;

  ::-webkit-scrollbar-track {
    background: none !important;
    box-shadow: none !important;
  }

  ::-webkit-scrollbar-thumb {
    background: none !important;
  }
}

$ordinal-resizer-size: 16px;
$main-resizer-width: 8px;
$ordinal-resizer-offset: calc(-1 * (#{$ordinal-resizer-size} / 2));

.resizable-handle {
  position: absolute;
  z-index: 9999;
  pointer-events: all;

  &.nw,
  &.ne,
  &.sw,
  &.se {
    height: $ordinal-resizer-size;
    width: $ordinal-resizer-size;
  }

  &.sw {
    bottom: $ordinal-resizer-offset;
    left: $ordinal-resizer-offset;
    cursor: nesw-resize;
  }

  &.se {
    bottom: $ordinal-resizer-offset;
    right: $ordinal-resizer-offset;
    cursor: nwse-resize;
  }
  &.nw {
    top: $ordinal-resizer-offset;
    left: $ordinal-resizer-offset;
    cursor: nwse-resize;
  }
  &.ne {
    top: $ordinal-resizer-offset;
    right: $ordinal-resizer-offset;
    cursor: nesw-resize;
  }

  &.w,
  &.e {
    top: $ordinal-resizer-size;
    height: calc(100% - 2 * #{$ordinal-resizer-size});
    width: $main-resizer-width;
    cursor: ew-resize;
  }
  &.w {
    left: -4px;
  }
  &.e {
    right: -4px;
  }
  &.n,
  &.s {
    left: $ordinal-resizer-size;
    width: calc(100% - 2 * #{$ordinal-resizer-size});
    height: $main-resizer-width;
    cursor: ns-resize;
  }
  &.n {
    top: calc(-#{$main-resizer-width} / 2);
  }
  &.s {
    bottom: calc(-#{$main-resizer-width} / 2);
  }

  &.showHandles {
    &.nw,
    &.ne,
    &.sw,
    &.se,
    &.w,
    &.e,
    &.n,
    &.s {
      width: 8px;
      height: 8px;
      @include themify($themes) {
        background-color: themed('mainBg');
        border: 1px solid themed('primaryColor');
      }
    }
    &.nw {
      left: -5px;
      top: -5px;
    }
    &.ne {
      top: -5px;
      right: -5px;
    }
    &.sw {
      bottom: -5px;
      left: -5px;
    }
    &.se {
      bottom: -5px;
      right: -5px;
    }
    &.n {
      top: -5px;
      left: calc(50% - 4px);
    }
    &.s {
      bottom: -5px;
      left: calc(50% - 4px);
    }
    &.w {
      left: -5px;
      top: calc(50% - 4px);
    }
    &.e {
      right: -5px;
      top: calc(50% - 4px);
    }
  }
}
