
        @import "@control-front-end/common/styles/variables";
        @import "@control-front-end/common/styles/mixins";
        
.modal {
  max-height: 90% !important;
}

.transaction {
  & > div {
    overflow-x: hidden;
  }

  :global .label {
    font-weight: 600;
    @include themify($themes) {
      color: themed('textColor') !important;
    }
  }

  &__actor {
    width: 60% !important;
  }
}

.amount {
  div :global .field {
    @include themify($themes) {
      background-color: themed('mainBg');
    }
  }
}

.selectField {
  & > div:first-child {
    width: 60%;
  }
}
