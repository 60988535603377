
        @import "@control-front-end/common/styles/variables";
        @import "@control-front-end/common/styles/mixins";
        
.corrective {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 16px;
  box-sizing: border-box;
  border-radius: 4px;
  box-shadow: 0 4px 6px 0 rgba(61, 65, 133, 0.08);
  @include themify($themes) {
    background-color: themed('mainBg');
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__acc {
    display: flex;
    align-items: center;
    span {
      margin-right: 8px;
      @include themify($themes) {
        color: themed('gray2');
      }
    }
  }

  &__data {
    display: flex;
    align-items: center;

    & > div:first-child {
      width: 320px;
      margin-right: 36px;
    }

    & > div:not(:first-child) {
      margin: 0 8px;
    }

    & :global .disabled {
      opacity: 1;
      :global .field {
        @include themify($themes) {
          color: themed('textColor') !important;
          background-color: themed('gray50');
        }
      }
    }

    :global .label {
      font-weight: 600;
      @include themify($themes) {
        color: themed('textColor') !important;
      }
    }
  }

  &__comment {
    width: 80%;
  }

  &__btn {
    width: 186px;
  }

  & :global hr {
    margin: 16px 0 !important;
  }
}