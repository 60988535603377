
        @import "@control-front-end/common/styles/variables";
        @import "@control-front-end/common/styles/mixins";
        
.sa {
  display: flex;
  position: relative;

  &__field {
    width: 112px;
    padding: 0 !important;
    cursor: pointer;

    :global .field {
      padding: 2px 4px !important;
      border: none !important;
      border-radius: 4px;
      @include themify($themes) {
        background-color: themed('mainBg');
      }

      input {
        @include text-overflow;
      }
    }

    :global .rightIcon {
      margin-left: 8px !important;
      i, svg {
        width: 8px;
        height: 8px;
      }
    }
  }

  &__result {
    z-index: 99999;
    position: absolute;
    border-radius: 6px;
    top: 44px;
    left: 0;
    right: 0;
    padding: 12px 16px;
    @include themify($themes) {
      background-color: themed('mainBg');
    }

    &__header {
      margin-bottom: 12px;
    }

    &__content {
      max-height: 248px;
      overflow: auto;
    }

    &__item {
      display: flex;
      align-items: center;
      cursor: pointer;
      @include text-overflow;
      &:not(:first-child) {
        padding-top: 4px;
      }
      &:not(:last-child) {
        padding-bottom: 4px;
        @include themify($themes) {
          border-bottom: 1px solid themed('gray50');
        }
      }

      .info {
        display: flex;
        flex-direction: column;
        width: calc(100% - 32px);
        margin-left: 8px;
        span {
          @include text-overflow;
          @include themify($themes) {
            color: themed('textColor') !important;
          }
        }
      }

      .template {
        display: flex;
        align-items: center;
        span {
          @include themify($themes) {
            color: themed('gray1') !important;
          }
        }

        :global .badge {
          border-width: 1px;
          box-sizing: border-box;
          margin-right: 4px;
        }
      }
    }
  }
}
