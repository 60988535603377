
        @import "@control-front-end/common/styles/variables";
        @import "@control-front-end/common/styles/mixins";
        
.f__errors {
  position: fixed;
  top: 80px;
  left: 292px;
  right: 32px;
  padding: 8px 12px;
  z-index: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 4px;
  @include themify($themes) {
    background-color: themed('redBg');
    border: 1px solid themed('redBorder');
  }

  & > div:first-child {
    width: calc(100% - 52px);
    display: flex;
    align-items: center;
  }

  &__close {
    cursor: pointer;
    padding: 4px;
    margin-right: 10px;
    :global .fill {
      @include themify($themes) {
        fill: themed('textColor');
      }
    }
  }

  &__divider {
    padding: 0 16px;
  }

  &__controls {
    &__icon {
      cursor: pointer;
      padding: 4px;

      &.disabled {
        cursor: default;
      }

      &:first-child {
        transform: rotate(90deg);
      }

      &:last-child {
        transform: rotate(-90deg);
      }
    }
  }

  &__field__name {
    @include themify($themes) {
      color: themed('redBorder');
    }
    @include text-overflow();
  }

  & > div:last-child {
    display: flex;
    align-items: center;

    :global .fill {
      @include themify($themes) {
        fill: themed('textColor');
      }
    }
  }
}

@include respond-to(mobile) {
  .f__errors {
    left: 24px;
  }
}
