
        @import "@control-front-end/common/styles/variables";
        @import "@control-front-end/common/styles/mixins";
        
.image {
  box-sizing: border-box;

  &__view {
    box-sizing: border-box;

    & img {
      max-width: 300px;
      max-height: 300px;
    }
  }
}
