
        @import "@control-front-end/common/styles/variables";
        @import "@control-front-end/common/styles/mixins";
        
.transactionStatus {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;

  &__icon {
    display: inline-flex;

    &.minLimitChanged,
    &.maxLimitChanged,
    &.authorized {
      :global .fill {
        @include themify($themes) {
          fill: themed('primaryColor') !important;
        }
      }

      &.open {
        :global .fill {
          fill: #f8ab03 !important;
        }
      }
    }

    &.corrected {
      :global .fill {
        fill: #f8ab03 !important;
      }
    }

    &.declined,
    &.canceled,
    &.reversed {
      :global .fill {
        @include themify($themes) {
          fill: themed('red1') !important;
        }
      }
    }

    &.completed {
      :global .stroke {
        stroke-width: 2;
        transform: scale(1.3, 1.3) translate(-2px, -3px);
        @include themify($themes) {
          stroke: themed('green1') !important;
        }
      }
    }

    &.removed {
      :global .fill {
        @include themify($themes) {
          fill: themed('textColor') !important;
        }
      }
    }
  }
}
.withBg {
  padding: 6px 8px;
  gap: 4px;
  border-radius: 4px;
  &.completed {
    background-color: #c9f5cd;
    color: #3c783b;
  }
  &.declined,
  &.canceled,
  &.reversed {
    @include themify($themes) {
      background-color: themed('redBg');
      color: themed('redBorder');
    }
    :global .fill {
      @include themify($themes) {
        fill: themed('redBorder') !important;
      }
    }
  }
  &.minLimitChanged,
  &.maxLimitChanged,
  &.authorized {
    @include themify($themes) {
      background-color: themed('blueBg');
      color: themed('primaryColor');
    }
  }
}
