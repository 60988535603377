
        @import "@control-front-end/common/styles/variables";
        @import "@control-front-end/common/styles/mixins";
        
.event {
  &__header {
    z-index: 2;
    display: flex;
    align-items: center;
    position: relative;
    justify-content: space-between;
    padding: 10px 16px 10px 16px;
    box-sizing: border-box;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    @include themify($themes) {
      background-color: themed('cardHeader');
    }

    &__avatar {
      margin-right: 12px;
      flex: 0 0 auto;
      @include themify($themes) {
        border: 1px solid themed('mainBg');
      }
    }

    &__title {
      width: 100%;
      border: 0;
      height: 19px;
      background-color: inherit;
      font-weight: 600;
      @include text-overflow;

      &::placeholder {
        font-weight: normal;
        @include themify($themes) {
          color: themed('gray1') !important;
        }
      }

      &.error {
        &::placeholder {
          @include themify($themes) {
            color: themed('red1') !important;
          }
        }
      }

      @include themify($themes) {
        color: themed('editedBadgeTextColor');
        -webkit-text-fill-color: themed('editedBadgeTextColor');
      }
    }
  }
}

@include respond-to(mobile) {
  .event__header {
    padding: 8px 16px !important;
    @include themify($themes) {
      background-color: themed('workSpaceBg') !important;
      border-bottom: 1px solid themed('descrBorder') !important;
    }
  }

  .event__header__title {
    @include themify($themes) {
      color: themed('textColor') !important;
      -webkit-text-fill-color: themed('textColor') !important;
    }
  }
}
