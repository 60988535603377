
        @import "@control-front-end/common/styles/variables";
        @import "@control-front-end/common/styles/mixins";
        
.tf {
  flex-wrap: wrap;
  position: relative;
  z-index: 10;

  &__wrap {
    position: relative;
  }

  &__type {
    display: flex;
    align-items: center;
    border-radius: 4px;
    padding: 8px;
    cursor: pointer;
    @include themify($themes) {
      border: 1px solid themed('blueBg');
      background-color: themed('blueBg');
      color: themed('primaryColor');
    }

    &__value {
      cursor: pointer;
      max-width: 200px;
      @include text-overflow();
    }
  }

  &__popup {
    position: absolute;
    right: 0;
    top: 40px;
    z-index: 11;

    & > div {
      max-height: unset;
    }
  }
}

@include respond-to(mobile) {
  .tf {
    padding: 0 16px;
  }
}
