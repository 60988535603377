
        @import "@control-front-end/common/styles/variables";
        @import "@control-front-end/common/styles/mixins";
        
.row {
  display: table;
  table-layout: fixed;
  width: 100%;

  &__item {
    display: table-cell;
    vertical-align: top;
    padding-right: 12px;

    &:last-child{
      padding-right: 0;
    }
  }

  &.hidden {
    display: none;
  }
}
