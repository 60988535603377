
        @import "@control-front-end/common/styles/variables";
        @import "@control-front-end/common/styles/mixins";
        
.balance {
  box-sizing: border-box;
  display: flex;

  &__icon {
    padding: 3px;
    border-radius: 50%;
    display: inline-flex;

    &__inner {
      display: flex;
      border-radius: 50%;
      align-items: center;
      justify-content: center;
      padding: 1px;

      &.total {
        @include themify($themes) {
          background-color: themed('workSpaceBg');
        }
      }

      &.credit {
        transform: rotate(-90deg);
        @include themify($themes) {
          background-color: themed('blueBg') !important;
        }

        path {
          @include themify($themes) {
            fill: themed('primaryColor') !important;
          }
        }
      }

      &.debit {
        transform: rotate(+90deg);
        @include themify($themes) {
          background-color: themed('redBg');
        }

        path {
          @include themify($themes) {
            fill: themed('redBorder') !important;
          }
        }
      }
    }
  }
}

.table {
  overflow-y: visible;
  overflow-x: hidden;

  & :global .scTableRow {
    padding: 0 !important;
    @include themify($themes) {
      background-color: themed('workSpaceBg');
    }

    .scTableCell:first-child {
      font-weight: 600;
    }
  }

  & :global .scTableRowDivider {
    margin: 0 !important;
  }

  &__cell {
    width: 25% !important;
    word-break: break-all !important;
    margin-right: 4px;

    &.balance {
      display: flex;
      align-items: center;

      & > div {
        display: flex;
        align-items: center;
        margin-right: 8px;

        & > i {
          margin-right: 4px;
        }
      }
    }
  }
}
