
        @import "@control-front-end/common/styles/variables";
        @import "@control-front-end/common/styles/mixins";
        
.modal {
  height: 70%;
  min-height: 486px;
  max-height: 90% !important;
}

.content {
  padding: 20px 0 0 !important;
  margin-bottom: 20px;
  box-sizing: border-box;
  height: 100%;
  overflow: hidden;
  @include themify($themes) {
    background-color: themed('workSpaceBg');
  }
}

@include respond-to(mobile) {
  .content {
    padding: 16px 0 0 !important;
    margin-bottom: 16px;
  }
}
