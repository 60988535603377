
        @import "@control-front-end/common/styles/variables";
        @import "@control-front-end/common/styles/mixins";
        
.chart {
  display: flex;
  flex-direction: column;
  overflow: hidden !important;
  width: 100%;
  height: 100%;
}
