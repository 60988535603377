
        @import "@control-front-end/common/styles/variables";
        @import "@control-front-end/common/styles/mixins";
        
.activeCell,
.activeCellbg {
  position: absolute;
  @include themify($themes) {
    border: 1px solid themed('primaryColor');
  }
  box-sizing: border-box;
  pointer-events: none;
  width: 100%;
  height: 100%;
}

.activeCellbg {
  opacity: 0.1;
  @include themify($themes) {
    background-color: themed('primaryColor');
  }
}
