
        @import "@control-front-end/common/styles/variables";
        @import "@control-front-end/common/styles/mixins";
        
.content {
  margin-bottom: 0 !important;
}

.row {
  &__container {
    overflow: auto;
    padding-bottom: 12px;
  }
  &__field {
    padding-top: 12px !important;
    padding-bottom: 16px !important;
  }
  &__label {
    margin-bottom: 16px;
    position: relative;
    &__disabled {
      @include themify($themes) {
        color: #6E7F96 !important;
      }
    }
    &__helper {
      display: block;
      position: absolute;
    }
  }
  &__check {
    margin-bottom: 14px;
    label {
      margin-left: 12px;
    }
    &__item {
      &__label {
        margin-bottom: 2px;
        @include themify($themes) {
          color: themed('textColor') !important;
        }
      }
      span {
        display: block;
      }
    }
  }
}
