
        @import "@control-front-end/common/styles/variables";
        @import "@control-front-end/common/styles/mixins";
        
.autocomplete {
  :global .field {
    max-height: 60px;
    overflow-y: auto;
  }
}

.participantsSelector {
  width: 500px;
}