
        @import "@control-front-end/common/styles/variables";
        @import "@control-front-end/common/styles/mixins";
        
.func {
  position: absolute;
  display: flex;
  padding: 12px;
  gap: 4px;
  top: 20px;
  right: -180px;
  border-radius: 0 10px 10px 10px;
  @include themify($themes) {
    border: 1px solid themed('gray50');
    background-color: themed('mainBg');
  }

  &__chip {
    cursor: pointer;
    transition: .3s;
    @include themify($themes) {
      border-color: themed('gray50');
      background-color: themed('gray50');
    }

    &:hover {
      @include themify($themes) {
        border-color: themed('secondaryColor');
        background-color: themed('secondaryColor');
        color: themed('primaryColor');
      }
    }
  }
}