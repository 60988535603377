
        @import "@control-front-end/common/styles/variables";
        @import "@control-front-end/common/styles/mixins";
        
.historyItem {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 12px 20px;
  gap: 8px;
  box-sizing: border-box;

  &:last-child {
    border-bottom: none;
  }

  &__label.compact > span {
    font-size: 12px !important;
  }

  &__collapse {
    display: flex;
    padding: 4px;
    width: fit-content;
    height: fit-content;
    cursor: pointer;

    i {
      transform: rotate(90deg);
      @include btn-icon-hover();
    }

    &.active > i {
      transform: rotate(-90deg);
    }
  }

  &__info {
    display: flex;
    gap: 12px;

    &__field {
      display: flex;
      gap: 8px;
    }
  }
}
