
        @import "@control-front-end/common/styles/variables";
        @import "@control-front-end/common/styles/mixins";
        
.layerItem {
  cursor: pointer;
  transition: .3s;
  padding: 8px 0;
  box-sizing: border-box;

  &:hover {
    @include themify($themes) {
      background-color: themed('primaryBgHover') !important;
    }
  }
}
