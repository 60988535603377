
        @import "@control-front-end/common/styles/variables";
        @import "@control-front-end/common/styles/mixins";
        
.content {
  margin-bottom: 0 !important;

  & > div {
    display: flex;
    flex-wrap: wrap;

    & > div:nth-child(even) {
      width: calc(60% - 16px);
      margin-left: 16px;
    }

    & > div:nth-child(odd) {
      width: 40%;
    }
  }
}
