
        @import "@control-front-end/common/styles/variables";
        @import "@control-front-end/common/styles/mixins";
        
.modal__content {
  padding-bottom: 0 !important;
}

.content {
  margin-bottom: 44px;
}