
        @import "@control-front-end/common/styles/variables";
        @import "@control-front-end/common/styles/mixins";
        
.select {
  box-sizing: border-box;

  &__title {
    box-sizing: border-box;

    & > div:first-child {
      padding-bottom: 12px;
    }
  }
}
