
        @import "@control-front-end/common/styles/variables";
        @import "@control-front-end/common/styles/mixins";
        
.select__options {
  box-sizing: border-box;

  &__wrap {
    margin-top: -8px;
    width: 100%;

    &__button {
      margin-left: calc(25% + 40px);
      transform: rotate(270deg);

      &.listWrap {
        transform: rotate(90deg);
      }
    }
  }

  &__add {
    margin-left: 12px;
    display: inline-block;
    cursor: pointer;
    @include themify($themes) {
      color: themed('primaryColor');
    }

    &:hover {
      @include themify($themes) {
        color: themed('primaryColorHover');
      }
    }
  }
}

.select__item {
  display: flex;
  align-items: center;

  &__radio {
    width: 12px !important;
    height: 12px;
    top: -11px !important;
    border-radius: 50%;
    @include themify($themes) {
      border: 2px solid themed('disabled');
    }
  }

  & > div:first-child {
    text-align: right;
    width: 24px;
    margin-right: 12px;
    position: relative;
    top: -12px
  }

  & > div:not(:first-child) {
    padding-top: 0;
  }

  & > div:nth-child(2) {
    width: 50%;
  }

  & > div:nth-child(3) {
    margin-left: 16px;
    padding-bottom: 20px;
    width: 40px;
  }

  & :global .rightIcon i {
    cursor: pointer;
    width: 12px;
    height: 12px;
    position: relative;
  }
}


