
        @import "@control-front-end/common/styles/variables";
        @import "@control-front-end/common/styles/mixins";
        
.radio {
  margin-bottom: 20px;
  position: relative;

  :global .horizontal {
    overflow-x: visible !important;
  }

  :global .error {
    position: absolute;
    top: 100%;
    margin-top: 4px;
    line-height: 0;
  }
}
