
        @import "@control-front-end/common/styles/variables";
        @import "@control-front-end/common/styles/mixins";
        
.tf {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  gap: 8px;
  box-sizing: border-box;
  flex-wrap: wrap;
  z-index: 10;

  &__wrap {
    box-sizing: border-box;
    position: relative;
  }

  &__type {
    display: flex;
    align-items: center;
    border-radius: 4px;
    padding: 8px;
    cursor: pointer;
    @include themify($themes) {
      border: 1px solid themed('blueBg');
      background-color: themed('blueBg');
      color: themed('primaryColor');
    }

    &__title {
      margin-right: 4px;
    }

    &__value {
      margin-right: 8px;
      cursor: pointer;
      max-width: 200px;
      @include text-overflow();
    }
  }

  &__search {
    display: flex;
    align-items: center;
    width: 100%;
    gap: 8px;

    &__field {
      width: 240px;
      padding: 0 !important;
    }

    &__type {
      border-radius: 4px;
      width: 148px !important;

      & > div {
        padding: 0 !important;
        border: none;
        margin-top: 0 !important;
      }

      :global .field {
        @include themify($themes) {
          background-color: themed('gray50');
          border-color: themed('gray50') !important;
        }

        input {
          font-weight: 600;
        }
      }
    }
  }

  &__incomeType {
    width: fit-content;
    max-width: 132px !important;
    border-radius: 4px;
    @include themify($themes) {
      border: 1px solid themed('blueBg');
    }

    & > div {
      padding: 0 !important;
      border: none;
      margin-top: 0 !important;
    }

    :global .field {
      @include themify($themes) {
        background-color: themed('blueBg');
        border-color: themed('blueBg') !important;
      }

      input {
        font-weight: 600;
        @include themify($themes) {
          color: themed('primaryColor') !important;
        }
      }
    }

    :global .rightIcon {
      margin-left: 8px !important;
    }
  }

  &__popup {
    position: absolute;
    left: 0;
    top: 40px;
    z-index: 11;

    & > div {
      max-height: unset;
    }
  }

  &__export {
    display: flex;

    &__divider {
      height: 36px;
      width: 1px;
      margin: 0 14px 0 8px;
      @include themify($themes) {
        background-color: themed('gray50');
      }
    }

    &__switch {
      padding: 4px;
      border-radius: 4px;
      @include themify($themes) {
        background-color: themed('workSpaceBg');
      }

      &__button {
        height: 28px !important;
      }
    }

    &__progress {
      display: flex;
      align-items: center;

      &__bar {
        margin-left: 8px !important;
      }
    }

    &__button {
      margin-left: 8px;
      height: 36px !important;
    }
  }
}

@include respond-to(mobile) {
  .tf {
    padding: 0 16px;
  }
}
