
        @import "@control-front-end/common/styles/variables";
        @import "@control-front-end/common/styles/mixins";
        
.modal {
  height: 90% !important;
  max-height: 90% !important;
}

.calls {
  overflow: hidden;

  &__filters {
    position: sticky;
    top: 0;
    z-index: 4;
    padding-bottom: 20px;
    @include themify($themes) {
      background-color: themed('workSpaceBg');
    }
  }

  &__list {
    display: flex;
    flex-direction: column;
    gap: 12px;
  }
}
