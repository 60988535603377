
        @import "@control-front-end/common/styles/variables";
        @import "@control-front-end/common/styles/mixins";
        
.scp {
  display: flex;
  flex-direction: column;
  max-height: 50vh;
  box-shadow: 1px 1px 8px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  box-sizing: border-box;
  position: absolute;
  z-index: 999;
  @include themify($themes) {
    background-color: themed('mainBg');
  }

  &.wrapped {
    padding: 12px;
    width: 400px;

    .scp__header {
      display: flex;
    }
  }

  &__header {
    display: none;
    justify-content: space-between;
    margin-bottom: 12px;

    &__title {
      display: flex;

      & > * {
        margin-right: 8px;
      }
    }

    &__actions {
      display: flex;
      gap: 8px;

      & > * {
        cursor: pointer;
        @include btn-icon-hover();
      }
    }
  }

  &__content {
    display: flex;
    box-sizing: border-box;
    border-radius: 4px;
    width: 100%;
    height: 100%;
    overflow: hidden;
    @include themify($themes) {
      border: 1px solid themed('descrBorder');
    }
  }
}
