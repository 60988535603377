
        @import "@control-front-end/common/styles/variables";
        @import "@control-front-end/common/styles/mixins";
        
.content {
  padding: 20px 0 !important;

  &__balances {
    @include themify($themes) {
      border-top: 16px solid themed('gray50');
      border-bottom: 16px solid themed('gray50');
    }
  }

  &__header {
    display: flex;
    justify-content: flex-start;

    &__details {
      display: flex;
      flex-direction: column;
      gap: 20px;
      padding: 0 16px;
      margin-bottom: 20px;

      & > div {
        display: flex;
        align-items: center;
        margin-top: -5px !important;

        & > div {
          margin-left: 8px !important;
          @include themify($themes) {
            background-color: themed('mainBg');
          }
        }
      }

      div:nth-child(3) {
        margin-top: -15px !important;
      }

      &__title {
        @include themify($themes) {
          color: themed('gray2');
        }
      }
    }

    &__limits {
      display: flex;
      flex-direction: column;
      gap: 20px;
      padding: 0 16px;
      margin-bottom: 20px;
      margin-left: -10px;

      & > div {
        display: flex;
        align-items: center;

        & > div {
          margin-left: 8px !important;
        }
      }

      &__title {
        @include themify($themes) {
          color: themed('gray2');
        }
      }
    }
  }
}

.loader {
  display: flex;
  height: 112px;
  width: 100%;
  justify-content: center;
  align-items: center;
}
