
        @import "@control-front-end/common/styles/variables";
        @import "@control-front-end/common/styles/mixins";
        
.chip {
  height: 16px !important;
  padding: 0 4px !important;
  @include themify($themes) {
    color: themed('gray2');
  }
}

.userAvatar {
  flex: 0 0 auto;
}

.scriptIcon {
  position: relative;
  @include themify($themes) {
    border: 1px solid themed('workSpaceBorder');
  }
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;
  width: 40px;
  height: 32px;
}

.scriptIcon.empty {
  @include themify($themes) {
    border: 1px dashed themed('gray2');
  }
}

.barIconContent {
  min-width: 348px;
}

.pictureUpload {
  width: 48px;
  display: flex;
  overflow: hidden;
  justify-content: center !important;
  align-items: center !important;
  height: 48px;
  border-radius: 8px;
  @include themify($themes) {
    border: 1px solid themed('descrBorder');
  }
}

.pictureUpload > span {
  display: none;
}

.loaded i {
  display: none;
}

.editIcon {
  @include themify($themes) {
    background-color: themed(disabled);
  }
  display: flex;
  justify-content: center;
  align-items: center;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  position: absolute;
  top: 0;
  right: 0;
  transform: translate(50%, -50%);
}
