
        @import "@control-front-end/common/styles/variables";
        @import "@control-front-end/common/styles/mixins";
        
.link {
  box-sizing: border-box;
  display: block;
  margin-bottom: 20px;

  &.left {
    text-align: left;
  }

  &.right {
    text-align: right;
  }

  &.center {
    text-align: center;
  }

  &.disabled {
    pointer-events: none;
    @include themify($themes) {
      color: themed('disabled');
    }
  }
}

