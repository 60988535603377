
        @import "@control-front-end/common/styles/variables";
        @import "@control-front-end/common/styles/mixins";
        
.cm__item {
  cursor: pointer;
  padding: 5px 12px;
  display: flex;
  position: relative;

  &.disabled {
    opacity: 0.5;
    cursor: default;
  }

  &.hidden {
    display: none;
  }

  &:hover {
    @include themify($themes) {
      background-color: themed('primary60Color');
    }
  }

  &:first-child {
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
  }

  &:last-child {
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
  }

  &.bordered {
    @include themify($themes) {
      border-top: 1px solid themed('gray50');
    }
  }

  &__icon {
    width: 20px;
    margin-right: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__label {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: calc(100% - 20px);

    span:nth-child(1) {
      @include text-overflow();
    }

    span:nth-child(2) {
      margin-left: 12px;
      @include themify($themes) {
        color: themed('gray35') !important;
      }
    }
  }

  &__dd {
    transform: rotate(-90deg);
  }

  :global .nested {
    position: absolute;
    top: 0;
    left: 220px;
    padding: 0 4px;
    height: 32px;
  }
}

@media screen and (max-height: 768px) {
  .cm__item {
    padding: 3px 12px;
  }
}
