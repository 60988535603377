
        @import "@control-front-end/common/styles/variables";
        @import "@control-front-end/common/styles/mixins";
        
.range {
  display: flex;
  position: relative;
  border-color: transparent !important;

  &__menu {
    z-index: 3;
    width: 210px;

    & > div {
      box-shadow: 0 4px 8px rgba(57, 63, 70, 0.1);
      border-radius: 8px;
      @include themify($themes) {
        border: 1px solid themed('gray50');
      }
    }

    &__item {
      i {
        width: 12px;
        height: 12px;
        transform: rotate(180deg);

        svg {
          width: 12px;
          height: 12px;
        }
      }

      &.active,
      &:hover {
        @include themify($themes) {
          background-color: themed('primary60Color') !important;
          :global .fill {
            fill: themed('primaryColor') !important;
          }
          span {
            color: themed('primaryColor') !important;
          }
        }
      }
    }
  }

  &__calendar {
    border-radius: 8px;
  }
}

.fullWidth {
  width: 100% !important;
}

.rangeBtn {
  &:hover {
    @include themify($themes) {
      border-color: themed('blueBg');
    }
  }

  &.main {
    flex: 1 1 auto;
    justify-content: flex-start;

    &.compact {
      span {
        font-size: 12px;
      }
    }
  }

  &.left {
    &.compact {
      padding: 0 0 0 8px;
    }
  }

  &.right {
    &.compact  {
      padding: 0 8px 0 0;
    }
  }
}
