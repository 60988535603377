
        @import "@control-front-end/common/styles/variables";
        @import "@control-front-end/common/styles/mixins";
        
.select {
  width: 100%;
}

.emojiSelect {
  width: 70px;
}

.iconLabel {
  width: 126px;
}

.fieldLabel {
  flex-grow: 1;
}

.fieldsHeading {
  margin-bottom: 8px;
}