
        @import "@control-front-end/common/styles/variables";
        @import "@control-front-end/common/styles/mixins";
        
.groupIcon {background-image: url('./images/group.svg');}
.actorIcon {background-image: url('./images/actor.svg');}
.graphIcon {background-image: url('./images/graph.svg');}
.accountIcon {background-image: url('./images/account.svg');}
.eventsIcon {background-image: url('./images/events.svg');}
.starLayerIcon {background-image: url('./images/star_layer.svg');}
.plusIcon {background-image: url('./images/plusIcon.svg');}

.documentation {
  background-image: url('./images/documentation.png');
  background-size: cover;
  background-repeat: no-repeat;
}

.menuNode {
  border-radius: 50%;
  @include themify($themes) {
    border: 1px solid themed('primaryColor');
  }
  box-sizing: border-box;
}

.dashedBorder {
  @include themify($themes) {
    border: 1px dashed themed('primaryColor');
  }
}

.innerTitle > div {
  left: -1px;
}

.innerTitle > div > div > span {
  background-color: transparent !important;
}

.plusAdornment::before {
  content: "";
  position: absolute;
  bottom: -8px;
  left: calc(50% - 8px);
  border-radius: 50%;
  @include themify($themes) {
    background-color: themed('primaryColor');
  }
  background-image: url('./images/plus.svg');
  background-size: cover;
  background-repeat: no-repeat;
  width: 16px;
  height: 16px;
}