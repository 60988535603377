
        @import "@control-front-end/common/styles/variables";
        @import "@control-front-end/common/styles/mixins";
        
.calendar{
  :global .rightIcon i,
  :global .rightIcon i svg {
    width: 12px;
    height: 12px;
    cursor: pointer;
  }
}
