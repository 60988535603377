
        @import "@control-front-end/common/styles/variables";
        @import "@control-front-end/common/styles/mixins";
        
.event {
  &__controls {
    display: flex;
    justify-content: space-between;
    height: 64px;
    width: 100%;
    position: absolute;
    bottom: 0;
    box-sizing: border-box;
    text-align: left;
    flex: 0 0 auto;
    @include themify($themes) {
      border-top: 1px solid themed('gray50');
    }

    &__upload {
      span {
        display: none;
      }
    }

    &__icons {
      display: flex;
      align-items: center;
      margin: 0 24px;
    }

    &__btn {
      cursor: pointer;
      margin-right: 20px;

      :global .fill {
        @include themify($themes) {
          fill: themed('primaryColor') !important;
        }
      }

      &.hidden {
        display: none;
      }
    }

    &__popup {
      position: absolute;
      bottom: 24px;
      left: 0;
      z-index: 1001;

      &__wrap {
        position: relative;
      }
    }
  }

  &__upload {
    width: 100%;
    height: 100%;

    & > div {
      height: 100%;
    }

    i,
    & > div > span {
      display: none;
    }
  }
}

@include respond-to(mobile) {
  .event__controls__popup__wrap {
    position: static;
  }
  .event__controls__popup {
    bottom: 52px;
    left: calc(50vw - 156px);
  }

  .event__controls__icons {
    margin: 0 16px;
  }
}

