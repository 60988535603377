
        @import "@control-front-end/common/styles/variables";
        @import "@control-front-end/common/styles/mixins";
        
.params {
  margin-top: 16px;
  @include themify($themes) {
    background-color: themed('workSpaceBg');
  }

  &__values {
    padding-top: 16px;
  }

  &__add {
    display: flex;
    margin-top: 8px;
    cursor: pointer;

    i {
      margin-right: 10px;
    }

    span {
      font-weight: 600;
      margin-top: -1px;
    }
  }

  &__editor {
    margin-top: 12px;
    height: 300px;
    width: 518px;

    // TODO remove after extra params editor fix
    :global .jsoneditor-repair,
    :global .jsoneditor-undo,
    :global .jsoneditor-redo{
      display: none !important;
    }
  }
}
