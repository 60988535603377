
        @import "@control-front-end/common/styles/variables";
        @import "@control-front-end/common/styles/mixins";
        
.image {
  margin-bottom: 20px;
  display: flex;

  & img {
    max-width: 100%;
    max-height: 100%;
    text-overflow: ellipsis;
  }

  &.left {
    justify-content: flex-start;
  }

  &.center {
    justify-content: center;
  }

  &.right {
    justify-content: flex-end;
  }

  &.hidden {
    display: none;
  }

  &.disabled {
    opacity: 0.4;
    cursor: default;
  }
}
