
        @import "@control-front-end/common/styles/variables";
        @import "@control-front-end/common/styles/mixins";
        
.userAccess {
  &__controls {
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 50%;
    flex-shrink: 0;
    position: relative;
    box-sizing: border-box;

    &.allPrivs {
      width: 60%;
    }

    & > div:not(:last-child) {
      margin-bottom: 0 !important;

      label {
        display: none;
      }
    }

    & i {
      cursor: pointer;
    }

    &__close {
      position: absolute !important;
      top: 0;
      right: 0;
      padding-right: 4px;

      &:not(.disabled):hover {
        :global .fill {
          @include themify($themes) {
            fill: themed('primaryColor');
          }
        }
      }

      &.disabled > i {
        cursor: default !important;
      }
    }

    &__labels {
      width: 50%;
      display: flex;
      align-items: center;
      justify-content: space-around;

      &.allPrivs {
        width: 60%;
      }

      span {
        width: 52px;
        text-align: center;
        @include themify($themes) {
          color: themed('gray1');
        }
      }
    }
  }

  &__item {
    display: flex;
    align-items: center;
    justify-content: left;
    padding: 8px 0;

    &.bulk {
      padding: 0 8px 12px 0 !important;
      scrollbar-gutter: stable;
    }

    &__info {
      width: 50%;

      span {
        height: fit-content;
      }

      &.allPrivs {
        width: 40%;
      }
    }

    &__avatar {
      flex: 0 0 auto;
      box-sizing: border-box;
      height: 28px !important;
      width: 28px !important;

      &__box {
        @include themify($themes) {
          border: 1px solid themed('descrBorder');
        }
        border-radius: 50%;
        padding: 1px;
      }

      :global .fill {
        @include themify($themes) {
          fill: themed('primaryColor');
        }
      }
    }
  }

  &__chip {
    height: 16px !important;
    padding: 0 4px !important;
    @include themify($themes) {
      background-color: themed('workSpaceBorder');
      color: themed('gray2');
    }
  }

  &__selected {
    padding: 0 20px !important;
    position: relative;
    height: 100%;
    overflow: hidden;

    &__header {
      display: flex;
      justify-content: space-between;
      margin: 16px 0;
      padding-right: 8px;

      &__count {
        display: flex;
        span:last-child {
          margin-left: 12px;
          @include themify($themes) {
            color: themed('gray2');
          }
        }
      }
    }

    &__content {
      height: calc(100% - 120px);
      overflow: auto;
      scrollbar-gutter: stable;

      &.customScroll {
        padding-right: 8px;

        &::-webkit-scrollbar {
          display: none;
        }

        &:hover {
          @include show-scroll-bar();
        }
      }
    }
  }

  &__select {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex-grow: 1;

    &__field {
      width: 104px;
      padding: 0 !important;

      & :global .rightIcon {
        cursor: pointer;
        margin-left: 8px !important;
        @include no-touch-select();

        i, svg {
          width: 8px;
          height: 8px;
        }
      }
    }

    :global .focus {
      i {
        transition: transform 0.3s;
        transform: rotate(-180deg);
      }

      :global .fill {
        @include themify($themes) {
          fill: themed('primaryColor') !important;
        }
      }
    }

    &__menu {
      width: 104px;
      height: fit-content;
      box-sizing: border-box;
      padding: 12px;
      box-shadow: 0 3px 12px rgba(57, 63, 72, 0.08);
      border-radius: 4px;
      @include themify($themes) {
        border: 1px solid themed('gray50');
        background-color: themed('mainBg');
      }
    }

    &__btn {
      width: 100%;
    }
  }
}

@include respond-to(desktop) {
  .userAccess__controls {
    span {
      display: none;
    }
  }

  .userAccess__select,
  .userAccess__controls__labels.compact {
    display: none;
  }
}

@include respond-to(tablet) {
  .userAccess__controls {
    span {
      display: none;
    }
  }

  .userAccess__select,
  .userAccess__controls__labels.compact {
    display: none;
  }
}

@include respond-to(mobile) {
  .userAccess__selected {
    padding: 0 12px !important;
  }

  .userAccess__selected__header__count {
    width: 40%;

    & > span:last-child {
      display: none;
    }
  }

  .userAccess__selected__content {
    margin-bottom: 12px;
  }

  .userAccess__select {
    flex-grow: 0;
  }

  .userAccess__item {
    position: relative !important;
    display: flex;
    align-items: center;

    &__info {
      flex-grow: 1;
    }
  }

  .userAccess__item.bulk > div:first-child {
    display: none;
  }

  .userAccess__controls {
    width: fit-content !important;

    & > div:not(:last-child) {
      display: none;
    }
  }

  .userAccess__controls__close {
    position: relative !important;
    padding-right: 0;
    padding-left: 4px;
  }

  .userAccess__controls__labels.full {
    display: none;
  }

  .userAccess__controls__labels.compact {
    justify-content: flex-end;
  }

  .userAccess__controls__labels span {
    width: 100% !important;
    white-space: pre;
    text-align: end;
    margin-right: 32px;
  }

  .userAccess__item.bulk {
    display: none;
  }
}
