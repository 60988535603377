
        @import "@control-front-end/common/styles/variables";
        @import "@control-front-end/common/styles/mixins";
        
.accessDiff {
  display: flex;

  &__item {
    width: 100%;

    & > span {
      width: 116px;
    }

    div[class*="-avatar-"] {
      width: 20px !important;
      height: 20px !important;
    }

    &__list {
      margin-top: -2px;
    }

    &__nick.strikethrough {
      text-decoration: line-through;
    }

    &__arrow {
      transform: rotate(180deg);
    }

    &__privs {
      padding: 4px;
      border-radius: 4px;
      @include themify($themes) {
        background-color: themed('gray20') !important;
      }

      .view {
        :global .fill {
          @include themify($themes) {
            fill: themed('gray35') !important;
          }
        }
      }

      .remove {
        :global .fill {
          @include themify($themes) {
            fill: themed('redBorder') !important;
          }
        }
      }

      .modify {
        :global .fill {
          @include themify($themes) {
            fill: themed('primaryColor') !important;
          }
        }
      }

      .sign {
        :global .fill {
          @include themify($themes) {
            fill: themed('yellow1') !important;
          }
        }
      }

      .execute {
        :global .stroke {
          stroke-width: 2;
          @include themify($themes) {
            stroke: themed('reactionDoneColor') !important;
          }
        }
      }

      &.disabled {
        pointer-events: none;
        :global .fill {
          @include themify($themes) {
            fill: themed('disabled') !important;
          }
        }
        :global .stroke {
          @include themify($themes) {
            stroke: themed('disabled') !important;
          }
        }
      }
    }
  }
}
