
        @import "@control-front-end/common/styles/variables";
        @import "@control-front-end/common/styles/mixins";
        
@import url('https://fonts.googleapis.com/css?family=Open+Sans:400,400i,600,700,800&subset=cyrillic');
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+Mono:wght@400&display=swap');

:root {
  --scrollbar-width: 8px;
  --scrollbar-height: 8px;
  --scrollbar-thumb-bg: linear-gradient(13deg, #c7c7c7 14%, #c7c7c7 64%);
  --scrollbar-track-bg: #ffffff;
  --scrollbar-thumb-border-radius: 5px;
  --scrollbar-track-box-shadow: inset 7px 10px 12px #f0f0f0;
}

:global .root,
:global .theme {
  height: 100%;
  //fix for correct z-index in case of several modals
  :global .modal__overlay {
    z-index: 10001 !important;
  }
}

::-webkit-scrollbar {
  width: var(--scrollbar-width);
  height: var(--scrollbar-height);
}

::-webkit-scrollbar-thumb {
  background: var(--scrollbar-thumb-bg);
  border-radius: var(--scrollbar-thumb-border-radius);
}

::-webkit-scrollbar-thumb:hover {
  background: var(--scrollbar-thumb-bg);
}

::-webkit-scrollbar-track {
  background: var(--scrollbar-track-bg);
  border-radius: var(--scrollbar-thumb-border-radius);
  box-shadow: var(--scrollbar-track-box-shadow);
}

*[contenteditable='true'] {
  -webkit-user-select: text;
}

//smart chips & event chips
mark[data-chip],
a[data-chip] {
  cursor: pointer;
  padding: 0 4px !important;
  border-radius: 4px !important;
  @include themify($themes) {
    border: 1px solid themed('blueBg') !important;
    background-color: themed('blueBg') !important;
  }
  font-weight: inherit;
  font-size: inherit;
  position: relative;
  @include themify($themes) {
    color: themed('primaryColor');
  }

  &:before {
    display: inline-block;
    content: '';
    background-size: contain;
    width: 12px;
    height: 12px;
    margin-right: 4px;
    margin-bottom: 4px;
    vertical-align: middle;
  }
}

mark[data-chip='user']:before {
  background-image: url('./../../public/static/user.svg');
}

mark[data-chip='chat']:before {
  background-image: url('./../../public/static/actor.svg');
}

a[data-chip='file']:before {
  background-image: url('./../../public/static/attach.svg');
}

a[data-chip='actor']:before {
  background-image: url('./../../public/static/actor.svg');
}

a[data-chip='graph']:before {
  background-image: url('./../../public/static/graph.svg');
}

a[data-chip='graphLayer']:before {
  background-image: url('./../../public/static/layer.svg');
}

a[data-chip='event']:before {
  background-image: url('./../../public/static/stream.svg');
}

a[data-chip='script']:before {
  background-image: url('./../../public/static/script.svg');
}

.main {
  height: 100%;
  @include themify($themes) {
    color: themed('textColor');
    background-color: themed('mainBg');
  }

  &__root {
    display: flex;
    flex-direction: column;
    height: 100%;
    position: relative;
  }

  &__contentWrap {
    position: relative;
    display: flex;
    height: 100%;
    overflow-y: hidden;
  }

  &__createbtn {
    display: none;
    position: absolute;
    bottom: 20px;
    right: 20px;
  }

  &__content {
    height: 100%;
    flex: 1;
    overflow: auto;
    position: relative;
  }
}

.err {
  @include themify($themes) {
    color: themed('textColor');
    background-color: themed('mainBg');
  }
  height: 100vh;
}

.notify {
  right: calc(50% - 200px) !important;
}

.account {
  width: 100vw;
  height: 100vh;
  @include themify($themes) {
    color: themed('textColor');
    background-color: themed('mainBg');
  }
}

.settings,
.information {
  height: 100%;
  width: 100%;
  display: grid;
}

@include respond-to(mobile) {
  .notify {
    left: 16px !important;
    padding: 0 !important;

    & > div > div {
      width: calc(100vw - 32px) !important;
    }
  }
}

:global #root.dragActor {
  cursor: url('./../../public/static/actorCursor.svg'), auto;
}
