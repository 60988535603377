
        @import "@control-front-end/common/styles/variables";
        @import "@control-front-end/common/styles/mixins";
        
.imgModal {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  z-index: 1000;
  position: fixed;
  transition: opacity 450ms cubic-bezier(.23, 1, .32, 1) 0ms;
  align-items: center;
  align-content: center;
  background-color: rgba(57, 63, 70, 0.8);
  cursor: pointer;

  img {
    margin: auto;
    display: flex;
    overflow: hidden;
    box-sizing: border-box;
    box-shadow: 0 4px 7px 0 rgba(57, 62, 68, 0.2);
    transition: transform 400ms cubic-bezier(.23, 1, .32, 1) 0ms;
    flex-direction: column;
  }
}
