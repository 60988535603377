
        @import "@control-front-end/common/styles/variables";
        @import "@control-front-end/common/styles/mixins";
        
.errorAccessContainer {
  background-color: rgba(12, 17, 29, 0.7);
}

.errorAccessTextLabel {
  text-align: center;
}
