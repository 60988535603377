
        @import "@control-front-end/common/styles/variables";
        @import "@control-front-end/common/styles/mixins";
        
.event {
  &__panel {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 64px;
    flex-shrink: 0;
    transition: width 400ms cubic-bezier(.23, 1, .32, 1) 0ms;
    @include themify($themes) {
      background-color: themed('workSpaceBg');
      border-left: 1px solid themed('gray50');
    }
    position: relative;

    &__icon {
      position: absolute;
      top: 16px;
      right: 24px;
      width: 14px;
      cursor: pointer;
      z-index: 2;
    }

    &.open {
      width: 280px;

      .event__panel__icon {
        transform: rotate(180deg);
      }
    }
  }
}

.actors {
  position: relative;
  height: 100%;

  &.open {
    .actors__title > span {
      opacity: 1;
    }

    .actors__list {
      overflow: auto;
    }

    .actors__row__info > span {
      opacity: 1;
      @include text-overflow();
    }

    .actors__row:hover {
      @include themify($themes) {
        background-color: themed('primaryBgHover') !important;
      }

      .actors__row__action {
        opacity: 1;

        :global .fill {
          @include themify($themes) {
            fill: themed('primaryColor') !important;
          }
        }
      }
    }
  }

  &__title {
    padding: 16px 16px;
    width: 100%;
    box-sizing: border-box;
    white-space: nowrap;

    span {
      transition: 0.3s;
      opacity: 0;
    }
  }

  &__list {
    display: flex;
    flex-direction: column;
    height: calc(100% - 94px);
    overflow: hidden;

    &.popup {
      height: calc(100% - 134px);
    }
  }

  &__field {
    padding: 0 16px;
  }

  &__row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5px 16px;
    position: relative;
    width: 100%;
    box-sizing: border-box;

    &.clickable {
      cursor: pointer;
      transition: 0.3s;
    }

    &.action {
      padding: 5px 0;

      @include themify($themes) {
        color: themed('gray1') !important;
      }

      :global .fill {
        @include themify($themes) {
          fill: themed('gray1') !important;
        }
      }

      &:hover {
        @include themify($themes) {
          color: themed('primaryColor') !important;
        }

        :global .fill {
          @include themify($themes) {
            fill: themed('primaryColor') !important;
          }
        }
      }
    }

    &__title {
      display: flex;
      align-items: center;
    }

    &__info {
      display: flex;
      align-items: center;
      flex-shrink: 1;
      flex-grow: 1;
      width: calc(100% - 28px);

      & > span {
        width: 100%;
        box-sizing: border-box;
        white-space: nowrap;
        transition: 0.3s;
        opacity: 0;
      }

      &__small {
        font-size: 12px;
        @include themify($themes) {
          color: themed('gray2');
        }
      }

      &__avatar {
        margin-right: 12px;
      }
    }

    &__action {
      display: flex;
      flex-shrink: 0;
      margin-left: 16px;
      opacity: 0;
      transition: 0.3s;
      cursor: pointer;
    }
  }
}

@include respond-to(desktop) {
  .event__panel__btn {
    display: none !important;
  }
}

@include respond-to(tablet) {
  .event__panel__btn {
    display: none !important;
  }
}

@include respond-to(mobile) {
  .event__panel {
    position: absolute;
    right: -64px;
    top: -40px;
    height: calc(100% - 24px);
    z-index: 3;

    &.open {
      right: 0;

      .event__panel__btn {
        display: none !important;
      }
    }

    &__btn {
      display: inline-flex;
      justify-content: flex-end;
      position: absolute !important;
      top: -2px;
      right: 64px;
      z-index: 2;
      width: 100px;
    }
  }

}
