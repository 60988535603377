
        @import "@control-front-end/common/styles/variables";
        @import "@control-front-end/common/styles/mixins";
        
.modal {
  height: 90%;
  max-height: 90%;
  position: relative;

  &.noForms {
    height: 400px;
    max-height: 400px;
  }

  &__actions {
    display: flex;
    align-items: center;
    gap: 16px;
    justify-content: space-between;
    padding: 12px 24px 12px !important;
    @include themify($themes) {
      border-top: 1px solid themed('gray50');
    }

    div {
      flex-grow: 0 !important;
    }

    & > div:first-child {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      flex-grow: 1 !important;

      span {
        margin-left: 8px;
      }
    }

    &__submit__panel {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      gap: 16px;
      flex-grow: 0;
    }

    &__checkbox {
      margin-bottom: 0 !important;
    }
  }

  :global .idChip {
    margin: 0 8px !important;
    @include themify($themes) {
      background-color: themed('gray50');
    }
  }
}

.content {
  margin-bottom: 0 !important;
  padding: 0 !important;
  @include hide-scroll-bar();
  @include themify($themes) {
    background-color: themed('mainBg');
  }

  & > div:first-child {
    width: 100%;
    min-height: 400px;
    overflow-x: hidden;
  }

  &.noForms {
    & > div:first-child {
      min-height: 280px;
    }
  }

  &__default {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 24px;
    flex-wrap: wrap;
    @include themify($themes) {
      background-color: themed('workSpaceBg');
    }

    &.noForms {
      padding: 80px 0 76px 0;
    }

    &.noForms.optionalFields {
      height: 100%;
      padding: 0;
    }

    & > div {
      max-width: 496px;
    }

    &__descr {
      width: 100%;
      flex-grow: 1;
      margin-top: 8px;

      :global .field {
        @include themify($themes) {
          background-color: themed('mainBg');
        }
      }
    }

    &.no__forms {
      padding: 100px 0 0 80px;
    }
  }

  &__actions {
    position: absolute;
    display: flex;
    top: 20px;
    right: 20px;
  }

  &__chip {
    margin-left: 8px;
  }

  &__loader {
    display: flex;
    width: 100%;
    justify-content: center;
    margin-top: 120px;
  }
}

.row {
  display: flex;
  flex-direction: column;

  &__field {
    flex-grow: 1;

    :global .field {
      @include themify($themes) {
        background-color: themed('mainBg');
      }
    }
  }

  &__label {
    margin-bottom: 16px;
    position: relative;
  }
}
