
        @import "@control-front-end/common/styles/variables";
        @import "@control-front-end/common/styles/mixins";
        
.userBadge {
  position: absolute;
  bottom: -2px;
  right: -2px;
  border-radius: 50%;
  background-size: contain;
  background-repeat: no-repeat;

  &.small {
    width: 12px;
    height: 12px;
  }

  &.medium {
    width: 16px;
    height: 16px;
  }

  &.large {
    width: 20px;
    height: 20px;
  }

  &.api {
    background-image: url('./img/api.svg');
  }

  &.guest {
    background-image: url('./img/guest.svg');
  }
}

.avatar {
  flex-shrink: 0;
}
