
        @import "@control-front-end/common/styles/variables";
        @import "@control-front-end/common/styles/mixins";
        
.cf {

  &__editor {
    display: flex;
    overflow: auto;
    height: 100%;
    @include themify($themes) {
      background-color: themed('workSpaceBg');
    }

    &__main {
      display: flex;
      justify-content: center;
      height: 100%;
      padding: 0 0 20px;
      box-sizing: border-box;
      flex-grow: 1;
      overflow: hidden;
    }

    &__panel {
      width: 352px;
      overflow: auto;
      flex-shrink: 0;
      @include themify($themes) {
        border-left: 1px solid themed('disabled');
        background-color: themed('mainBg');
      }
    }
  }

  &__preview {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    @include themify($themes) {
      background-color: themed('workSpaceBg');
    }
    position: relative;

    &__main {
      display: flex;
      flex-grow: 1;
      overflow: hidden;
    }
  }

  &__access {
    height: 100%;
    overflow: hidden;
    padding: 0 20px;

    &__noUat {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 400px;
      height: 100%;
      margin: auto;
      @include themify($themes) {
        color: themed('gray35');
      }

      & > * {
        margin-top: 32px;
        text-align: center;
      }

      i, svg {
        width: 56px !important;
        height: 56px !important;
      }
    }
  }

  // FOOTER

  &__footer {
    padding: 0 24px;
    display: flex;
    align-items: center;

    &__left {
      flex-grow: 1;
    }

    &__right {
      display: flex;
      align-items: center;
      box-sizing: border-box;

      &__tmpl {
        margin-right: 60px;
      }

      &__btns {
        & > div {
          margin-left: 8px;
        }

        &__loader {
          position: relative;
          top: 4px;
          box-sizing: border-box;
        }
      }
    }
  }
}
