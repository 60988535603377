
        @import "@control-front-end/common/styles/variables";
        @import "@control-front-end/common/styles/mixins";
        
.title {
  width: 100%;
  max-width: calc(100% - 204px);

  &__label {
    width: fit-content;
    max-width: 100%;
    margin-left: 4px;
    padding: 4px;
    display: flex;
    border: none;

    &__text {
      display: block;
      @include text-overflow();
    }

    &__icon {
      align-self: center;
      margin-left: 4px;

      i {
        opacity: 0;
      }
    }

    &.canEdit {
      cursor: pointer;

      &:hover {
        @include themify($themes) {
          border: 1px solid themed('disabled');
          border-radius: 4px;
          background-color: themed('primary50Color');
        }

        i {
          opacity: 1;
        }
      }
    }
  }

  &__edit {
    height: 24px;
    display: flex;
    border-radius: 4px;
    flex-direction: row;

    @include themify($themes) {
      border: 1px solid themed('primaryColor');
      background-color: themed('mainBg');
    }

    &__field {
      height: 24px;
      color: #393F48;
      margin-top: -19px;
      padding-left: 8px;
      padding-right: 4px;
      width: 100%;

      & > div {
        border-bottom-style: none;
        padding-bottom: 4px;
      }
    }
  }
}
