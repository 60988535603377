
        @import "@control-front-end/common/styles/variables";
        @import "@control-front-end/common/styles/mixins";
        
.check {
  box-sizing: border-box;
  margin-bottom: 20px !important;
  position: relative;
  overflow: hidden;

  :global .error {
    padding-left: 0;
  }
}

