
        @import "@control-front-end/common/styles/variables";
        @import "@control-front-end/common/styles/mixins";
        
.title {
  flex: 1 1 auto;
  overflow: hidden;
  text-overflow: ellipsis;

  &__label {
    display: flex;
    max-width: 100%;
    margin-left: 4px;
    padding: 4px;
    border: none;

    &__value {
      display: block;
      @include text-overflow();
    }

    &__icon {
      align-self: center;
      margin-left: 4px;

      i {
        opacity: 0;
      }
    }

    &.editable {
      max-width: calc(100% - 16px);
      cursor: pointer;
      border-radius: 4px;
      width: fit-content;
      border: 1px solid transparent;

      &:hover {
        @include themify($themes) {
          border-color: themed('disabled');
          background-color: themed('primary50Color');
        }

        i {
          opacity: 1;
        }
      }
    }
  }

  &__edit {
    height: 24px;
    display: flex;
    border-radius: 4px;
    flex-direction: row;

    @include themify($themes) {
      border: 1px solid themed('primaryColor');
      background-color: themed('mainBg');
    }
  }

  &__field {
    height: 28px;
    color: #393F48;
    margin-top: -19px;
    padding-left: 8px;
    padding-right: 4px;
    width: 100%;
    font-size: 18px;

    & > div {
      border-bottom-style: none;
      padding-bottom: 4px;
    }
  }
}
