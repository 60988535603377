
        @import "@control-front-end/common/styles/variables";
        @import "@control-front-end/common/styles/mixins";
        
.modal {
  min-height: 260px;
  height: 560px;
  overflow: hidden !important;

  &__content {
    overflow: hidden !important;

    & > div {
      display: flex;
      height: 100%;
      width: 100%;
      overflow: hidden !important;
    }
  }
}

.content {
  height: 100%;
  width: 100%;
  overflow-y: auto;

  &__wrap {
    display: grid;
    grid-template-rows: 36px 1fr;
    height: 100%;
    width: 100%;
    overflow: hidden;
  }

  &__loader {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
  }

  &__editBtn {
    position: absolute !important;
    top: 9px;
    right: 16px;
    width: auto;
    padding: 0 !important;
  }
}

.row {
  display: flex;
  flex-direction: column;

  &.item {
    width: 100%;
    flex-direction: row;

    & > div:not(:last-child) {
      margin-right: 8px;
      flex-grow: 1;
    }

    & > div:nth-child(3) {
      cursor: pointer;
      padding-top: 11px;

      & :global .disabled {
        cursor: default;
      }
    }
  }

  &__field {
    padding-top: 0 !important;

    :global .field {
      @include themify($themes) {
        background-color: themed('mainBg');
      }
    }
  }

  &__label {
    margin-top: 20px !important;
    margin-bottom: 12px;
    position: relative;
  }

  &__btn {
    width: 152px;
  }
}

.table {
  @include themify($themes) {
    background-color: themed('workSpaceBg') !important;
  }

  :global .scTableRowDivider {
    margin: 0 !important;
  }

  :global .scTableRowList {
    padding-left: 0 !important;
  }

  &__row {
    @include themify($themes) {
      background-color: themed('workSpaceBg') !important;
    }
    padding: 0 !important;
  }

  &__cell {
    display: flex;
    @include text-overflow();

    span {
      @include text-overflow();
    }

    &.name {
      width: 50% !important;
    }

    &.access {
      width: 30% !important;
      justify-content: flex-start;
      align-items: center;
    }

    &.owner {
      width: 20% !important;
      justify-content: center;
      align-items: center;
    }

    &.value {
      width: 40% !important;
      margin-right: 12px;
    }

    &.title {
      width: 60% !important;
    }

    &.user {
      width: 55% !important;
      justify-content: flex-start;
      align-items: center;

      span:nth-child(3) {
        display: flex;
        flex-shrink: 0;
      }

      & > div {
        margin-right: 12px;
      }
    }

    &.privs {
      width: 15% !important;
      justify-content: center;

      :global .stroke {
        @include themify($themes) {
          stroke: themed('primaryColor');
        }
      }
    }
  }
}
