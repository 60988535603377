
        @import "@control-front-end/common/styles/variables";
        @import "@control-front-end/common/styles/mixins";
        
.chip {
  width: fit-content;
  min-width: 60px;
  padding: 0 8px !important;
}

.credit {
  @include themify($themes) {
    color: themed('primaryColor');
    background-color: themed('blueBg');
    border-color: themed('blueBg');
  }
}

.debit {
  @include themify($themes) {
    color: themed('redBorder');
    background-color: themed('redBg');
    border-color: themed('redBg');
  }
}

.corrected {
  @include themify($themes) {
    color: themed('gray1');
    background-color: themed('gray50');
    border-color: themed('gray50');
  }
}
