
        @import "@control-front-end/common/styles/variables";
        @import "@control-front-end/common/styles/mixins";
        
.preview {
  &__root {
    display: flex;
    flex-direction: column;
    padding: 8px;
    box-sizing: border-box;
    width: 100%;
    overflow: hidden;
  }

  &__header {
    display: flex;
    align-items: center;
    width: 100%;
    box-sizing: border-box;

    &__title {
      display: flex;
      align-items: center;
      flex-shrink: 1;
      @include text-overflow();

      span {
        margin-left: 8px;
        @include text-overflow();
      }

      &__avatar {
        flex-shrink: 0;
        :global .fill {
          @include themify($themes) {
            fill: themed('primaryColor') !important;
          }
        }
      }
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    padding: 8px 0;
    box-sizing: border-box;
    overflow: hidden;

    & > span:only-child {
      @include themify($themes) {
        color: themed('gray2') !important;
      }
    }

    &__wrap {
      position: relative;
      line-height: 22px;
      overflow-x: hidden;
      overflow-y: auto;
    }

    &__descr {
      max-height: 160px;
      overflow: auto;
      padding: 12px 0;
      @include word-break();
    }

    &__loader {
      display: flex;
      width: 100%;
      height: 120px;
      justify-content: center;
      align-items: center;
    }

    &__accessDenied {
      padding: 12px;
    }
  }

  &__info {
    display: flex;
    flex-direction: column;
    & > div {
      display: flex;
      align-items: center;
      margin: 4px 0;

      & > i {
        margin-right: 8px;
      }
    }
  }
}
