
        @import "@control-front-end/common/styles/variables";
        @import "@control-front-end/common/styles/mixins";
        
.modal {
  height: 80% !important;
  max-height: 80% !important;

  &__content {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    overflow: hidden;

    & > div {
      height: 90%;
      overflow: hidden;
    }
  }
}
