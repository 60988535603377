
        @import "@control-front-end/common/styles/variables";
        @import "@control-front-end/common/styles/mixins";
        
.fs {
  padding: 20px;

  &__section {
    display: flex;
    flex-direction: column;

    &:first-child {
      display: flex;
    }

    &__title {
      box-sizing: border-box;
    }

    &__desc {
      margin-top: 2px;
      @include themify($themes) {
        color: themed('gray1');
      }
    }

    &__textfield {
      padding-top: 12px !important;

      :global .field {
        align-items: center;
        @include themify($themes) {
          background-color: themed('mainBg') !important;
        }
      }
    }

    &__color {
      margin-top: 2px;
    }
  }
}
