
        @import "@control-front-end/common/styles/variables";
        @import "@control-front-end/common/styles/mixins";
        
.smartChip {
  display: flex;
  flex-direction: column;
  padding: 12px 0;
  box-sizing: border-box;
  border-radius: 4px;
  width: 240px;
  min-height: 40px;
  max-height: 320px;
  box-shadow: 1px 1px 8px rgba(0, 0, 0, 0.15);
  @include themify($themes) {
    border: 1px solid themed('gray20') !important;
    background-color: themed('mainBg') !important;
  }

  &__results {
    width: 100%;
    height: 100%;
    overflow: auto;

    &__empty {
      width: 100%;
      text-align: center;
      @include themify($themes) {
        color: themed('gray2') !important;
      }
    }

    &__loader {
      display: flex;
      width: 100%;
      height: 100%;
      overflow: hidden;
      align-items: center;
      justify-content: center;
    }

    &__group {
      display: flex;
      flex-direction: column;
      position: relative;
      width: 100%;

      & > span {
        @include text-overflow();
        padding: 0 12px;
      }

      &__icon {
        position: absolute;
        right: 8px;
        top: 3px;
        cursor: pointer;
        transform: rotate(180deg);
        &:hover {
          :global .fill {
            @include themify($themes) {
              fill: themed('primaryColor') !important;
            }
          }
        }

        &.back {
          svg {
            transform: rotate(180deg);
          }
        }
      }
    }

    &__item {
      display: flex;
      align-items: center;
      cursor: pointer;
      transition: .3s;
      width: 100%;
      padding: 2px 12px;
      box-sizing: border-box;
      @include text-overflow();

      &.active,
      &:hover {
        @include themify($themes) {
          background-color: themed('primary10Color');
        }
      }

      span {
        @include text-overflow();
      }

      &__avatar {
        flex-shrink: 0;
        margin-right: 8px;
        background-color: transparent !important;
        @include themify($themes) {
          border: 1px solid themed('gray50') !important;
        }

        i, svg {
          width: 12px !important;
          height: 12px !important;
        }
      }

      &__actor {
        margin-right: 8px;

        & > div:first-child {
          width: 24px !important;
          height: 24px !important;
          box-sizing: border-box !important;
        }

        :global .avatar {
          width: 100% !important;
          height: 100% !important;
        }
      }

      &__tooltip.hidden {
        visibility: hidden;
      }
    }
  }
}
