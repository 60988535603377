
        @import "@control-front-end/common/styles/variables";
        @import "@control-front-end/common/styles/mixins";
        
.period {
  display: flex;
  position: relative;
  justify-content: flex-end;
  flex: 1 1 auto;

  &.default {
    .period__btn {
      @include themify($themes) {
        color: themed('gray1') !important;
        background-color: themed('mainBg') !important;
      }

      &:hover {
        @include themify($themes) {
          color: themed('primaryColor') !important;
          background-color: themed('mainBg') !important;
        }
        border-color: transparent !important;
      }
    }
  }

  &.text {
    .period__btn {
      &:hover {
        background-color: initial !important;
        border-color: transparent !important;
      }
    }
  }

  &__menu {
    width: 210px;
    box-shadow: 0 4px 8px rgba(57, 63, 70, 0.1);
    border-radius: 8px;
    box-sizing: border-box;
    @include themify($themes) {
      border: 1px solid themed('gray50');
    }

    &.ranges {
      right: 212px;
      top: 124px;
    }

    &__item {
      i {
        width: 12px;
        height: 12px;

        svg {
          width: 12px;
          height: 12px;

          :global .stroke {
            stroke-width: 2;
            transform: scale(1.3, 1.3) translate(-2px, -3px);
            @include themify($themes) {
              stroke: themed('primaryColor') !important;
            }
          }
        }
      }

      &.active,
      &:hover {
        @include themify($themes) {
          background-color: themed('primary60Color') !important;
          :global .fill {
            fill: themed('primaryColor') !important;
          }
          span {
            color: themed('primaryColor') !important;
          }
        }
      }
    }
  }

}
