
        @import "@control-front-end/common/styles/variables";
        @import "@control-front-end/common/styles/mixins";
        
.nl {
  &__header {
    &__actions {
      display: flex;
      gap: 8px;
      position: absolute;
      z-index: 1;
      right: -16px;
      top: -16px;
      &>* {
        cursor: pointer;
      }
    }

    &__item{
      width: 32px;
      height: 32px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;

      @include themify($themes) {
        background-color: themed('disabled');
      }

      :global .fill {
        @include themify($themes) {
          fill: themed('gray');
        }
      }

      &:hover {
        @include themify($themes) {
          background-color: themed('blueBg');
        }

        :global .fill {
          @include themify($themes) {
            fill: themed('primaryColor');
          }
        }
      }
    }
  }

  &__content {
    width: 100%;
    height: calc(100% - 4px);
    box-sizing: border-box;
    position: relative;
  }
}