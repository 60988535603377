
        @import "@control-front-end/common/styles/variables";
        @import "@control-front-end/common/styles/mixins";
        
.item {
  display: flex;
  align-items: stretch;
  flex: 1 1 auto;
  box-sizing: border-box;
  border-radius: 10px;
  min-width: 30%;

  &:empty {
    display: none;
  }

  &.system {
    border: none;
    flex-grow: 0;

    .item__unactive {
      cursor: default;
      background-color: rgba(221, 224, 229, 0.2) !important;
    }
  }

  &__unactive {
    display: flex;
    flex: 1 1 auto;
    padding: 16px 16px 0;
    border-radius: 10px;
    cursor: pointer;
    transition: all .3s;
    user-select: none;
    position: relative;
    overflow: hidden;
    @include themify($themes) {
      background-color: themed('mainBg');
      border: 1px solid themed('gray50');
    }

    & > div {
      width: 100%;
    }

    & input {
      user-select: none;
      pointer-events: none;
    }

    &:hover {
      @include themify($themes) {
        background-color: themed('primary50Color');
      }
    }
  }

  &__active {
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    width: 100%;
    flex-shrink: 1;
    box-sizing: border-box;
    @include themify($themes) {
      border: 1px solid themed('gray50');
    }

    &.row {
      border-top: 0;

      & .item__active__header {
        border-radius: 0;
      }
    }

    &__tabs {
      display: flex;
      flex-direction: row;
      margin: 0 -1px;
      box-sizing: border-box;
      justify-content: space-around;
      gap: 8px;

      &__item {
        flex-grow: 1;
        width: 0;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        font-size: 16px;
        font-weight: 600;
        padding: 12px 24px;
        border-radius: 10px 10px 0 0;
        box-sizing: border-box;
        cursor: pointer;
        @include text-overflow();
        @include themify($themes) {
          background-color: themed('mainBg');
          border: 1px solid themed('disabled');
        }
        border-bottom: 0 !important;

        &.active {
          @include themify($themes) {
            color: themed('mainBg');
            background-color: themed('primaryColor');
            border-color:  themed('primaryColor');
          }
        }

        &:first-child {
          margin-left: 0;
        }

        &:last-child {
          margin-right: 1px;
        }
      }
    }

    &__header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      box-sizing: border-box;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      padding: 0 16px;
      margin-left: -1px;
      gap: 16px;
      @include themify($themes) {
        background-color: themed('primaryColor');
      }

      & i {
        cursor: pointer;
      }

      &__left,
      &__right {
        display: flex;
        align-items: center;
        gap: 16px;
      }

      &__right {
        & :global .fill {
          @include themify($themes) {
            fill: themed('mainBg');
          }
        }
      }

      &__sort,
      &__type,
      &__required {
        display: flex;
      }

      &__required.disabled {
        opacity: 0.5;
      }

      &__classSelect {
        display: flex;
        max-width: 196px;

        & > div:nth-child(2) {
          & > div {
            margin-top: -8px;
            max-height: 356px;
          }

          :global .hidden {
            max-height: 0 !important;
          }
        }

        [class*='menuItem'] {
          min-height: 32px !important;
          height: 32px;
        }
      }

      &__sort {
        gap: 16px;

        & :global .fill {
          @include themify($themes) {
            fill: themed('mainBg');
          }
        }

        & div:first-child {
          transform: rotate(90deg);
        }

        & div:last-child {
          transform: rotate(-90deg);
        }
      }

      &__type {
        & :global .bordered {
          padding-top: 8px !important;
          padding-bottom: 8px !important;
        }

        & :global {
          .leftIcon .fill {
            @include themify($themes) {
              fill: themed('primaryColor');
            }
          }

          .leftIcon .stroke {
            @include themify($themes) {
              stroke: themed('primaryColor');
            }
          }
        }

        & :global .field {
          @include themify($themes) {
            background-color: themed('mainBg');
          }
        }
      }

      &__required {
        & span {
          @include themify($themes) {
            color: themed('mainBg');
          }
        }

        & i {
          @include themify($themes) {
            color: themed('mainBg') !important;
          }
        }
      }
    }

    &__main {
      box-sizing: border-box;
      padding: 16px;
      position: relative;
      @include themify($themes) {
        background-color: themed('mainBg');
      }
    }

    &__footer {
      display: flex;
      justify-content: start;
      box-sizing: border-box;
      column-gap: 15px;
      padding: 12px 16px;
      border-radius: 0 0 10px 10px;
      @include themify($themes) {
        background-color: themed('mainBg');
        border-top: 1px solid themed('disabled');
      }

      & div:first-child i {
        transform: rotate(0deg);
      }

      & div:last-child i {
        transform: rotate(0deg);
      }
    }
  }

  &__column {
    position: absolute;
    top: 38px;
    right: 16px;
    cursor: pointer;

    & > i {
      transition: 0.3s;
    }

    &:hover:not(.disabled) {
      :global .fill {
        @include themify($themes) {
          fill: themed('primaryColor') !important;
        }
      }
    }

    &.active {
      :global .fill {
        fill: #22C933 !important;
      }
    }

    &:hover.active {
      :global .fill {
        fill: #D34437 !important;
      }
    }

    &.disabled {
      cursor: default;
    }
  }
}

.item__unactive,
.item__active__main {
  position: relative;

  .item__column:not(.active) > i {
    opacity: 0;
  }
}

.item__unactive:hover,
.item__active__main:hover {
  .item__column > i {
    opacity: 1;
  }
}


@include respond-to(mobile) {
  .item__active__footer div {
    &:first-child {
      margin-right: 16px;
    }
  }

  .item__unactive {
    padding: 8px 8px 0;
  }

  .item__active__footer span {
    display: none;
  }

  .item__active__header__type :global .rightIcon {
    margin-left: 0;
  }

  .item__active__header__required {
    margin-right: 0;
  }

  .item__active__header__required label span {
    font-size: 12px;
  }

  .item__active__header__right div:first-child {
    display: none;
  }

  .item__active__header__right div:last-child {
    margin-left: 16px;
  }
}
