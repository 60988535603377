
        @import "@control-front-end/common/styles/variables";
        @import "@control-front-end/common/styles/mixins";
        
.header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  & > * {
    pointer-events: auto;
  }

  &__wrap {
    display: flex;
    flex-direction: column;
    padding: 24px;
    -webkit-app-region: drag;
    box-sizing: border-box;
    width: 100%;
    pointer-events: none;
  }

  &__title {
    display: flex;
    align-items: center;
    line-height: 33px;
    margin-right: 12px;
    flex: 1 1 400px;
    @include text-overflow();

    span {
      @include text-overflow();
    }
  }

  &__backBtn {
    margin-right: 24px;
  }

  &__icon {
    margin-right: 8px;
  }

  &__actions {
    display: flex;
    flex: 0 0 auto;
    position: relative;

    & > div[style*="display: none"] {
      margin-right: 0 !important;
    }

    & > div:not(:last-child) {
      margin-right: 16px;
    }

    &__icon {
      @include header-icon();
    }

    &__create {
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      margin: 0 !important;

      &__btn {
        display: flex;

        &.round {
          border-radius: 50%;
        }
      }

      &__icon {
        display: none;
        position: absolute;
        top: 10px;
      }
    }

    &__readOnly {
      @include themify($themes) {
        background-color: themed('gray35') !important;
      }
    }

    &__divider {
      height: 36px;
      width: 1px;
      @include themify($themes) {
        background-color: themed('gray50');
      }
    }
  }

  &__chips {
    display: flex;
    overflow: auto;
    margin-left: 24px;
    flex-shrink: 1;

    & > div {
      margin-bottom: 0 !important;
    }

    &:empty {
      display: none;
    }
  }
}

@include respond-to(desktop) {
  .header__details {
    display: none;
  }
}

@include respond-to(tablet) {
  .header__details {
    display: none;
  }

  .header__actions {
    & > div:not(:last-child) {
      margin-right: 12px !important;
    }
  }

  .header__actions__icon {
    @include no-touch-select();
  }
}

@include respond-to(mobile) {
  .header__actions__create {
    &__btn {
      width: 36px !important;
      border-radius: 50% !important;

      span {
        display: none;
      }
    }

    &__icon {
      display: flex;
    }
  }

  .header {
    &__wrap {
      padding: 16px !important;
    }

    .header__chips {
      display: none;
    }
  }

  .header__details {
    .header__chips {
      margin-left: 0 !important;
      margin-top: 16px;
    }
  }

  .header__actions {
    & > div:not(:last-child) {
      margin-right: 12px !important;
    }
  }

  .header__actions__icon {
    @include no-touch-select();
  }
}

