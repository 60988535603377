
        @import "@control-front-end/common/styles/variables";
        @import "@control-front-end/common/styles/mixins";
        
.modal {
  max-height: 90% !important;

  &__content {
    overflow-x: hidden;

    &__list > div:first-child:not(:only-child) {
      margin-bottom: -20px !important;
    }

    &__divider {
      margin-bottom: 12px !important;
    }
  }
}

.removeBtn:hover {
  cursor: pointer;
  @include btn-icon-hover();
}

.removeBtn.disabled {
  opacity: 0.5;
}

@include respond-to(mobile) {
  .modal {
    padding: 20px 0 0;
    align-self: center;
  }
}
