
        @import "@control-front-end/common/styles/variables";
        @import "@control-front-end/common/styles/mixins";
        
.modal {
  z-index: 1001;

  &__content {
    padding: 0 !important;
    @include themify($themes) {
      border-bottom: 1px solid themed('gray50') !important;
    }
  }

  &__buttons {
    padding: 20px !important;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
    @include themify($themes) {
      background-color: themed('mainBg');
    }

    & > div {
      flex-grow: 0 !important;
    }
  }
}
