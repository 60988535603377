
        @import "@control-front-end/common/styles/variables";
        @import "@control-front-end/common/styles/mixins";
        
.event {
  :global .editor__field {
    min-height: 40px !important;
  }

  &__editor {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    padding: 16px;
    box-sizing: border-box;
    word-break: break-word;
    position: relative;

    &.error {
      & > div::after {
        @include themify($themes) {
          color: themed('red1');
        }
      }
    }

    & > div:last-child {
      width: initial !important;
      flex: 1 0 40px;
      max-height: initial !important;
      @include themify($themes) {
        color: themed('textColor') !important;
      }

      a {
        cursor: pointer;
        padding: 2px 8px;
        @include themify($themes) {
          border: 1px solid themed('primary10Color') !important;
          background-color: themed('primary10Color') !important;
        }
      }

      img {
        cursor: pointer;
        max-width: 100%;
        max-height: 100%;
      }
    }
  }
}
