
        @import "@control-front-end/common/styles/variables";
        @import "@control-front-end/common/styles/mixins";
        
.userAccess {
  position: relative;
  height: 100%;
  width: 100%;
  overflow: hidden;

  &.modal {
    box-sizing: border-box;
  }

  &.embeded {
    box-sizing: border-box;
    padding: 20px 0;

    & > div:not(:last-child) {
      padding: 0 !important;
    }
  }

  &__list {
    height: calc(100% - 64px);

    &.embeded > div {
      padding: 0 !important;
    }
  }
}
