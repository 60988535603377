
        @import "@control-front-end/common/styles/variables";
        @import "@control-front-end/common/styles/mixins";
        
.formula__chip {
  display: flex;
  position: relative;
  width: max-content;
  margin: 4px;

  &__item {
    cursor: pointer;
    max-width: max-content !important;
    margin: 0 !important;

    &.error {
      @include themify($themes) {
        border: 1px solid themed('red1') !important;
      }
    }
  }

  &__edit {
    display: flex;
    align-items: center;
    border-radius: 4px;
    border: 1px solid #87B4FF;
    margin: 4px;
    @include themify($themes) {
      border: 1px solid themed('primaryColor');
    }

    &.error {
      @include themify($themes) {
        border: 1px solid themed('red1');
      }
    }

    &__select {
      width: 80px;
      border-left: 1px solid #87B4FF;
      &:nth-child(3) {
        width: 60px;
      }

      & > div {
        padding: 0 !important;
      }

      :global .label {
        top: 2px !important;
        left: 4px;
      }

      :global .field {
        padding: 2px 4px !important;
        border: none !important;
      }

      :global .rightIcon {
        margin-left: 8px !important;
      }

      &__item > div {
        display: flex;
        span {
          width: 50%;
        }
        & > div {
          width: 50%;
          margin: 0 !important;
          font-size: 14px !important;
          @include text-overflow();
          @include themify($themes) {
            color: themed('textColor') !important;
          }
        }
      }
    }
  }

  &__actions {
    position: absolute;
    top: 1px;
    right: 1px;
    bottom: 1px;
    display: flex;
    align-items: center;
    opacity: 0;
    transition: 0.3s;
    box-sizing: border-box;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    border-left: 1px solid rgba(0, 0, 0, 0.16) !important;

    &:hover {
      opacity: 1;
      i {
        opacity: 1;
      }
    }

    &__icon {
      cursor: pointer;
      opacity: 0;
      transition: 0.3s;
      margin: 0 4px;
      :global .fill {
        @include themify($themes) {
          fill: themed('textColor') !important;
        }
      }
    }
  }
}

div[class*="-menu"] {
  margin-top: 1px;
}
