
        @import "@control-front-end/common/styles/variables";
        @import "@control-front-end/common/styles/mixins";
        
.preview {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  z-index: 1000;
  position: fixed;
  transition: opacity 450ms cubic-bezier(.23, 1, .32, 1) 0ms;
  align-items: center;
  justify-content: center;
  align-content: center;
  background-color: rgba(0, 2, 6, 0.9);
  overflow: hidden;

  &__loader {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__docx,
  &__xlsx,
  &__csv,
  &__mp3,
  &__mp4,
  &__webm {
    display: flex;
    justify-content: center;
    align-items: center;
    overflow-y: auto;
    width: calc(100% - 144px);
    height: calc(100% - 60px);

    & > div:not(.preview__loader) {
      visibility: hidden;
      overflow: hidden;
    }

    &.loaded > div {
      visibility: visible;
      overflow-y: auto;
    }

    & video {
      max-width: calc(100% - 144px);
      max-height: calc(100% - 60px);
    }
  }

  &__docx,
  &__xlsx,
  &__csv,
  &__mp3 {
    & > div:not(.preview__loader) {
      height: 90%;
    }
  }

  &__xlsx > div > div > div > div,
  &__csv > div > div > div > div, {
    width: 95vw;
  }

  &__pdf {
    display: flex;
    justify-content: center;
    max-height: calc(100% - 144px);

    &.loaded {
      overflow-y: auto;
    }

    &.loaded > div:not(.preview__loader) {
      visibility: visible;
    }

    & > div > div > div > div {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    & > div:not(.preview__loader) {
      min-width: calc(100vw - 144px);
      overflow: hidden;
      align-items: center;
      justify-content: center;
      visibility: hidden;
    }
  }

  & > img {
    margin: auto;
    display: flex;
    overflow: hidden;
    box-sizing: border-box;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.6);
    transition: transform 400ms cubic-bezier(.23, 1, .32, 1) 0ms;
    flex-direction: column;
    max-height: calc(100% - 144px);
    max-width: calc(100% - 144px);
    user-select: none;
  }

  &__file {
    display: flex;
    justify-content: center;
    width: 100%;

    & > img,
    & > i,
    & > i > svg {
      width: 160px !important;
      height: 160px !important;
    }
  }

  &__doc {
    display: flex;
    justify-content: center;
    width: calc(100% - 144px);
    height: calc(100% - 80px);
  }

  &__header {
    position: absolute;
    top: 24px;
    left: 24px;
    right: 24px;
    display: flex;
    justify-content: space-between;

    > div {
      display: flex;
      align-items: center;
    }

    &__title {
      max-width: 70%;
      span {
        @include text-overflow();
      }
    }
  }

  &__actions {
    display: flex;

    &__icon {
      height: 36px;
      width: 36px;
      background-color: #0A0A0C;
      border-radius: 50%;
      display: flex;
      flex-shrink: 0;
      align-items: center;
      justify-content: center;
      margin-right: 16px;
      cursor: pointer;
      user-select: none;

      &:hover {
        @include themify($themes) {
          background-color: themed('secondaryColor');
        }

        :global .fill {
          @include themify($themes) {
            fill: themed('primaryColor') !important;
          }
        }
      }

      &.hidden {
        display: none;
      }

      &.arrowDown {
        transform: rotate(180deg);
      }

      &.noMargin {
        margin-right: 0 !important;
      }
    }
  }

  &__navigation {
    position: absolute;
    left: 24px;
    right: 24px;
    display: flex;
    justify-content: space-between;

    .preview__actions__icon {
      margin: 0 !important;
    }
  }

  &__title {
    @include themify($themes) {
      color: themed('mainBg');
    }
  }

  &__menu {
    position: absolute;
    left: -160px;
    top: 0;
    z-index: 10;

    &__wrap {
      position: relative;
    }
  }
}

@include respond-to(tablet) {
  .preview img {
    width: auto;
    height: auto;
    max-width: calc(100% - 48px);
    max-height: calc(100% - 96px);
  }
}

@include respond-to(mobile) {
  .preview img {
    width: auto;
    height: auto;
    max-width: calc(100% - 32px);
  }

  .preview__header {
    left: 16px;
    right: 16px;
  }

  .preview__navigation {
    left: 16px;
    right: 16px;
  }
}
